import { Chip, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useInstantSearch } from "react-instantsearch-hooks-web";

export default function ActiveRefinements(props: any) {
  const { indexId, prettyLabels } = props;
  const { uiState, setUiState } = useInstantSearch();
  const [refinements, setRefinements] = useState([]);

  useEffect(() => {
    if (uiState[indexId].refinementList) {
      const stateRefinements: any = uiState[indexId].refinementList;
      let tempLabels: any = [];

      Object.keys(stateRefinements).forEach((key) => {
        stateRefinements[key].forEach((r: any) => {
          tempLabels.push({
            label: `${prettyLabels[key]}: ${r.toUpperCase()}`,
            index: indexId,
            facet: key,
            value: r,
          });
        });
      });

      setRefinements(tempLabels);
    } else {
      setRefinements([]);
    }
  }, [uiState]);

  function handleDelete(index: string, facet: string, value: string) {
    setUiState((ps) => {
      const newRefinements =
        ps[index].refinementList?.[facet].filter((rv) => rv !== value) || [];

      return {
        ...ps,
        [index]: {
          ...ps[index],
          refinementList: {
            ...ps[index].refinementList,
            [facet]: newRefinements,
          },
        },
      };
    });
  }

  return (
    <Stack direction="row" sx={{ flexWrap: "wrap" }}>
      {refinements.map((r: any, i: number) => {
        return (
          <Chip
            key={`${indexId}-refinement-${i}`}
            label={r.label}
            sx={{ my: 1, mr: 2 }}
            onDelete={() => handleDelete(r.index, r.facet, r.value)}
            variant="outlined"
          />
        );
      })}
    </Stack>
  );
}
