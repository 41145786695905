import React from "react";
import { CompanyData } from "../types/companies";
import { FeaturedCompany } from "../components/featuredCompany";

import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import useWindowDimensions from "../hooks/useWindowDimensions";

interface HorizontalCarouselProps {
  items: CompanyData[];
}

export const HorizontalCarousel: React.FC<HorizontalCarouselProps> = ({
  items,
}) => {
  const { width } = useWindowDimensions();

  let slidesPerViewValue = 0;

  if (width >= 860) {
    slidesPerViewValue = 4;
  } else if (width >= 550) {
    slidesPerViewValue = 3;
  } else if (width >= 400) {
    slidesPerViewValue = 2;
  } else {
    slidesPerViewValue = 1;
  }

  return (
    <Swiper
      loop={true}
      slidesPerView={slidesPerViewValue}
      spaceBetween={0}
      navigation={true}
      modules={[Navigation]}
    >
      {items.map((item) => {
        return (
          <SwiperSlide>{<FeaturedCompany companyDatum={item} />}</SwiperSlide>
        );
      })}
    </Swiper>
  );
};
