import React from "react";
import { PaddedPaper } from "../padded_paper";
import {
  CompanyData,
  AccountType,
  AdditionalResourceType,
} from "../../types/companies";

import { Grid, Button } from "@material-ui/core";
import newsItemPlaceholder from "../../images/icons/news_item_placeholder.png";
import * as ROUTES from "../../types/routes";

interface CompanyDetailsDisplayProps {
  companyDatum: CompanyData;
}

export default function ContentTab({
  companyDatum,
}: CompanyDetailsDisplayProps) {
  const checkAccountType = (): AccountType => {
    if (companyDatum["account_type"] === "premium") {
      return AccountType.PREMIUM;
    }
    if (companyDatum["account_type"] === "basic") {
      return AccountType.BASIC;
    }
    return AccountType.STARTER;
  };

  const showVideos =
    companyDatum!["videos"] !== null &&
    companyDatum!["videos"] !== undefined &&
    companyDatum!["videos"].length > 0 &&
    companyDatum["videos"][0] !== "";

  const showArticles =
    companyDatum!["articles"] !== null &&
    companyDatum!["articles"] !== undefined &&
    companyDatum!["articles"].length > 0;

  const showAdditionalResources =
    companyDatum!["additional_resources"] !== null &&
    companyDatum!["additional_resources"] !== undefined &&
    companyDatum!["additional_resources"].length > 0;

  return (
    <div className="container-box">
      <Grid container spacing={2}>
        {/* {showAdditionalResources && (
          <Grid item xs={12}>
            <div className="company-details-information white-papers item">
              <h3>Research &amp; White Papers</h3>
              <Grid item xs={12} md={3}>
                <PaddedPaper>
                  <div className="related-companies">
                    <img src="" alt="Document preview"></img>
                    <h3 className="company-heading">Name</h3>
                    <h3 className="company-heading industry">Info</h3>
                    <a href="">Download</a>
                  </div>
                </PaddedPaper>
              </Grid>
            </div>
          </Grid>
        )} */}

        {showAdditionalResources && (
          <Grid item xs={12}>
            <div className="item">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
                className="details-row news"
              >
                {/* {companyDatum["news_about_the_company"] && ( */}
                <>
                  <Grid item xs={12} md={6}>
                    <div className="company-details-information">
                      <h3>Research &amp; White Papers</h3>
                      <Grid container alignItems="center" spacing={3}>
                        {companyDatum !== null &&
                          companyDatum["additional_resources"] !== null &&
                          companyDatum["additional_resources"].map((item) => {
                            return (
                              <Grid item xs={12} key={item["url"]}>
                                <a
                                  className="company-details-information__resource-link"
                                  href={
                                    item.type ===
                                    AdditionalResourceType.DOCUMENT
                                      ? window.location.origin +
                                        "/partner-documents/" +
                                        item["name"]
                                      : item["url"] !== null
                                      ? item["url"]
                                      : ""
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <PaddedPaper>
                                    <Grid
                                      style={{
                                        display: "inline-block",
                                      }}
                                      item
                                      xs={12}
                                      md={4}
                                    >
                                      {item["image"] !== undefined ? (
                                        <div
                                          className="news-image"
                                          style={{
                                            height: "250px",
                                            maxWidth: "250px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            margin: "auto",
                                          }}
                                        >
                                          <div
                                            className="news-image"
                                            style={{
                                              height: "150px",
                                              width: "150px",
                                              backgroundImage: `url(${item["image"]})`,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              margin: "auto",
                                            }}
                                          ></div>
                                        </div>
                                      ) : (
                                        <div
                                          className="news-image"
                                          style={{
                                            height: "140px",
                                            maxWidth: "250px",
                                            margin: "auto",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            style={{ width: "100px" }}
                                            src={newsItemPlaceholder}
                                            alt="news item image placeholder"
                                          />
                                        </div>
                                      )}
                                    </Grid>
                                    <Grid
                                      style={{
                                        display: "inline-block",
                                      }}
                                      item
                                      xs={8}
                                    >
                                      <h3>{item["title"]}</h3>
                                      <p>
                                        {item["summary"] ? item["summary"] : ""}
                                        {/* {item["type"] ===
                                        AdditionalResourceType.DOCUMENT
                                          ? "Download"
                                          : "Link"} */}
                                      </p>
                                    </Grid>
                                  </PaddedPaper>
                                </a>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </div>
                  </Grid>
                </>
                {/* )} */}
              </Grid>
            </div>
          </Grid>
        )}

        {showVideos && (
          <Grid item xs={12}>
            <div className="item">
              <Grid item xs={12} className=" videos">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h3>Videos</h3>
                  </Grid>

                  <>
                    <Grid item xs={12}>
                      <div className="video-container">
                        {companyDatum["videos"] &&
                          companyDatum["videos"].length > 0 && (
                            <iframe
                              width="100%"
                              height="500"
                              src={companyDatum["videos"][0]}
                              title="YouTube video player"
                            ></iframe>
                          )}
                      </div>
                    </Grid>
                    {companyDatum["videos"] &&
                      companyDatum["videos"].length > 1 &&
                      companyDatum["videos"].map((video) => {
                        return (
                          <Grid item xs={4}>
                            <div className="video-container-small">
                              <iframe
                                width="100%"
                                height="160"
                                src={video}
                                title="YouTube video player"
                              ></iframe>
                            </div>
                          </Grid>
                        );
                      })}
                  </>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}

        {showArticles && (
          <Grid item xs={12}>
            <div className="item">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="stretch"
                spacing={2}
                className="details-row news"
              >
                <>
                  <Grid item xs={12} md={6}>
                    <div className="company-details-information">
                      <h3>Articles</h3>
                      <Grid container alignItems="center" spacing={3}>
                        {companyDatum !== null &&
                          companyDatum["articles"] !== null &&
                          companyDatum["articles"].map((item) => {
                            return (
                              <Grid item xs={12} key={item["url"]}>
                                <a
                                  className="company-details-information__resource-link"
                                  href={item["url"] || ""}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <PaddedPaper>
                                    <Grid
                                      style={{
                                        display: "inline-block",
                                      }}
                                      item
                                      xs={12}
                                      md={4}
                                    >
                                      <div
                                        className="news-image"
                                        style={{
                                          height: "140px",
                                          width: "250px",
                                          margin: "auto",
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          style={{ width: "100px" }}
                                          src={newsItemPlaceholder}
                                          alt="news item image placeholder"
                                        />
                                      </div>
                                    </Grid>
                                    <Grid
                                      style={{
                                        display: "inline-block",
                                      }}
                                      item
                                      xs={8}
                                    >
                                      <h3>{item["title"]}</h3>
                                      <p>
                                        {item["summary"] ? item["summary"] : ""}
                                      </p>
                                    </Grid>
                                  </PaddedPaper>
                                </a>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </div>
                  </Grid>
                </>
                {/* )} */}
              </Grid>
            </div>
          </Grid>
        )}

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          className="details-row show-interest"
        >
          <Grid item xs={12} md={12}>
            <div className="center" style={{ padding: "3rem 0" }}>
              <h2>Members get more on the Data Leaders Hub</h2>
              <Button
                className="lower-case-button theme-button outline"
                variant="outlined"
                color="primary"
                href={ROUTES.DATA_LEADERS_HUB}
              >
                Go to the Hub now
              </Button>
              <div className="get-in-touch">
                <p>
                  Not yet a member?{" "}
                  <a href={ROUTES.DATA_LEADERS_HUB_CONTACT}>Get in touch</a>
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
