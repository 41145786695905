export const DomainList: Array<string> = [
  "AI-based applications",
  "Analytics, ML & AI",
  "Data broker",
  "Data governance",
  "Data management",
  "Data marketplace",
  "Data ops",
  "Data processors",
  "Data strategy",
  "DBMS",
  "Investment",
  "Legal",
  "Intelligent automation",
  "IT services & consulting",
  "Open data",
  "Recruitment",
  "Research / analysis",
  "Risk, security, ESG",
  "Training",
  "Web scraping",
];
export const DomainListDataProviders: Array<string> = [
  "Data broker",
  "Data marketplace",
  "Open data",
  "Web scraping",
];
export const DomainListServiceProviders: Array<string> = [
  "Research / analysis",
  "Legal",
  "Recruitment",
  "IT services & consulting",
  "Training",
];
export const DomainListSoftwareProviders: Array<string> = [
  "Analytics, ML & AI",
  "Data governance",
  "Data management",
  "Data ops",
  "DBMS",
  "Intelligent automation",
  "Risk, security, ESG",
];
export const IndustryFocusList: Array<string> = [
  "Automotive",
  "Manufacturing, Electronics and Engineering",
  "Construction",
  "Consumer Goods",
  "Energy and Utilities",
  "Media, Entertainment and Culture",
  "Government",
  "Pharmaceutical and Healthcare",
  "Travel and Hospitality",
  "Financial Services",
  "Not For Profit",
  "Professional and Business Services",
  "Retail and Fashion",
  "Technology",
  "Telecommunications",
  "Transportation and Logistics",
  "Other",
];
export const FunctionFocusList: Array<string> = [
  "Back Office Automation",
  "Customer service",
  "Data",
  "Finance",
  "HR",
  "IT",
  "Legal",
  "Marketing",
  "Procurement",
  "Productivity",
  "Sales",
  "Security",
  "Supply chain",
];

export const DataSourceTypeList: Array<string> = [
  "Data marketplace",
  "Data broker",
  "Open data",
  "Web scraping",
];
