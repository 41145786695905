import React from "react";
import { useHistory, Link } from "react-router-dom";
import * as ROUTES from "../../types/routes";

export default function PartnerHome({ userCompany = "" }) {
  return (
    <div className="partner-account">
      <div className="partner-account__content">
        <h2>Welcome</h2>
        <p>You can manage your profile and preview it.</p>
      </div>
    </div>
  );
}
