import { firestore } from "firebase-admin";

export interface CompanyData {
  // Company overview
  id: string;
  stock_symbol: string | null;
  featured_company: boolean | null;
  company_type: string;
  company_name: string;
  company_logo_file_location: string;
  company_logo_padding: boolean | null;
  about_the_company?: string;
  headquarters_location?: string;
  website?: string | null;
  account_type: AccountType;
  founded_year?: string | null;
  total_funding_amount: string | null;
  number_of_clients?: number | null;
  number_of_employees?: number | null;
  all_locations: LocationData[] | null;
  videos: string[] | null;
  geographic_segment: { [region: string]: number } | null;
  executive_team: ExecutiveTeamData[] | null;
  customer_references: CustomerReferenceData[] | null;
  open_positions: [] | null;
  open_positions_link: string | null;

  specialisations_domains: string[];
  industry_focus: string[];
  focus_areas: string[];
  features: { [id: string]: string } | null;
  data_source_type: string[] | null;
  feature_information: string | null;
  product_description: null;
  "7_axis_of_analysis": { [axis: string]: string } | null;

  $all_specialisations_domains: boolean;
  tags: string[];
  industries: string[];
  $all_industry_focus: boolean;
  $all_focus_areas: boolean;
  verified_mark?: null;
  founded_message: string; // "Established (>10yrs)"
  general_contact_email: string | null;
  linked_in_profile: string | null;
  market_cap: null;
  news_about_the_company: NewsData[] | null;
  dcm: null;
  additional_resources: AdditionalResourcesData[] | null;
  articles: ArticlesData[] | null;
  search_terms: string;
  matchScore: number | null;

  approved: boolean;
  date_last_edited: firestore.Timestamp | null | undefined;
  date_added: firestore.Timestamp | null | undefined;
  admin_notes: string | null;
  new_user_update: boolean | null;
}

export enum CompanyType {
  SOFTWARE_PROVIDER = "software provider",
  DATA_PROVIDER = "data provider",
  SERVICES_PROVIDER = "services provider",
}

export const CompanyTypeList: Array<string> = [
  CompanyType.SOFTWARE_PROVIDER,
  CompanyType.SERVICES_PROVIDER,
  CompanyType.DATA_PROVIDER,
];
export enum AccountType {
  STARTER = "starter",
  BASIC = "basic",
  PREMIUM = "premium",
}

export enum AdditionalResourceType {
  DOCUMENT = "document",
  LINK = "link",
}

export interface TagData {
  id: string;
  value: string;
}

export interface LocationData {
  address: string;
  latitude: number;
  longitude: number;
  city: string;
  country: string;
  state: string | null;
}

interface NewsData {
  title: string;
  summary: string;
  url: string;
  image: string;
}

interface AdditionalResourcesData {
  title: string;
  summary: string | null;
  url: string | null;
  name: string | null;
  image: string | null;
  type: AdditionalResourceType;
}

interface ArticlesData {
  title: string;
  summary: string | null;
  url: string | null;
}

export interface ExecutiveTeamData {
  full_name: string;
  job_title: string;
  image_url: string;
}

export interface CustomerReferenceData {
  text: string;
  author: string | null;
  author_image: string | null;
}

export const enrichWithDynamicValues = (
  rawData: CompanyData[]
): CompanyData[] => {
  // The Maturity changes year by year, therefore if there is a founded year we compute the
  //  "founded_message" based on the gap in years
  const currentYear = new Date().getFullYear();
  rawData.forEach((datum) => {
    if (datum["founded_year"]) {
      const foundedYear = parseInt(datum["founded_year"]);
      const yearsOld = currentYear - foundedYear;
      if (yearsOld > 10) {
        datum["founded_message"] = "Established (>10yrs)";
      } else if (yearsOld > 5) {
        datum["founded_message"] = "Growing (5-10yrs)";
      } else {
        datum["founded_message"] = "Start-Up (<5yrs)";
      }
    }
  });

  rawData.forEach((datum) => {
    datum["$all_industry_focus"] =
      datum["industry_focus"].length === 1 &&
      datum["industry_focus"][0] === "All";
    datum["$all_focus_areas"] =
      datum["focus_areas"].length === 1 && datum["focus_areas"][0] === "All";
    datum["$all_specialisations_domains"] =
      datum["specialisations_domains"].length === 1 &&
      datum["specialisations_domains"][0] === "All";
  });

  return rawData;
};
