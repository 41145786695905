import React from "react";
import { Link } from "react-router-dom";
import {
  deleteCompany,
  fetchAllCompaniesAsAdmin,
  fetchCompany,
} from "../../utils/database";
import Chip from "@material-ui/core/Chip";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import { firestore } from "firebase-admin";
import { capitalise, indexOfCaseInsensitive } from "../../utils/utils";
import { DomainList } from "../../types/classification";
import {
  CompanyData,
  AccountType,
  CompanyType,
  TagData,
} from "../../types/companies";
import { LoadingAnimation } from "../../components/loadingAnimation";
import Delete from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlined from "@material-ui/icons/EditOutlined";
import { Button, Tab, Tabs, Box, Breadcrumbs } from "@material-ui/core";
import Badge, { BadgeProps } from "@material-ui/core/Badge";
import { styled } from "@material-ui/styles";
import * as ROUTES from "../../types/routes";
import { getTags } from "../../utils/database";

const TESTING_ONLY =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";

export default function AllCompanies() {
  const [allCompanies, setAllCompanies] = React.useState([] as CompanyData[]);
  const [liveCompanies, setLiveCompanies] = React.useState([] as CompanyData[]);
  const [draftDataCompanies, setDraftDataCompanies] = React.useState(
    [] as CompanyData[]
  );
  const [draftServicesCompanies, setDraftServicesCompanies] = React.useState(
    [] as CompanyData[]
  );
  const [draftSoftwareCompanies, setDraftSoftwareCompanies] = React.useState(
    [] as CompanyData[]
  );
  const [newCompanies, setNewCompanies] = React.useState([] as CompanyData[]);
  const [companiesToShow, setCompaniesToShow] = React.useState(
    [] as CompanyData[]
  );
  const [loading, setLoading] = React.useState(true);
  const [tabSelected, setTabSelected] = React.useState(0);
  const [tags, setTags] = React.useState<TagData[]>();
  React.useEffect(() => {
    const fetchAllTags = async () => {
      const allTags: TagData[] = await getTags();
      setTags(allTags);
    };
    fetchAllTags();
  }, []);
  const handleChange = (event: any, newValue: any) => {
    setTabSelected(newValue);
    switch (newValue) {
      case 0:
        setCompaniesToShow(liveCompanies);
        break;
      case 1:
        setCompaniesToShow(draftServicesCompanies);
        break;
      case 2:
        setCompaniesToShow(draftSoftwareCompanies);
        break;
      case 3:
        setCompaniesToShow(draftDataCompanies);
        break;
      case 4:
        setCompaniesToShow(newCompanies);
        break;

      default:
        setCompaniesToShow(liveCompanies);
    }
  };
  React.useEffect(() => {
    switch (tabSelected) {
      case 0:
        setCompaniesToShow(liveCompanies);
        break;
      case 1:
        setCompaniesToShow(draftServicesCompanies);
        break;
      case 2:
        setCompaniesToShow(draftSoftwareCompanies);
        break;
      case 3:
        setCompaniesToShow(draftDataCompanies);
        break;
      case 4:
        setCompaniesToShow(newCompanies);
        break;

      default:
        setCompaniesToShow(liveCompanies);
    }
  }, [
    ,
    allCompanies,
    liveCompanies,
    draftSoftwareCompanies,
    draftDataCompanies,
    newCompanies,
    draftServicesCompanies,
  ]);

  const isValidOption = (option: string, optionsType: string): Boolean => {
    switch (optionsType) {
      case "domains":
        return indexOfCaseInsensitive(DomainList, option) > -1;
      case "tags":
        if (tags != undefined) {
          const index = tags.findIndex((element) => {
            return element.value.toLowerCase() === option.toLowerCase();
          });
          return index > -1;
        } else {
          return false;
        }

      default:
        return true;
    }
  };
  const getDate = (date: firestore.Timestamp | null | undefined) => {
    if (!date || date === undefined) return "--";
    // return JSON.stringify(date);
    // console.log(JSON.stringify(date?.toDate()));
    return moment(date?.toDate()).format("DD/MM/YYYY");
  };

  React.useEffect(() => {
    const fetchList = async () => {
      const data = await fetchAllCompaniesAsAdmin();
      if (data != undefined) {
        data.sort((a, b) =>
          a.id.toLowerCase().localeCompare(b.id.toLowerCase())
        );
        setAllCompanies(data);
      } else {
        setAllCompanies([]);
      }
      setLoading(false);
    };
    fetchList();
  }, []);

  React.useEffect(() => {
    setDraftDataCompanies(
      allCompanies.filter(
        (company) =>
          !company.new_user_update &&
          !company.approved &&
          company.company_type === CompanyType.DATA_PROVIDER
      )
    );
    setDraftServicesCompanies(
      allCompanies.filter(
        (company) =>
          !company.new_user_update &&
          !company.approved &&
          company.company_type === CompanyType.SERVICES_PROVIDER
      )
    );
    setDraftSoftwareCompanies(
      allCompanies.filter(
        (company) =>
          !company.new_user_update &&
          !company.approved &&
          company.company_type === CompanyType.SOFTWARE_PROVIDER
      )
    );
    setNewCompanies(
      allCompanies.filter(
        (company) => company.new_user_update && !company.approved
      )
    );
    setLiveCompanies(
      allCompanies.filter(
        (company) => !company.new_user_update && company.approved
      )
    );
  }, [loading, tabSelected, allCompanies]);

  const deleteCompanyHandler = async (
    companyId: string,
    companyName: string
  ) => {
    if (
      window.confirm(
        `Are you sure you want to delete ${companyName}? This action is not reversible.`
      )
    ) {
      setLoading(true);
      try {
        await deleteCompany(companyId);
        window.location.reload();
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }
  };

  const styles = {
    smallIcon: {
      width: 12,
      height: 12,
    },
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 0,
      top: 13,
      padding: "0 4px",
      backgroundColor: "rgba(175,184,194,0.4)",
      color: "rgb(36,41,47)",
    },
  }));

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function download_csv_file() {
    const CSV_COLUMNS = ["Name", "Company type", "Domains"];

    let csv = CSV_COLUMNS.join(",") + "\n";
    const date = new Date();
    const month = date.getMonth() + 1;
    const formattedDate =
      date.getDate() + "-" + month + "-" + date.getFullYear();

    allCompanies.forEach(function (company) {
      csv +=
        company.company_name +
        "," +
        company.company_type +
        ',"' +
        company.specialisations_domains.filter((item) => item != "");
      csv += '"\n';
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";

    // provide the name for the CSV file to be downloaded
    hiddenElement.download = `List of companies - ${formattedDate}.csv`;
    hiddenElement.click();
  }

  return (
    <div className="all-companies">
      {loading ? (
        <LoadingAnimation message="Fetching companies..." />
      ) : (
        <div>
          <div className="section-header">
            <h2>Companies</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px 0",
              }}
            >
              <p>{allCompanies.length} results</p>
              <Button
                style={{
                  fontWeight: 500,
                  color: "grey",
                  border: "1px solid #c4c4c4",
                  backgroundColor: "#f8f8f8",
                  // padding: "0 20px",
                  // margin: "5px 0",
                }}
                size="small"
                disableElevation
                className="btn--new"
                onClick={download_csv_file}
              >
                Download CSV
              </Button>

              <Button
                style={{
                  fontWeight: "bold",
                  color: "white",
                  backgroundColor: "#038097",
                  // padding: "0 20px",
                  // margin: "5px 0",
                }}
                size="small"
                disableElevation
                className="btn--new"
                href={ROUTES.NEW_COMPANY}
              >
                New Company
              </Button>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Tabs
              value={tabSelected}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "hsla(189, 96%, 30%, 1)",
                },
              }}
            >
              <Tab label={`Live (${liveCompanies.length})`} {...a11yProps(0)} />
              <Tab
                label={`Draft Services Providers (${draftServicesCompanies.length})`}
                {...a11yProps(1)}
              />
              <Tab
                label={`Draft Software Providers (${draftSoftwareCompanies.length})`}
                {...a11yProps(2)}
              />
              <Tab
                label={`Draft Data Providers (${draftDataCompanies.length})`}
                {...a11yProps(3)}
              />

              <Tab label={`New (${newCompanies.length})`} {...a11yProps(4)} />
            </Tabs>
          </div>

          <div className="all-companies__table">
            {/* <div className="all-companies__table__container"> */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 10 }}></TableCell>
                  <TableCell className="name">Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Updated</TableCell>
                  <TableCell className="list">Company type</TableCell>
                  {tabSelected === 1 && (
                    <TableCell className="list">Locations</TableCell>
                  )}
                  <TableCell className="list">Domains</TableCell>
                  <TableCell className="list">Features</TableCell>
                  {/* <TableCell className="list"># of company locations</TableCell> */}
                  <TableCell width="50px"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companiesToShow.map((company) => (
                  <TableRow key={company.company_name}>
                    <TableCell style={{ paddingLeft: 10, paddingRight: 0 }}>
                      <Link
                        to={`${ROUTES.EDIT_COMPANY}/${company.id}`}
                        className="edit"
                        style={{ color: "#666" }}
                      >
                        <EditOutlined fontSize="small" color="inherit" />
                      </Link>
                    </TableCell>
                    <TableCell>
                      {/* Company name */}
                      <Link
                        to={`${ROUTES.PREVIEW_COMPANY}/${company.id}`}
                        className="preview"
                      >
                        {company.company_name}
                      </Link>

                      {/* Account type */}
                      {company.account_type === AccountType.BASIC ||
                      company.account_type === AccountType.PREMIUM ? (
                        <Chip
                          color={
                            company.account_type === AccountType.PREMIUM
                              ? "primary"
                              : "secondary"
                          }
                          size="small"
                          label={company.account_type}
                        />
                      ) : (
                        <span></span>
                        // <Chip
                        //   variant="outlined"
                        //   size="small"
                        //   label="starter"
                        // />
                      )}

                      {/* Featured company? */}
                      {company.featured_company ? (
                        <Chip color="default" size="small" label="Featured" />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {/* Approved or not */}
                      {company.new_user_update ? (
                        <>
                          <span>Client update</span>&nbsp;
                          <span className="state-circle state-circle--new-update"></span>
                        </>
                      ) : company.approved === true ? (
                        <>
                          <span>Live</span>&nbsp;
                          <span className="state-circle state-circle--active"></span>
                        </>
                      ) : (
                        <>
                          <span>Draft</span>&nbsp;
                          <span className="state-circle state-circle--inactive"></span>
                        </>
                      )}
                    </TableCell>
                    <TableCell>{getDate(company.date_last_edited)}</TableCell>

                    {/* <TableCell>
                      {company.geographic_segment !== null &&
                        company.geographic_segment !== undefined &&
                        Object.keys(company.geographic_segment).map((key) => {
                          return (
                            <div>
                              {key}: {company.geographic_segment![key]}
                            </div>
                          );
                        })}
                    </TableCell> */}

                    <TableCell>
                      <span>
                        {company.company_type &&
                          capitalise(company.company_type)}
                      </span>
                    </TableCell>
                    {tabSelected === 1 && (
                      <TableCell>
                        Headquarters:
                        <ul>
                          {company.headquarters_location && (
                            <li>{company.headquarters_location}</li>
                          )}
                        </ul>
                        Other locations:
                        <ul>
                          {company.all_locations &&
                            company.all_locations.map(
                              (location) =>
                                location.city && (
                                  <li>
                                    {location.city} ({location.country})
                                  </li>
                                )
                            )}
                        </ul>
                      </TableCell>
                    )}
                    <TableCell>
                      <ul className="listings">
                        {company.specialisations_domains &&
                          company.specialisations_domains.map((item) => {
                            if (item !== "") {
                              return (
                                <li
                                  key={item.toString()}
                                  className={
                                    !isValidOption(item, "domains")
                                      ? "invalid"
                                      : ""
                                  }
                                >
                                  {capitalise(item)}
                                </li>
                              );
                            }
                          })}
                      </ul>
                    </TableCell>
                    {/* <TableCell>
                        <ul className="listings">
                          {company.tags &&
                            company.tags.length >= 1 &&
                            company.tags.map((item) => {
                              if (item != "") {
                                return (
                                  <li
                                    key={item.toString()}
                                    className={
                                      !isValidOption(item, "tags")
                                        ? "invalid"
                                        : ""
                                    }
                                  >
                                    {capitalise(item)}
                                  </li>
                                );
                              }
                            })}
                        </ul>
                      </TableCell> */}
                    <TableCell>
                      <ul className="listings">
                        {company.features &&
                          Object.keys(company.features).map((item) => (
                            <li
                              key={item.toString()}
                              className={
                                !isValidOption(item, "tags") ? "invalid" : ""
                              }
                            >
                              <span>{item}</span>:{" "}
                              <span style={{ color: "#aaa" }}>
                                {company.features![item]}
                              </span>
                            </li>
                          ))}
                      </ul>
                    </TableCell>
                    {/* <TableCell>{company.all_locations?.length}</TableCell> */}

                    <TableCell>
                      <Delete
                        className="clickable"
                        onClick={() => {
                          deleteCompanyHandler(
                            company.id,
                            company.company_name
                          );
                        }}
                        fontSize="small"
                        color="inherit"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* </div> */}
            {/* <ul>
              {allCompanies
                .filter((_, id) => id >= allCompanies.length / 2)
                .map((company) => (
                  <li key={company.company_name}>
                    <Link
                      to={`registration-form/${company.company_name}`}
                      className={company.approved !== false ? "" : "disabled"}
                    >
                      {company.company_name}
                    </Link>
                    {"  "}
                    {company.account_type === AccountType.BASIC ||
                    company.account_type === AccountType.PREMIUM ? (
                      <Chip
                        color={
                          company.account_type === AccountType.PREMIUM
                            ? "primary"
                            : "secondary"
                        }
                        size="small"
                        label={company.account_type}
                      />
                    ) : (
                      ""
                    )}
                    {company.approved === false && (
                      <Chip color="default" size="small" label="not approved" />
                    )}
                    <span>
                      {JSON.stringify(company.specialisations_domains)}
                    </span>
                  </li>
                ))}
            </ul> */}
          </div>
        </div>
      )}
    </div>
  );
}
