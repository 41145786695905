import { Grid, Button } from "@material-ui/core";
import * as ROUTES from "../types/routes";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

export default function SignUpToNewsletter() {
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className="sign-up-to-newsletter"
    >
      <Grid
        item
        xs={12}
        alignItems="center"
        justify="center"
        className="container"
      >
        <h2>Sign up to the Newsletter</h2>
        <h1>Receive monthly updates directly in your inbox</h1>

        <div
          className="content  contact-us "
          style={{ width: "100%", margin: 0, padding: 0 }}
        >
          <div className="contact-us__form">
            <div className="contact-us__form-container">
              <form
                action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                method="POST"
              >
                <input type="hidden" name="oid" value="00D3z0000034CHl" />
                <input
                  type="hidden"
                  name="retURL"
                  value="https://marketplace.dataleaders.net/subscription-confirmation"
                />
                <input
                  type="hidden"
                  name="LeadSource"
                  id="LeadSource"
                  value="MP newsletter"
                />
                <div className="even-columns">
                  <div>
                    <div className="fieldWrapper">
                      <label htmlFor="first_name">First Name</label>
                      <input
                        id="first_name"
                        maxLength={40}
                        name="first_name"
                        size={20}
                        style={{ padding: "12px", width: "100%" }}
                        type="text"
                      />
                    </div>
                    <div className="fieldWrapper">
                      <label htmlFor="last_name">Last Name</label>
                      <input
                        id="last_name"
                        maxLength={80}
                        name="last_name"
                        size={20}
                        style={{ padding: "12px", width: "100%" }}
                        type="text"
                      />
                    </div>
                    <div className="fieldWrapper">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        maxLength={80}
                        name="email"
                        size={20}
                        style={{ padding: "12px", width: "100%" }}
                        type="text"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="fieldWrapper">
                      <label htmlFor="company">Company</label>
                      <input
                        id="company"
                        maxLength={40}
                        name="company"
                        size={20}
                        style={{ padding: "12px", width: "100%" }}
                        type="text"
                      />
                    </div>
                    <div className="fieldWrapper">
                      <label htmlFor="title">Title</label>
                      <input
                        id="title"
                        maxLength={40}
                        name="title"
                        size={20}
                        style={{ padding: "12px", width: "100%" }}
                        type="text"
                      />
                    </div>
                    <div className="fieldWrapper">
                      <label htmlFor="mobile">Mobile</label>
                      <input
                        id="mobile"
                        maxLength={40}
                        name="mobile"
                        size={20}
                        style={{ padding: "12px", width: "100%" }}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="center">
                  <input
                    className="lower-case-button theme-button subscribe-btn"
                    type="submit"
                    name="Subscribe"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
