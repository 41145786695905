export const LANDING = "/";
export const HOME = "/home";

// Marketplace
export const HOW_WORKS = "/how-it-works";
export const LOGIN = "/login";
export const RESET_PASSWORD = "/reset-password";
export const REGISTRATION = "/registration-form";
export const CLAIM_PRESENCE = "/claim-your-presence";
export const ABOUT_MARKETPLACE = "/about";
export const SEARCH_RESULTS = "/search-results";
export const COMPANY_DETAILS = "/companies";
export const CONTACT_US = "/contact-us";
export const SUBSCSCRIPTION_CONFIRMATION = "/subscription-confirmation";
export const DATA_LEADERS_CONTACT = "https://dataleaders.net/contact-us/";
export const DATA_LEADERS_ABOUT = "https://dataleaders.net/about-us/";
export const DATA_LEADERS_ATTEND = "https://dataleaders.net/data-events/";
export const DATA_LEADERS_HOME = "https://dataleaders.net/";
export const DATA_LEADERS_HUB = "https://hub.dataleaders.net/users/sign_in";
export const DATA_LEADERS_HUB_CONTACT =
  "https://dataleaders.net/memberships-form/";
export const DATA_LEADERS_GET_LISTED =
  "https://marketplace.dataleaders.net/claim-your-presence";
export const LINKEDIN = "https://www.linkedin.com/company/dataleaders/";

// Admin portal (Admin)
export const ACCOUNT_HOME = "/account/home";
export const EDIT_COMPANY = "/admin/edit-company";
export const ALL_COMPANIES = "/admin/companies";
export const NEW_COMPANY = "/admin/add-company";
export const NEW_ACCOUNT = "/admin/new-account";
export const ALL_ACCOUNTS = "/admin/accounts";
export const UPLOAD_COMPANIES = "/admin/upload-scraped-companies";
export const PREVIEW_COMPANY = "/admin/preview-company";
// Partner
// export const PARTNER_ACCOUNT = "/partner";
export const PARTNER_PREVIEW_PROFILE = "/partner/preview-profile";
export const PARTNER_MANAGE_PROFILE = "/partner/manage-profile";
