import { Box, CircularProgress, Grid } from "@mui/material";
import algoliasearch from "algoliasearch/lite";
import {
  Index,
  InfiniteHits,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-hooks-web";
import ClaimYourPresence from "../components/claim_your_presence";
import { CompanyItem } from "../components/search_results/companyItem";
import { EventItem } from "../components/search_results/eventItem";
import { InsightItem } from "../components/search_results/insightItem";
import ResultsMenu, {
  VirtualMenu,
  VirtualNumericMenu,
} from "../components/search_results/resultsMenu";
import { useState } from "react";
import Refinements from "../components/search_results/refinements";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID as string,
  process.env.REACT_APP_ALGOLIA_API_KEY as string
);

const menuOptions = [
  {
    label: "software providers",
    indexName: "company_name",
    configFilter: "software provider",
    filters: [
      "specialisations_domains",
      "focus_areas",
      "industry_focus",
      "maturity",
    ],
  },
  {
    label: "services providers",
    indexName: "company_name",
    configFilter: "services provider",
    filters: [
      "specialisations_domains",
      "focus_areas",
      "industry_focus",
      "maturity",
      "countries",
    ],
  },
  {
    label: "data providers",
    indexName: "company_name",
    configFilter: "data provider",
    filters: [
      "specialisations_domains",
      "focus_areas",
      "industry_focus",
      "maturity",
    ],
  },
];

function Hit({ hit }: { hit: any }) {
  let hitComponent = null;
  switch (hit.index) {
    case "company_name":
      hitComponent = (
        <CompanyItem
          featured={hit.featured_company}
          companyDatum={{ ...hit, id: hit.objectID }}
          matchScore={hit.__position}
          searchTerm="test"
        />
      );
      break;
    case "insight_name":
      hitComponent = <InsightItem insightDatum={hit} />;
      break;
  }
  return hitComponent;
}

export default function AlgoliaResults() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [shouldAutoSelectTab, setShouldAutoSelectTab] = useState(true);

  return (
    <>
      {/* <Grid
        container
        direction="row"
        alignItems="flex-end"
        className="shallow-hero"
      >
        <Grid item xs={12} md={12}>
          <div className="content">
            <h1 className="search-heading hero-title">Results</h1>
            <p className="hero-body">
              Information about the companies, people, products and services
              that matter to you.
            </p>
          </div>
        </Grid>
      </Grid> */}
      <div className="content search-results">
        <div style={{ margin: "20px 0 40px" }}>
          <h1
            style={{
              color: "rgb(41, 183, 179)",
              fontSize: "22px",
              fontWeight: 300,
              margin: "0 0 1em 0",
              padding: 0,
              lineHeight: "22px",
            }}
          >
            DataSphere
          </h1>
          <h2
            style={{
              color: "rgb(9, 46, 76)",
              fontSize: "42px",
              margin: 0,
              padding: 0,
              // maxWidth: "17ch",
            }}
          >
            Connect with real users of a solution.
          </h2>
        </div>
        <InstantSearch
          searchClient={searchClient}
          indexName={"company_name"}
          routing={true}
        >
          <SearchBox
            placeholder="Search for a company, domain or challenge"
            autoFocus
            classNames={{ input: "dl-input" }}
            onKeyDownCapture={() =>
              !shouldAutoSelectTab && setShouldAutoSelectTab(true)
            }
            onBlur={() => setShouldAutoSelectTab(false)}
          />
          <ResultsMenu
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            menuOptions={menuOptions}
            loading={loading}
            setLoading={setLoading}
            shouldAutoSelectTab={shouldAutoSelectTab}
          />
          {menuOptions.map((option, tab) => {
            return (
              <Box
                key={option.label}
                sx={{ display: tab === selectedTab ? "block" : "none" }}
              >
                <Index
                  indexName={option.indexName}
                  indexId={option.label.replaceAll(" ", "-")}
                >
                  <VirtualMenu attribute="company_type" />

                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "hsl(8, 100%, 68%)",
                      }}
                    >
                      <CircularProgress color="inherit" size="80px" />
                    </Box>
                  ) : (
                    <>
                      {option.filters ? (
                        <Refinements
                          filters={option.filters}
                          indexId={option.label.replaceAll(" ", "-")}
                        />
                      ) : (
                        <Box sx={{ p: 2 }} />
                      )}
                      <InfiniteHits
                        hitComponent={Hit}
                        className="search-results"
                        showPrevious={false}
                        transformItems={(items) => {
                          return items.map((item) => ({
                            ...item,
                            index: option.indexName,
                          }));
                        }}
                      />
                    </>
                  )}
                </Index>
              </Box>
            );
          })}
        </InstantSearch>

        {/* <ClaimYourPresence /> */}
      </div>
    </>
  );
}
