import { CompanyData } from "../types/companies";

export function getArraysIntersection(a1: string[], a2: string[]) {
  // console.log(a1);
  // console.log(a2);
  const lowercaseda2 = a2.map((item) => item.toLowerCase());
  return a1.filter(function (n) {
    return lowercaseda2.indexOf(n.toLowerCase()) !== -1;
  });
} // https://www.codegrepper.com/code-examples/whatever/javascript+find+common+values+in+two+arrays
export function checkIfArraysOverlap(a1: string[], a2: string[]) {
  return getArraysIntersection(a1, a2).length >= 1;
}

export function doArraysMatch(a1: string[], a2: string[]): boolean {
  return getArraysIntersection(a1, a2).length === a1.length;
}
export function doArraysOverlap(a1: string[], a2: string[]): boolean {
  return checkIfArraysOverlap(a1, a2);
}

function rand(min: number, max: number): number {
  return min + Math.random() * (max - min);
}

export function verifyEmail(email: string) {
  const cleanEmail = email.trim();
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(cleanEmail).toLowerCase());
}

export const indexOfCaseInsensitive = (arr: string[], q: string): number =>
  arr.findIndex((item) => q.toLowerCase() === item.toLowerCase());

export const stringCompareCaseInsensitive = (a: string, b: string): boolean =>
  a.toLocaleLowerCase() === b.toLocaleLowerCase();

export function getRandomHslColor(): string {
  // const purple = "hsl(256,23%,43%)";
  // const turquoise = "hsl(168,65%,73%)";
  // const yellow = "hsl(54,100%,49%)";
  // const red = "hsl(3,89%,64%)";
  // const grey = "hsl(40,11%,89%)";
  var h = rand(1, 360);
  var s = rand(0, 100);
  var l = rand(0, 100);
  return "hsl(" + h + "," + s + "%," + l + "%)";
}

const purple = "hsl(256,23%,43%)";
const turquoise = "hsl(168,65%,73%)";
const yellow = "hsl(54,100%,49%)";
const red = "hsl(3,89%,64%)";
const grey = "hsl(40,11%,89%)";

export const colorsForGraph: string[] = [purple, turquoise, red, yellow, grey];

export function isInArray(arr: string[], q: string): boolean {
  return arr.findIndex((item) => q.toLowerCase() === item.toLowerCase()) > -1;
}

export function getRandom(arr: CompanyData[], n: number): CompanyData[] {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export function capitalise(wordOrSentence: string) {
  if (
    wordOrSentence === undefined ||
    wordOrSentence === null ||
    wordOrSentence.length < 1
  ) {
    return "Undefined";
  }
  if (wordOrSentence === "dbms") {
    return "DBMS";
  }
  if (wordOrSentence === "Analytics & ML") {
    return "Analytics & ML";
  }
  return wordOrSentence.charAt(0).toUpperCase() + wordOrSentence.slice(1);
}
