import React from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { getCompanyById } from "../utils/database";
import { CompanyData } from "../types/companies";
import { useParams } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Breadcrumbs } from "@material-ui/core";
import OverviewTab from "../components/vendor_page/OverviewTab";
import PeopleCultureTab from "../components/vendor_page/PeopleCultureTab";
import DataCapabilityTab from "../components/vendor_page/DataCapabilityTab";
import ContentTab from "../components/vendor_page/ContentTab";
import LiveUsersTab from "../components/vendor_page/LiveUsersTab";
import ReactGA from "react-ga";
import { Grid, CircularProgress } from "@material-ui/core";
import * as ROUTES from "../types/routes";
import Alert from "@material-ui/lab/Alert";
import { userRoleType } from "../types/users";
import { storage, ref, getDownloadURL } from "../utils/firebase";
import { ImageOutlined } from "@material-ui/icons";

function TabPanel(props: any) {
  const { children, selectedTab, index, ...other } = props;

  return <div {...other}>{selectedTab === index && <Box>{children}</Box>}</div>;
}

interface CompanyDetailsDisplayProps {
  companyDatum: CompanyData;
  suggestedCompanies: CompanyData[] | undefined;
  userRole: userRoleType;
  previewOnly: boolean;
}

enum TAB_NUMBER {
  LIVE_USERS = 0,
  OVERVIEW = 1,
  CONTENT = 2,
  DATA_CAPABILITY = 3,
  PEOPLE_AND_CULTURE = 4,
}

const TAB_NUMBER_TO_PATHNAME = {
  [TAB_NUMBER.OVERVIEW]: "/overview",
  [TAB_NUMBER.PEOPLE_AND_CULTURE]: "/people-and-culture",
  [TAB_NUMBER.DATA_CAPABILITY]: "/data-capability",
  [TAB_NUMBER.CONTENT]: "/content",
  [TAB_NUMBER.LIVE_USERS]: "/live-users",
};

// const PATHNAME_TO_TAB_NUMBER = Object.keys(TAB_NUMBER_TO_PATHNAME).reduce(
//   (acc, cur) => {
//     acc[TAB_NUMBER_TO_PATHNAME[cur]] = parseInt(cur);
//     return acc;
//   },
//   {}
// );

function CompanyDetailsDisplay({
  companyDatum,
  suggestedCompanies,
  userRole = userRoleType.USER,
  previewOnly = false,
}: CompanyDetailsDisplayProps) {
  ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.event({
    category: "Company",
    action: "Viewed Company Details",
    label: companyDatum["company_name"],
    nonInteraction: true,
  });
  const history = useHistory();

  React.useEffect(() => {
    return history.listen((location) => {
      const updateTabIfNeccessary = () => {
        const newTab = determineTabFromPathname(location.pathname);
        if (newTab !== selectedTab) {
          setSelectedTab(newTab);
        }
      };
      if (history.action === "PUSH" || history.action === "POP") {
        updateTabIfNeccessary();
      }
    });
  });

  React.useEffect(() => {
    document.title = companyDatum.company_name + " - Data Leaders Marketplace";
  }, []);

  const isPaidAccount = () => {
    return (
      companyDatum["account_type"] === "basic" ||
      companyDatum["account_type"] === "premium"
    );
  };

  const determineTabFromPathname = (pathname: string): TAB_NUMBER => {
    if (pathname.includes("content")) {
      return TAB_NUMBER.CONTENT;
    } else if (pathname.includes("data-capability")) {
      return TAB_NUMBER.DATA_CAPABILITY;
    } else if (pathname.includes("people-and-culture")) {
      return TAB_NUMBER.PEOPLE_AND_CULTURE;
    } else if (pathname.includes("overview")) {
      return TAB_NUMBER.OVERVIEW;
    } else if (pathname.includes("live-users")) {
      return TAB_NUMBER.LIVE_USERS;
    } else {
      return 0;
    }
  };

  const page = useLocation();
  const [selectedTab, setSelectedTab] = React.useState(
    determineTabFromPathname(page.pathname)
  );
  const [imageUrl, setImageUrl] = React.useState("");
  React.useEffect(() => {
    if (
      companyDatum["company_logo_file_location"] !== null &&
      companyDatum["company_logo_file_location"] !== undefined &&
      companyDatum["company_logo_file_location"].includes("gs://")
    ) {
      getDownloadURL(ref(storage, companyDatum["company_logo_file_location"]))
        .then((url: string) => {
          setImageUrl(url);
        })
        .catch((error: any) => {
          console.error("Error retrieving logo from Google Storage: " + error);
        });
    } else {
      setImageUrl(
        process.env.PUBLIC_URL + companyDatum["company_logo_file_location"]
      );
    }
  }, [, companyDatum]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newSelectedTab: TAB_NUMBER
  ): void => {
    setSelectedTab(newSelectedTab);
    if (!previewOnly) {
      TAB_NUMBER_TO_PATHNAME[newSelectedTab] &&
        history.push(
          ROUTES.COMPANY_DETAILS +
            "/" +
            companyDatum.company_name +
            TAB_NUMBER_TO_PATHNAME[newSelectedTab]
        );
    }
  };
  return (
    <div>
      <div className="company-details-header">
        <div className="breadcrumbs">
          <div className="content content__breadcrumbs">
            {" "}
            {!previewOnly && (
              <div className="breadcrumbs__current-path">
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Link color="inherit" to="/home">
                    Home
                  </Link>
                  <span>Companies</span>
                  <span>{companyDatum.company_name}</span>
                </Breadcrumbs>
              </div>
            )}
          </div>
        </div>
        <div className="company-details-header__background"></div>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-end"
          className="company-details-header__company-name"
        >
          {imageUrl !== "" ? (
            <div
              className="company-details-header__logo"
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
            ></div>
          ) : (
            <div className="company-details-header__logo company-details-header__logo--placeholder">
              <ImageOutlined
                style={{ width: "80%", fontSize: "80px", color: "#dde0e6" }}
              />
            </div>
          )}

          <div className="company-details-header__name">
            <h2>{companyDatum["company_name"]} </h2>
            <p>Company Type:&nbsp;{companyDatum["company_type"]}</p>
          </div>
        </Grid>
      </div>
      <div className="content details-content">
        {(userRole === userRoleType.ADMIN ||
          userRole === userRoleType.PARTNER) &&
          previewOnly &&
          !companyDatum.approved && (
            <Alert
              style={{ textAlign: "center", marginBottom: "20px" }}
              severity="warning"
            >
              PREVIEW ONLY - Company still in draft
            </Alert>
          )}

        <AppBar position="static" className="nav-tabs">
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant="scrollable"
          >
            <Tab className="tab--accent" label="Connect with users" />
            <Tab label="Overview" />
            <Tab label="Content" />
            <Tab label="Data Capability" />
            <Tab label="People and Culture" />
          </Tabs>
        </AppBar>
        <TabPanel selectedTab={selectedTab} index={0}>
          <LiveUsersTab companyDatum={companyDatum} />
        </TabPanel>
        <TabPanel selectedTab={selectedTab} index={1}>
          <OverviewTab
            companyDatum={companyDatum}
            suggestedCompanies={suggestedCompanies}
          />
        </TabPanel>
        <TabPanel selectedTab={selectedTab} index={2}>
          {isPaidAccount() ? (
            <ContentTab companyDatum={companyDatum} />
          ) : (
            <div className="container-box">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <div className="company-details-information item company-details-information__premium-account-info">
                    <h3 style={{ margin: 0 }}>Not available</h3>
                    <p>
                      The company has not provided any information regarding
                      additional content.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </TabPanel>

        <TabPanel selectedTab={selectedTab} index={3}>
          {isPaidAccount() ? (
            <DataCapabilityTab companyDatum={companyDatum} />
          ) : (
            <div className="container-box">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <div className="company-details-information item company-details-information__premium-account-info">
                    <h3 style={{ margin: 0 }}>Not available</h3>
                    <p>
                      The company has not provided any information regarding
                      their data capability.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </TabPanel>
        <TabPanel selectedTab={selectedTab} index={4}>
          <PeopleCultureTab companyDatum={companyDatum} />
        </TabPanel>
      </div>
    </div>
  );
}

type Props = {
  userRole: userRoleType;
  userCompany: string;
  previewOnly: boolean;
};

export const CompanyDetails: React.FC<Props> = ({
  userRole = userRoleType.USER,
  userCompany = "",
  previewOnly = false,
}) => {
  let { id } = useParams<any>();
  const [companyDatum, setCompanyDatum] = React.useState<CompanyData>();
  const [loading, setLoading] = React.useState(true);
  const [suggestedCompanies, setSuggestedCompanies] =
    React.useState<CompanyData[]>();

  React.useEffect(() => {
    getCompanyById(id, userRole)
      .then(setCompanyDatum)
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [, id]);

  let child: JSX.Element | null = null;
  if (loading) {
    child = (
      <div className="page-loader-container">
        <CircularProgress />
      </div>
    );
  } else if (companyDatum == null || companyDatum === undefined) {
    child = (
      <div className="page-loader-container">
        <div className="no-results">
          <h2>Company not found</h2>
          <p>Could not find any matches for "{id}".</p>
          <br />
          <Link to={ROUTES.HOME}>&lt; back to homepage</Link>
        </div>
      </div>
    );
  } else {
    child = (
      <CompanyDetailsDisplay
        companyDatum={companyDatum}
        suggestedCompanies={suggestedCompanies}
        userRole={userRole}
        previewOnly={previewOnly}
      />
    );
  }
  // } else {
  //   child = (
  //     <div className="page-loader-container">
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  return <div>{child}</div>;
};
