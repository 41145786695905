import { CompanyData } from "../../types/companies";
import ReactGA from "react-ga";
import { PaddedPaper } from "../../components/padded_paper";
import { Link } from "react-router-dom";
import { Grid, Hidden } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { InsightData } from "../../types/insights";
import * as ROUTES from "../../types/routes";
// TODO: Type (TypeScript) things properly
interface InsightItemProps {
  insightDatum: InsightData;
}

export function InsightItem({ insightDatum }: InsightItemProps) {
  function decodeHtml(html: any) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  // ReactGA.event({
  //   category: "Search Results",
  //   action: "Appeared in search: " + searchTerm,
  //   label: eventDatum.title,
  //   value: undefined,
  //   nonInteraction: true,
  // });
  return (
    <div
      style={{ backgroundColor: "transparent", border: "1px solid #e0dede" }}
    >
      <a className="company-link" href={insightDatum["url"]} target="_blank">
        <>
          {" "}
          <Hidden only={["xs", "sm"]}>
            <Grid
              container
              spacing={0}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item md={3}>
                <div
                  style={{
                    height: "148px",
                    width: "100%",
                    backgroundImage: `url(${insightDatum["image_url"]})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    margin: "auto",
                  }}
                ></div>
              </Grid>

              <Grid item md={9}>
                <Grid container spacing={0} direction="column" justify="center">
                  <Grid item md={12}>
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      justify="center"
                    ></Grid>
                  </Grid>
                  <Grid item md={12}>
                    <div
                      style={{
                        padding: "0 20px",
                      }}
                    >
                      <h3
                        style={{
                          textAlign: "left",
                          margin: 0,
                          padding: 0,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {decodeHtml(insightDatum["title"])}
                      </h3>
                      <p className="company-about">Read more &gt;</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden only={["md", "lg", "xl"]}>
            <Grid
              container
              spacing={0}
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} style={{ overflow: "hidden" }}>
                <div
                  style={{
                    height: "248px",
                    width: "100%",
                    minWidth: "922px",
                    backgroundImage: `url(${insightDatum["image_url"]})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    margin: "auto",
                    overflowX: "hidden",
                  }}
                ></div>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={0} direction="column" justify="center">
                  <Grid item md={12}>
                    <div
                      style={{
                        padding: "20px 20px",
                      }}
                    >
                      <h3 style={{ textAlign: "left", margin: 0, padding: 0 }}>
                        {decodeHtml(insightDatum["title"])}
                      </h3>
                      <p className="company-about">Read more &gt;</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </>
      </a>
    </div>
  );
}
