import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  useRefinementList,
  UseRefinementListProps,
} from "react-instantsearch-hooks-web";

export default function AlgoliaSelectFilter(
  props: UseRefinementListProps & any
) {
  const { items, refine } = useRefinementList(props);

  // Need selected state for improved UI (not waiting for the refinement list to update)
  const handleChange = (event: any) => {
    const changeValue = event.target.value;
    const selectedOption = changeValue.filter(
      (v: string) => v !== props.label
    )[0];

    refine(selectedOption);
  };

  return (
    <FormControl
      size="small"
      sx={{ my: 1, width: "50%", maxWidth: 250, px: 1 }}
    >
      <Select
        multiple
        value={[props.label]}
        renderValue={(value) => <span style={{ color: "grey" }}>{value}</span>}
        onChange={handleChange}
        disabled={items.length === 0}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          style: { maxHeight: "50vh", marginTop: "0.5em" },
        }}
      >
        {items.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox
                checked={item.isRefined}
                sx={{
                  "&.Mui-checked": {
                    color: "hsl(8, 100%, 68%)",
                  },
                }}
              />
              <ListItemText
                primary={item.label}
                sx={{
                  textTransform: "uppercase",
                  mr: 1,
                  "& .MuiListItemText-primary": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "0.875rem",
                  },
                }}
              />
              <Typography
                variant="overline"
                sx={{ color: "hsl(8, 100%, 68%)", fontSize: "0.625em" }}
              >{`(${item.count})`}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
