import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckIcon from "@material-ui/icons/Check";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import GroupIcon from "@material-ui/icons/Group";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { AccountType } from "../../types/companies";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { StockChart } from "../stockChart";
// import LinkedInLogo from "../../images/icons/linked_in.png";
import { PieChart } from "react-minimal-pie-chart";
import { PaddedPaper } from "../padded_paper";
import { CompanyData } from "../../types/companies";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LinkIcon from "@material-ui/icons/Link";
import { colorsForGraph, getRandomHslColor } from "../../utils/utils";
import { ExecutiveTeamProfile } from "./ExecutiveTeamProfile";

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Button,
  CardMedia,
  Divider,
} from "@material-ui/core";

type GeographicSegmentsData = {
  title: string;
  value: number;
  color: string;
  totalValue: number;
}[];

interface CompanyDetailsDisplayProps {
  companyDatum: CompanyData;
}

function createData(date: string, article: string) {
  return { date, article };
}

export default function PeopleCultureTab({
  companyDatum,
}: CompanyDetailsDisplayProps) {
  const [showLocationAmount, setShowLocationAmount] = React.useState<number>(8);
  const [value, setValue] = React.useState(0);
  const [showPeopleAmount, setShowPeopleAmount] = React.useState<number>(4);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const showOpenPositions =
    companyDatum["open_positions"] !== null &&
    companyDatum["open_positions"] !== undefined;

  const showExecutiveTeam =
    companyDatum["executive_team"] &&
    companyDatum["executive_team"] !== null &&
    companyDatum["executive_team"] !== undefined;

  const rows = [createData("Date", "Article title")];

  const checkAccountType = (): AccountType => {
    if (companyDatum["account_type"] === "premium") {
      return AccountType.PREMIUM;
    }
    if (companyDatum["account_type"] === "basic") {
      return AccountType.BASIC;
    }
    return AccountType.STARTER;
  };

  const isPaidAccount = () => {
    return (
      companyDatum["account_type"] === "basic" ||
      companyDatum["account_type"] === "premium"
    );
  };

  return (
    <div className="container-box">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="item">
            <Grid item xs={12}>
              <div className="company-details-information staff-profiles">
                <h4>Executive team</h4>
                {showExecutiveTeam ? (
                  <Grid container spacing={2}>
                    {companyDatum["executive_team"] &&
                      companyDatum["executive_team"]
                        .filter((_: any, i: number) => {
                          if (i >= showPeopleAmount) {
                            return false;
                          }
                          return true;
                        })
                        .map((executiveTeamMember: any) => {
                          return (
                            <ExecutiveTeamProfile
                              profile={executiveTeamMember}
                            />
                          );
                        })}
                  </Grid>
                ) : (
                  <div className="no-results">
                    <span className="main">No Executive Team Profiles</span>
                    <span className="reason">
                      The company has not listed any executive team profiles.
                    </span>
                  </div>
                )}
                {showExecutiveTeam &&
                  companyDatum["executive_team"]!.length - showPeopleAmount >
                    0 && (
                    <Button
                      className="lower-case-button theme-button outline fullwidth-button"
                      variant="outlined"
                      onClick={() => setShowPeopleAmount(showPeopleAmount + 8)}
                    >
                      Show more profiles (
                      {companyDatum["executive_team"]!.length -
                        showPeopleAmount}{" "}
                      more)
                    </Button>
                  )}
              </div>
            </Grid>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className="item">
            <h3>Open Positions</h3>

            {companyDatum["open_positions_link"] ? (
              <div style={{ textAlign: "center", padding: "0px 0px 40px" }}>
                <p>
                  Want a career at {companyDatum["company_name"]}? See all the
                  open positions available.
                </p>
                <Button
                  variant="contained"
                  href={companyDatum["open_positions_link"]}
                >
                  Go To Open Positions
                </Button>
              </div>
            ) : showOpenPositions ? (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <TableContainer component={Paper}>
                    <Table className="table" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Article</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.date}>
                            <TableCell component="th" scope="row">
                              {row.date}
                            </TableCell>
                            <TableCell>{row.article}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <div className="no-results">
                <span className="main">No Open Positions</span>
                <span className="reason">
                  When the company shares any open positions they will appear
                  here.
                </span>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {/* <Grid
        container
        direction="row"
        justify="center"
        alignItems="stretch"
        spacing={0}
        className="details-row locations"
      >
        {isPaidAccount() &&
          companyDatum["all_locations"] &&
          companyDatum["all_locations"].length !== 0 && (
            <>
              <Grid item xs={12} className="company-details-information">
                <h3>All {companyDatum["company_name"]} Locations</h3>
                <Grid container spacing={5}>
                  {companyDatum["all_locations"]
                    .filter((_, i) => {
                      if (i >= showLocationAmount) {
                        return false;
                      }
                      return true;
                    })
                    .map((location) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={location["address"]}
                        >
                          <PaddedPaper>
                            <ul>
                              <li className="location-address">
                                {location["address"]}
                              </li>
                              <li>
                                <span className="city">{location["city"]}</span>
                              </li>
                              <li>{location["country"]}</li>
                            </ul>
                          </PaddedPaper>
                        </Grid>
                      );
                    })}
                </Grid>
                {companyDatum["all_locations"].length - showLocationAmount >
                  0 && (
                  <Button
                    className="lower-case-button theme-button outline fullwidth-button"
                    variant="outlined"
                    onClick={() =>
                      setShowLocationAmount(showLocationAmount + 8)
                    }
                  >
                    Show more locations (
                    {companyDatum["all_locations"].length - showLocationAmount}{" "}
                    more)
                  </Button>
                )}
              </Grid>
            </>
          )} */}
      {/* {companyDatum["general_contact_email"] != null &&
          companyDatum["general_contact_email"] != "" && (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}
              className="show-interest"
            >
              <Grid item xs={12} md={12}>
                <h2>Would you like to know more about this company?</h2>
                <Button
                  className="lower-case-button theme-button outline"
                  variant="outlined"
                  color="primary"
                  href={"mailto:" + companyDatum["general_contact_email"]}
                >
                  Show an interest
                </Button>
              </Grid>
            </Grid>
          )} */}
      {/* </Grid> */}
    </div>
  );
}
