import React from "react";
import { useHistory, Link } from "react-router-dom";
import * as ROUTES from "../../types/routes";
import { verifyEmail } from "../../utils/utils";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import {
  createUserWithEmailAndPassword,
  getAuth,
  updateCurrentUser,
  UserCredential,
} from "firebase/auth";
import {
  AccountData,
  addNewAccount,
  updateCompany,
} from "../../utils/database";
import { timeStamp } from "../../utils/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Button } from "@material-ui/core";

export default function NewAccount() {
  const auth = getAuth();
  const history = useHistory();

  const [success, setSuccess] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);

  const [company, setCompany] = React.useState("");
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const functions = getFunctions();
  const createAccount = httpsCallable(functions, "createAccount");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const adminUserWhoAddedNewUser = getAuth().currentUser;
    e.preventDefault();

    if (email === null || !verifyEmail(email)) {
      setInvalidEmail(true);
      return;
    }

    try {
      createAccount({
        email: email,
        company: company,
      }).then((res: any) => {
        setPassword(res.data.password);
        addNewAccount({
          id: res.data ? res.data.uid.toString() : "No ID retrieved",
          company: company,
          email: email,
          date_added: timeStamp.now(),
        }).then(() => setSuccess(true));
      });
    } catch (e) {
      console.error(error);
      setError(true);
    }
    await updateCurrentUser(auth, adminUserWhoAddedNewUser);
  };

  return (
    <div className="new-account">
      {success ? (
        <div className="">
          <Alert severity="success">Account successfully created.</Alert>
          <div>
            <p>Company: {company}</p>
            <p>Email: {email}</p>
            <p>Password: {password}</p>

            <Alert severity="info">
              Please ask the account holder to change the password via the
              "Forgot password" link on the login page.
            </Alert>
          </div>
        </div>
      ) : (
        <form className="form" onSubmit={handleSubmit}>
          <h2>New account</h2>
          <input
            className="emailField"
            type="text"
            value={company}
            placeholder="Company name"
            onChange={(e) => setCompany(e.target.value)}
          />
          <input
            className="emailField"
            type="text"
            value={email}
            placeholder="Email address"
            onChange={(e) => setEmail(e.target.value)}
          />
          {invalidEmail && (
            <Alert severity="error">Please enter a valid email address</Alert>
          )}

          {error && (
            <Alert severity="error">
              There was an error creating the account. Please try again.
            </Alert>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              style={{ marginRight: "10px" }}
              variant="outlined"
              href={ROUTES.ALL_ACCOUNTS}
              className="btn--admin btn--admin--cancel"
            >
              Cancel
            </Button>

            <Button
              size="small"
              type="submit"
              variant="contained"
              disableElevation
              disableFocusRipple
              className="btn--admin btn--admin--confirm"
            >
              Create Account
            </Button>
            {/* <input
              className="btn btn--login"
              type="submit"
              value="Create Account"
            /> */}
          </div>
        </form>
      )}
      <p></p>
    </div>
  );
}
