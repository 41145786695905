import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import ReactGA from "react-ga";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import emailjs from "emailjs-com";
import { init } from "@emailjs/browser";
import { LoadingAnimation } from "../components/loadingAnimation";
import * as ROUTES from "../types/routes";

export default function SubscriptionConfirmation() {
  return (
    <div className="content center contact-us">
      <div className="contact-us__form">
        <div className="contact-us__form-container">
          <Paper elevation={2}>
            <Box p={5}>
              <Alert severity="success">
                You have successfully subscribed to our newsletter.
              </Alert>
              <br />
              <br />
              <a href={ROUTES.HOME}>&lt;&nbsp;Back to homepage</a>
            </Box>
          </Paper>
        </div>
      </div>
    </div>
  );
}
