import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import { ReactComponent as Quotation } from "../../images/icons/quotation.svg";
import {
  DomainList,
  IndustryFocusList,
  FunctionFocusList,
  DataSourceTypeList,
} from "../../types/classification";

import {
  CompanyData,
  AccountType,
  CompanyTypeList,
  TagData,
} from "../../types/companies";

import { isInArray } from "../../utils/utils";
import { Grid, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TickIcon from "../../images/icons/tick.png";
import CheckedIcon from "../../images/icons/checked.png";
import Tooltip from "@material-ui/core/Tooltip";
import * as ROUTES from "../../types/routes";
import { getTags } from "../../utils/database";

type GeographicSegmentsData = {
  title: string;
  value: number;
  color: string;
  totalValue: number;
}[];

interface CompanyDetailsDisplayProps {
  companyDatum: CompanyData;
}

function createData(date: string, article: string) {
  return { date, article };
}

export default function DataCapabilityTab({
  companyDatum,
}: CompanyDetailsDisplayProps) {
  const [numberFeaturesToShow, setNumberFeaturesToShow] =
    React.useState<number>(4);
  const [tags, setTags] = React.useState<TagData[]>();

  const rows = [createData("Date", "Article title")];
  const companyHasFeature = (
    item: string,
    companyFeatures: { [id: string]: string }
  ): Boolean => {
    if (
      companyFeatures != null &&
      companyFeatures != undefined &&
      companyFeatures[item] != undefined
    ) {
      return true;
    } else {
      return false;
    }
  };
  const checkAccountType = (): AccountType => {
    if (companyDatum["account_type"] === "premium") {
      return AccountType.PREMIUM;
    }
    if (companyDatum["account_type"] === "basic") {
      return AccountType.BASIC;
    }
    return AccountType.STARTER;
  };

  const showFeatureInformation = companyDatum["feature_information"] != null;
  const showPortfolioInformation = companyDatum["product_description"] != null;
  const showCustomerReferences =
    companyDatum["customer_references"] != null &&
    companyDatum["customer_references"].length > 0;

  React.useEffect(() => {
    const fetchAllTags = async () => {
      const allTags: TagData[] = await getTags();
      setTags(allTags);
    };
    fetchAllTags();
  }, []);

  return (
    <div className="container-box">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <div className="company-details-information item">
            <h3>Feature List</h3>
            <Grid
              container
              spacing={0}
              direction="row"
              justify="center"
              className="inset-row"
            >
              <Grid item xs={12} md={4}>
                <h4>Company Type</h4>
                <ul className="dcm-list">
                  {CompanyTypeList.filter(
                    (item) =>
                      companyDatum["company_type"].toLowerCase() === item
                  ).map((item) => {
                    return (
                      <li className="available">
                        <CheckIcon className="dcm-icon" />
                        {item}
                      </li>
                    );
                  })}
                  {CompanyTypeList.filter(
                    (item) =>
                      companyDatum["company_type"].toLowerCase() !== item
                  ).map((item) => {
                    return (
                      <li className="not-available">
                        <CloseIcon className="dcm-icon" />
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <h4>Domain</h4>
                <ul className="dcm-list">
                  {DomainList.filter((item) =>
                    isInArray(companyDatum["specialisations_domains"], item)
                  ).map((item) => {
                    return (
                      <li className="available">
                        <CheckIcon className="dcm-icon" />
                        {item}
                      </li>
                    );
                  })}
                  {DomainList.filter(
                    (item) =>
                      !isInArray(companyDatum["specialisations_domains"], item)
                  )
                    .filter((_, i) => {
                      if (i > numberFeaturesToShow) {
                        return false;
                      } else {
                        return true;
                      }
                    })
                    .map((item) => {
                      return (
                        <li className="not-available">
                          <CloseIcon className="dcm-icon" />
                          {item}
                        </li>
                      );
                    })}
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <h4>Industry focus</h4>
                <ul className="dcm-list">
                  {IndustryFocusList.filter((item) =>
                    isInArray(companyDatum["industry_focus"], item)
                  ).map((item) => {
                    return (
                      <li className="available">
                        <CheckIcon className="dcm-icon" />
                        {item}
                      </li>
                    );
                  })}
                  {IndustryFocusList.filter(
                    (item) => !isInArray(companyDatum["industry_focus"], item)
                  )
                    .filter((_, i) => {
                      if (i > numberFeaturesToShow) {
                        return false;
                      } else {
                        return true;
                      }
                    })
                    .map((item) => {
                      return (
                        <li className="not-available">
                          <CloseIcon className="dcm-icon" />
                          {item}
                        </li>
                      );
                    })}
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <h4>Department</h4>
                <ul className="dcm-list">
                  {FunctionFocusList.filter((item) =>
                    isInArray(companyDatum["focus_areas"], item)
                  ).map((item) => {
                    return (
                      <li className="available">
                        <CheckIcon className="dcm-icon" />
                        {item}
                      </li>
                    );
                  })}
                  {FunctionFocusList.filter(
                    (item) => !isInArray(companyDatum["focus_areas"], item)
                  )
                    .filter((_, i) => {
                      if (i > numberFeaturesToShow) {
                        return false;
                      } else {
                        return true;
                      }
                    })
                    .map((item) => {
                      return (
                        <li className="not-available">
                          <CloseIcon className="dcm-icon" />
                          {item}
                        </li>
                      );
                    })}
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <h4>Features</h4>
                <ul className="dcm-list">
                  {tags != null &&
                    tags
                      .filter((item) => {
                        if (companyDatum["features"] !== null) {
                          return companyHasFeature(
                            item.value,
                            companyDatum["features"]
                          );
                        } else {
                          return false;
                        }
                      })
                      .map((item) => {
                        return (
                          <li className="available" key={item.id}>
                            {(companyDatum["features"] !== null &&
                              companyDatum["features"][item.value]) ===
                            "integrated" ? (
                              <Tooltip title="Integrated feature">
                                <div className="features__icons-integrated-standalone">
                                  <img
                                    src={CheckedIcon}
                                    className="features__icon-integrated"
                                    alt="tick icon"
                                  />{" "}
                                  {item.value}
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Standalone feature">
                                <div className="features__icons-integrated-standalone">
                                  {" "}
                                  <img
                                    src={TickIcon}
                                    className="features__icon-standalone"
                                    alt="checked icon"
                                  />{" "}
                                  {item.value}
                                </div>
                              </Tooltip>
                            )}
                          </li>
                        );
                      })}
                  {tags != null &&
                    tags
                      .filter((item) => {
                        if (companyDatum["features"] != null) {
                          return !companyHasFeature(
                            item.value,
                            companyDatum["features"]
                          );
                        } else {
                          return true;
                        }
                      })
                      .filter((_, i) => {
                        if (i > numberFeaturesToShow) {
                          return false;
                        } else {
                          return true;
                        }
                      })

                      .map((item) => {
                        return (
                          <li className="not-available">
                            <CloseIcon className="dcm-icon" />
                            {item.value}
                          </li>
                        );
                      })}
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <h4>Data source type</h4>
                <ul className="dcm-list">
                  {DataSourceTypeList.filter((_, i) => {
                    if (i > numberFeaturesToShow) {
                      return false;
                    } else {
                      return true;
                    }
                  }).map((item) => {
                    return (
                      <>
                        {companyDatum["data_source_type"] &&
                        isInArray(companyDatum["data_source_type"], item) ? (
                          <li className="available">
                            <CheckIcon className="dcm-icon" />
                            {item}
                          </li>
                        ) : (
                          <li className="not-available">
                            <CloseIcon className="dcm-icon" />
                            {item}
                          </li>
                        )}
                      </>
                    );
                  })}
                </ul>
              </Grid>
              <Grid item xs={12}>
                {numberFeaturesToShow < 5 ? (
                  <Button
                    className="lower-case-button theme-button outline fullwidth-button"
                    variant="outlined"
                    onClick={() => setNumberFeaturesToShow(100)}
                  >
                    Show all
                  </Button>
                ) : (
                  <Button
                    className="lower-case-button theme-button outline fullwidth-button"
                    variant="outlined"
                    onClick={() => setNumberFeaturesToShow(4)}
                  >
                    Show less
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
        {showFeatureInformation && (
          <Grid item xs={12}>
            <div className="company-details-information product-information item">
              <h3>Feature Information</h3>
              <p>{companyDatum.feature_information}</p>
            </div>
          </Grid>
        )}
        {showPortfolioInformation && (
          <Grid item xs={12} sm={12}>
            <div className="company-details-information intro item">
              <h3>Portfolio overview</h3>
              <p>{companyDatum.product_description}</p>
            </div>
          </Grid>
        )}

        {/* Add Deployment and support feature list  */}
        {/* Add feature description  */}
        {/* <Grid item xs={12}>
          <div className="company-details-information dcm item">
            <h3>Data Leaders 7 Axis</h3>
            <p>
              The Data Leaders 7 Axis are part of the Data Capability Matrix and
              are designed to help identify companies' core strengths in data
              handling and offering.{" "}
            </p>
            <p>
              The Data Capability Matrix gives a snapshot of the data status of
              each organization.
            </p>

            <Grid container>
              {companyDatum["7_axis_of_analysis"] &&
                Object.keys(companyDatum["7_axis_of_analysis"]).map((axis) => {
                  return (
                    <Grid item xs={12} sm={6}>
                      <div className="dcm_axis">
                        {" "}
                        <h3>
                          <CheckIcon className="overview-icon blue" />
                          {axis}
                        </h3>
                        <p className="highlight-data">
                          {companyDatum["7_axis_of_analysis"] != null
                            ? companyDatum["7_axis_of_analysis"][axis]
                            : ""}
                        </p>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </Grid> */}

        {showCustomerReferences && (
          <Grid item xs={12}>
            <div className="company-details-information product-information item">
              <h3>Customer references</h3>
              <div className="customer-references">
                {companyDatum["customer_references"]!.map((reference) => {
                  return (
                    <div className="customer-references__reference">
                      <Quotation />
                      {reference.text}
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
        )}

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
          className="details-row show-interest"
        >
          <Grid item xs={12} md={12}>
            <div className="center" style={{ padding: "3rem 0" }}>
              <h2>
                As a Data leader do you want to know where you and your team
                stand on the Data Leaders Data Capability Matrix?
              </h2>
              <p>
                The Data Capability Matrix is a tool created by Data Leaders to
                benchmark your organization around data maturity.
              </p>
              <Button
                className="lower-case-button theme-button outline"
                variant="outlined"
                color="primary"
                href={ROUTES.CONTACT_US}
              >
                Get in touch now
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
