import React from "react";
import { CompanyData } from "../../types/companies";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import { Grid, Hidden } from "@material-ui/core";
import * as ROUTES from "../../types/routes";
import { storage, ref, getDownloadURL } from "../../utils/firebase";
import { ImageOutlined } from "@material-ui/icons";
import { Paper } from "@mui/material";

// TODO: Type (TypeScript) things properly
interface CompanyItemProps {
  companyDatum: CompanyData;
  matchScore: number;
  searchTerm: string;
  featured?: boolean;
}

export function CompanyItem({
  companyDatum,
  matchScore,
  searchTerm,
  featured,
}: CompanyItemProps) {
  // console.log(
  //   companyDatum["company_name"] + "(match score: " + matchScore + ")"
  // );

  const [imageUrl, setImageUrl] = React.useState("");

  React.useEffect(() => {
    if (
      companyDatum["company_logo_file_location"] !== undefined &&
      companyDatum["company_logo_file_location"].includes("gs://")
    ) {
      getDownloadURL(ref(storage, companyDatum["company_logo_file_location"]))
        .then((url: string) => {
          setImageUrl(url);
        })
        .catch((error: any) => {
          console.error("Error retrieving logo from Google Storage: " + error);
        });
    } else {
      setImageUrl(
        process.env.PUBLIC_URL + companyDatum["company_logo_file_location"]
      );
    }
  }, [, companyDatum]);

  if (searchTerm !== "") {
    ReactGA.event({
      category: "Search Results",
      action: "Appeared in search: " + searchTerm,
      label: companyDatum["company_name"],
      value: matchScore,
      nonInteraction: true,
    });
  }

  return (
    <Paper
      elevation={0}
      sx={{ p: "1em" }}
      className={featured ? "featured" : ""}
    >
      <Link
        className="company-link"
        to={{
          pathname: `${ROUTES.COMPANY_DETAILS}/${companyDatum["id"]}`,
        }}
      >
        {/* Desktop */}
        <Hidden only={["xs", "sm"]}>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item md={1}>
              {imageUrl !== "" ? (
                <div
                  style={{
                    height: "5em",
                    width: "5em",
                    backgroundImage: `url(${imageUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    margin: "auto",
                  }}
                ></div>
              ) : (
                <div
                  style={{
                    height: "5em",
                    width: "5em",
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ImageOutlined
                    style={{ width: "80%", fontSize: "80px", color: "#dde0e6" }}
                  />
                </div>
              )}
            </Grid>
            <Grid item md={2}>
              <h3 className="company-title">{companyDatum["company_name"]}</h3>
            </Grid>
            <Grid item md={8}>
              <div className="company-description">
                <p className="company-about">
                  {companyDatum["about_the_company"]}
                </p>
              </div>
            </Grid>
            <Grid item md={1}>
              {/* <AddIcon /> */}
            </Grid>
          </Grid>
        </Hidden>

        {/* Mobile */}
        <Hidden only={["md", "lg", "xl"]}>
          <Grid
            container
            spacing={3}
            direction="column"
            justify="center"
            alignItems="center"
          >
            {/* <Grid item xs={12}> */}
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={6}>
                <div
                  style={{
                    height: "5em",
                    width: "5em",
                    backgroundImage: `url(${imageUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    marginLeft: "auto",
                    marginTop: 20,
                    marginBottom: 10,
                    marginRight: 20,
                  }}
                ></div>
              </Grid>
              <Grid item xs={6}>
                <h3 className="company-title">
                  {companyDatum["company_name"]}
                </h3>
              </Grid>
            </Grid>
            {/* </Grid> */}
            <Grid item xs={12}>
              <p className="company-about company-about--small">
                {companyDatum["about_the_company"]}
              </p>
            </Grid>

            <Grid item xs={12}>
              {/* <AddIcon /> */}
            </Grid>
          </Grid>
        </Hidden>
      </Link>
    </Paper>
  );
}
