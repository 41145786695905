import React from "react";
import { Link } from "react-router-dom";
import { AccountData, fetchCompany } from "../../utils/database";
import { adminFetchListOfAccounts } from "../../utils/database";
import Chip from "@material-ui/core/Chip";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import moment from "moment";
import { firestore } from "firebase-admin";
import Login from "../../components/login";
import { LoadingAnimation } from "../../components/loadingAnimation";
import * as ROUTES from "../../types/routes";
import { Button } from "@material-ui/core";
// import { UserContext } from "../../contexts/userContext";
import { Tab, Tabs, Box, Breadcrumbs } from "@material-ui/core";

const TESTING_ONLY =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";

export default function AllAccounts() {
  const [allAccounts, setAllAccounts] = React.useState([] as AccountData[]);
  const [loading, setLoading] = React.useState(true);
  const [accountsToShow, setAccountsToShow] = React.useState(
    [] as AccountData[]
  );
  const [partners, setPartners] = React.useState([] as AccountData[]);
  const [admins, setAdmins] = React.useState([] as AccountData[]);
  const getDate = (date: firestore.Timestamp | null | undefined) => {
    if (!date || date === undefined) return "--";
    // return JSON.stringify(date);
    // console.log(JSON.stringify(date?.toDate()));
    return moment(date?.toDate()).format("DD/MM/YYYY");
  };
  // const { admin } = React.useContext(UserContext);

  const isNew = (
    dateAdded: firestore.Timestamp | null | undefined
  ): boolean => {
    if (dateAdded === null || dateAdded === undefined) {
      return false;
    } else {
      return (
        moment(dateAdded.toDate()).fromNow().indexOf("second") > -1 ||
        moment(dateAdded.toDate()).fromNow().indexOf("minute") > -1 ||
        moment(dateAdded.toDate()).fromNow().indexOf("hour") > -1 ||
        moment(dateAdded.toDate()).fromNow().startsWith("2 days") ||
        moment(dateAdded.toDate()).fromNow().startsWith("3 days") ||
        moment(dateAdded.toDate()).fromNow().startsWith("4 days") ||
        moment(dateAdded.toDate()).fromNow().startsWith("5 days")
      );
    }
  };
  const [tabSelected, setTabSelected] = React.useState(0);
  const handleChange = (event: any, newValue: any) => {
    setTabSelected(newValue);
    switch (newValue) {
      case 0:
        setAccountsToShow(partners);
        break;
      case 1:
        setAccountsToShow(admins);
        break;

      default:
        setAccountsToShow(partners);
    }
  };
  React.useEffect(() => {
    switch (tabSelected) {
      case 0:
        setAccountsToShow(partners);
        break;
      case 1:
        setAccountsToShow(admins);
        break;

      default:
        setAccountsToShow(partners);
    }
  }, [, allAccounts, partners, admins]);

  React.useEffect(() => {
    setAdmins(
      allAccounts.filter(
        (account: AccountData) =>
          account.company.toLowerCase() === "data leaders"
      )
    );
    setPartners(
      allAccounts.filter(
        (account: AccountData) =>
          account.company.toLowerCase() !== "data leaders"
      )
    );
  }, [loading, tabSelected, allAccounts]);

  React.useEffect(() => {
    const fetchList = async () => {
      const data = await adminFetchListOfAccounts();
      setAllAccounts(data);
      setLoading(false);
    };
    fetchList();
  }, []);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className="all-companies">
      {loading ? (
        <LoadingAnimation message="Fetching accounts..." />
      ) : (
        <div>
          <div className="section-header">
            <h2>Accounts</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px 0",
              }}
            >
              <p>{allAccounts.length} results</p>
              <Button
                style={{
                  fontWeight: "bold",
                  color: "white",
                  backgroundColor: "#038097",
                  // padding: "0 20px",
                  // margin: "5px 0",
                }}
                size="small"
                disableElevation
                className="btn--new"
                href={ROUTES.NEW_ACCOUNT}
              >
                New account
              </Button>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Tabs
              value={tabSelected}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "hsla(189, 96%, 30%, 1)",
                },
              }}
            >
              <Tab label={`Partners (${partners.length})`} {...a11yProps(0)} />
              <Tab label={`Admins (${admins.length})`} {...a11yProps(1)} />
            </Tabs>
          </div>
          <div className="all-companies__table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email </TableCell>
                  <TableCell className="name">Company</TableCell>
                  <TableCell>Date added</TableCell>
                  <TableCell width={120}>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountsToShow.map((account) => (
                  <TableRow key={account.id}>
                    <TableCell>
                      {account.email}{" "}
                      {isNew(account.date_added) ? (
                        <Chip size="small" label="New" color="primary" />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>{account.company}</TableCell>
                    <TableCell>{getDate(account.date_added)} </TableCell>
                    <TableCell>
                      {account.company.toLowerCase() === "data leaders"
                        ? "Admin"
                        : "Partner"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}
