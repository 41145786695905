import React from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

interface ImageContainerProps {
  mdImg: string;
  id: string;
}

interface TextContainerProps {
  text: string;
  title: string;
  id: string;
  cta?: JSX.Element;
  getListed?: JSX.Element;
}

interface TextImageSectionProps {
  leftPanel: JSX.Element;
  rightPanel: JSX.Element;
  id: string;
}

export const ImageContainer: React.FC<ImageContainerProps> = ({
  mdImg,
  id,
}) => {
  // srcSet is used to serve smaller or larger imgs based on viewport size
  return (
    <div className="imgWrapper">
      <div id={id} className="imgContainer">
        <img width="100%" height="100%" src={mdImg} srcSet={`${mdImg} 3000w`} />
      </div>
    </div>
  );
};

export const TextContainer: React.FC<TextContainerProps> = ({
  getListed,
  title,
  text,
  id,
  cta,
}) => {
  return (
    <div id={id} className="textContainer">
      <div className="titleWrapper">
        {getListed ? getListed : null}
        <h1>{title}</h1>
      </div>
      {text ? (
        <div className="textWrapper">
          <p>{text}</p>
        </div>
      ) : null}
      {cta ? cta : null}
    </div>
  );
};

const TextImageSection: React.FC<TextImageSectionProps> = ({
  leftPanel,
  rightPanel,
  id,
}) => {
  let elements = [leftPanel, rightPanel];
  const { width } = useWindowDimensions();
  // This will reorder the element array.
  // Placing the the text element on top of the img element
  // Only for mobile
  if (width <= 800) {
    elements = elements.sort((elementA) => {
      if (elementA.props.id.includes("image")) {
        return 1;
      }
      return -1;
    });
  }

  return (
    <section id={id} className="textImgSection">
      {elements.map((element) => element)}
    </section>
  );
};

export default TextImageSection;
