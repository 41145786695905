import Logo from "../images/logo_dataleaders.webp";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import * as ROUTES from "../types/routes";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <img
          className="footer-logo"
          src={Logo}
          alt="Data Leaders Marketplace logo"
        />
        <div className="footer-nav">
          <a href={ROUTES.DATA_LEADERS_HOME}>Home</a>
          <a href={ROUTES.DATA_LEADERS_ABOUT}>Company</a>
          <a href={ROUTES.DATA_LEADERS_CONTACT}>Contact&nbsp;us</a>
          <a href={ROUTES.LOGIN}>Log&nbsp;in</a>
        </div>
        <div className="social-icons">
          <a href={ROUTES.LINKEDIN} target="_blank" rel="noreferrer">
            <LinkedInIcon />
          </a>
        </div>
        <small className="copyright">
          &copy; All rights reserved • Data Leaders™
        </small>
      </div>
    </footer>
  );
}
