import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import ReactGA from "react-ga";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import emailjs from "emailjs-com";
import { init } from "@emailjs/browser";
import { LoadingAnimation } from "../components/loadingAnimation";

export default function ContactUs() {
  init(process.env.REACT_APP_EMAILJS_USER_ID as string);

  ReactGA.pageview(window.location.pathname + window.location.search);
  const [contactForm, setContactForm] = React.useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    email: "",
    tel: "",
    reason: "",
    source: "",
    contactPermission: "",
    message: "",
  });
  const [success, setSuccess] = React.useState(false);
  const [, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [missingData, setMissingData] = React.useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setContactForm({
      ...contactForm,
      [name]: value,
    });
  };

  const handleReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setContactForm({ ...contactForm, reason: event.target.value as string });
  };

  const handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setContactForm({ ...contactForm, source: event.target.value as string });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (
      contactForm.firstName === "" ||
      contactForm.lastName === "" ||
      contactForm.companyName === "" ||
      contactForm.message === "" ||
      contactForm.jobTitle === "" ||
      contactForm.email === "" ||
      contactForm.tel === "" ||
      contactForm.reason == null ||
      contactForm.reason.length < 4 ||
      contactForm.source == null ||
      contactForm.source.length < 5
    ) {
      setMissingData(true);
    } else {
      setLoading(true);
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
          process.env.REACT_APP_EMAILJS_CONTACT_NOTIFICATION_TEMPLATE as string,
          contactForm,
          process.env.REACT_APP_EMAILJS_USER_ID as string
        )
        .then(() => {
          setSuccess(true);
        })
        .catch(() => {
          console.error("Failed to send email.");
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="content center contact-us">
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="middle-hero"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box px={5}>
              <h5 className="header-paragraph">Contact Data Leaders</h5>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <h1 className="hero-title">
                  Get in touch<span className="full-stop">.</span>
                </h1>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={5}></Box>
          </Grid>
        </Grid>
      </Grid>
      <div className="contact-us__form">
        <div className="contact-us__form-container">
          <Paper elevation={2}>
            <Box p={5}>
              {loading ? (
                <LoadingAnimation message="Sending message" />
              ) : success ? (
                <Alert severity="success">
                  We have received your request and will get back as quickly as
                  we can.
                </Alert>
              ) : (
                <FormControl>
                  <h2>Enquiry form</h2>
                  <p className="contact-form-header hero-body">
                    Please fill in the following form and we will get back to
                    you as quickly as we can.
                  </p>
                  <div className="fieldWrapper">
                    <TextField
                      style={{
                        width: "100%",
                      }}
                      variant="standard"
                      required
                      InputProps={{
                        style: {
                          padding: 12,
                        },
                      }}
                      type="text"
                      name="firstName"
                      className="search-field"
                      value={contactForm.firstName}
                      placeholder="First name*"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="fieldWrapper">
                    <TextField
                      style={{
                        width: "100%",
                      }}
                      variant="standard"
                      required
                      InputProps={{
                        style: {
                          padding: 12,
                        },
                      }}
                      type="text"
                      name="lastName"
                      className="search-field"
                      value={contactForm.lastName}
                      placeholder="Last name*"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      style={{
                        width: "100%",
                      }}
                      variant="standard"
                      required
                      InputProps={{
                        style: {
                          padding: 12,
                        },
                      }}
                      type="text"
                      name="jobTitle"
                      className="search-field"
                      value={contactForm.jobTitle}
                      placeholder="Job title*"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      style={{
                        width: "100%",
                      }}
                      variant="standard"
                      required
                      InputProps={{
                        style: {
                          padding: 12,
                        },
                      }}
                      type="text"
                      name="companyName"
                      className="search-field"
                      value={contactForm.companyName}
                      placeholder="Company name*"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      style={{
                        width: "100%",
                      }}
                      variant="standard"
                      required
                      InputProps={{
                        style: {
                          padding: 12,
                        },
                      }}
                      type="email"
                      name="email"
                      className="search-field"
                      value={contactForm.email}
                      placeholder="Business email*"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="fieldWrapper">
                    <TextField
                      style={{
                        width: "100%",
                      }}
                      variant="standard"
                      required
                      InputProps={{
                        style: {
                          padding: 12,
                        },
                      }}
                      type="tel"
                      name="tel"
                      className="search-field"
                      value={contactForm.tel}
                      placeholder="Business phone*"
                      onChange={handleChange}
                    />
                  </div>
                  <p>Reason for contacting us*</p>
                  <div className="selectWrapper">
                    <Select
                      className="search-field"
                      variant="standard"
                      name="reason"
                      value={contactForm.reason}
                      onChange={handleReasonChange}
                      style={{
                        width: "100%",
                        padding: "0px 12px",
                        marginBottom: 20,
                      }}
                    >
                      <MenuItem value={"General enquiry"}>
                        General enquiry
                      </MenuItem>
                      <MenuItem value={"Get listed in the Marketplace"}>
                        Get listed in the Marketplace
                      </MenuItem>
                      <MenuItem value={"Free professional advice"}>
                        Require professional advice
                      </MenuItem>
                      <MenuItem value={"Submit a missing company"}>
                        Submit a missing company
                      </MenuItem>
                      <MenuItem value={"Update Marketplace listing"}>
                        Update Marketplace listing
                      </MenuItem>
                      <MenuItem value={"Request an upgrade to a paid plan"}>
                        Request an upgrade to a paid plan
                      </MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </div>
                  <p>Where did you hear about us?*</p>
                  <div className="selectWrapper">
                    <Select
                      style={{
                        width: "100%",
                      }}
                      className="search-field"
                      variant="standard"
                      name="source"
                      value={contactForm.source}
                      onChange={handleSourceChange}
                    >
                      <MenuItem value={"Email"}>Email</MenuItem>
                      <MenuItem value={"LinkedIn"}>LinkedIn</MenuItem>
                      <MenuItem value={"Google"}>Google</MenuItem>
                      <MenuItem value={"Referral"}>Referral</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </div>
                  <div className="fieldWrapper">
                    <TextField
                      style={{
                        width: "100%",
                      }}
                      variant="standard"
                      InputProps={{
                        style: {
                          padding: 12,
                          paddingTop: 40,
                        },
                      }}
                      type="text"
                      name="message"
                      className="search-field"
                      value={contactForm.message}
                      placeholder="Your message*"
                      multiline
                      onChange={handleChange}
                    />
                  </div>
                  <br />
                  {missingData && (
                    <Alert severity="warning">
                      Please fill in all required fields.
                    </Alert>
                  )}
                  <Button
                    className="lower-case-button theme-button "
                    variant="outlined"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </FormControl>
              )}
            </Box>
          </Paper>
        </div>
      </div>
    </div>
  );
}
