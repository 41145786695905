import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Logo from "../images/logo_dataleaders.webp";
import { NavLink } from "react-router-dom";
import * as ROUTES from "../types/routes";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signOut } from "firebase/auth";
const pages = [
  { name: "Home", url: ROUTES.DATA_LEADERS_HOME },
  { name: "Resources", url: ROUTES.SEARCH_RESULTS },
  { name: "Attend", url: ROUTES.DATA_LEADERS_ATTEND },
  { name: "Company", url: ROUTES.DATA_LEADERS_ABOUT },
];

const ResponsiveAppBar = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const toggleDrawer =
    (anchor: string, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <>
      <AppBar position="relative" elevation={0} className="header">
        <div className="header__top-banner">
          <div className="header__top-banner__content">
            <Link href={ROUTES.DATA_LEADERS_HUB}>Members login</Link>

            <PersonIcon className="icon" />
          </div>
        </div>

        <Container maxWidth={false} className="max-width no-padding">
          <Toolbar disableGutters className="header__toolbar">
            <Link component="a" href={ROUTES.DATA_LEADERS_HOME}>
              <img
                src={Logo}
                className="header__logo"
                alt="Data Leaders logo"
              />
            </Link>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                size="large"
                onClick={toggleDrawer("right", true)}
                style={{ color: "hsl(225, 38%, 23%)" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "flex-start",
                paddingLeft: "20%",
                display: { xs: "none", md: "flex" },
              }}
            >
              {pages.map((page) => (
                <a
                  href={page.url}
                  className={
                    page.name === "Resources"
                      ? "header__link--active"
                      : "header__link"
                  }
                  key={page.name}
                  // style={{
                  //   color: page.name === "Resources" ? "red" : "inherit",
                  // }}
                  // onClick={handleCloseNavMenu}
                >
                  {page.name}
                </a>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
              <Button
                className="contact"
                variant="contained"
                disableElevation
                sx={{
                  textTransform: "capitalize",
                }}
                href={ROUTES.DATA_LEADERS_CONTACT}
              >
                Contact us
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        PaperProps={{
          sx: { width: "90%", paddingTop: "42px" },
        }}
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <List className="menu-wrapper">
          {pages.map((page) => {
            return (
              <>
                <ListItem button key={page.name} component="a" href={page.url}>
                  <ListItemText primary={page.name} />
                </ListItem>
                <Divider />
              </>
            );
          })}
          <ListItem button component="a" href={ROUTES.DATA_LEADERS_CONTACT}>
            <ListItemText primary="Contact us" />
          </ListItem>
          <Divider />

          <ListItem
            className="login-button"
            button
            component="a"
            href={ROUTES.DATA_LEADERS_HUB}
          >
            <ListItemText primary="Members login" />
          </ListItem>
          <Divider />
        </List>
      </Drawer>
    </>
  );
};
export default ResponsiveAppBar;
