import React from "react";
import Loader from "react-loader-spinner";

interface LoadingAnimationProps {
  message: string;
}

export function LoadingAnimation({ message }: LoadingAnimationProps) {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader type="BallTriangle" color="#5ba4b6" height={80} width={80} />
      <span style={{ padding: "40px 0" }}>{message}</span>
    </div>
  );
}
