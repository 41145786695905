import { useEffect } from "react";
import { useStockChartScriptContext } from "../contexts/stockChartContext";

declare var TradingView: any;

interface StockChartProps {
  stockSymbol: string | null;
}

const StockChart = ({ stockSymbol }: StockChartProps) => {
  const tradingViewData = useStockChartScriptContext();
  useEffect(() => {
    if (stockSymbol !== null && tradingViewData.isChartReady) {
      runScript(stockSymbol);
    }
    console.log(stockSymbol);
  }, []);

  const runScript = (stockSymbol: string) => {
    new TradingView.widget({
      width: "100%",
      height: 400,
      symbol: stockSymbol.replace(/ /g, ""), // need to remove white spaces from stock symbol
      interval: "D",
      timezone: "Etc/UTC",
      theme: "light",
      style: "3",
      locale: "en",
      toolbar_bg: "#f1f3f6",
      enable_publishing: false,
      allow_symbol_change: false,
      container_id: "tradingview_b175f",
    });
  };

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_b175f"></div>
      <div className="tradingview-widget-copyright">
        Chart by&nbsp;
        <a href="https://www.tradingview.com" rel="noreferrer" target="_blank">
          TradingView
        </a>
      </div>
    </div>
  );
};

export { StockChart };
