import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import HeroImage from "../images/cyp.png";
import MobileImage from "../images/mobile.png";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import ReactGA from "react-ga";
import { Button, Select, Paper } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import emailjs from "emailjs-com";
import { init } from "@emailjs/browser";
import { LoadingAnimation } from "../components/loadingAnimation";

export default function ClaimYourPresencePage() {
  const form = React.useRef();
  init(process.env.REACT_APP_EMAILJS_USER_ID as string);

  ReactGA.pageview(window.location.pathname + window.location.search);
  const [showContactForm, setShowContactForm] = React.useState(false);
  const [contactForm, setContactForm] = React.useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    email: "",
    tel: "",
    reason: "",
    source: "",
    contactPermission: "",
    message: "",
  });
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [missingData, setMissingData] = React.useState(false);

  React.useEffect(() => {
    let formElement = document.getElementById("contact-form");
    if (formElement !== null) {
      formElement.scrollIntoView();
    }
  }, [showContactForm]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setContactForm({
      ...contactForm,
      [name]: value,
    });
  };

  const handleReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setContactForm({ ...contactForm, reason: event.target.value as string });
  };
  const handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setContactForm({ ...contactForm, source: event.target.value as string });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (
      contactForm.firstName === "" ||
      contactForm.lastName === "" ||
      contactForm.companyName === "" ||
      contactForm.message === "" ||
      contactForm.jobTitle === "" ||
      contactForm.email === "" ||
      contactForm.tel === "" ||
      contactForm.reason == null ||
      contactForm.reason.length < 4 ||
      contactForm.source == null ||
      contactForm.source.length < 5
    ) {
      setMissingData(true);
    } else {
      setLoading(true);
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
          process.env.REACT_APP_EMAILJS_CONTACT_NOTIFICATION_TEMPLATE as string,
          contactForm,
          process.env.REACT_APP_EMAILJS_USER_ID as string
        )
        .then((resp) => {
          setSuccess(true);
        })
        .catch((error: any) => {
          console.error("Failed to send email.");
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="content center contact-us">
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="middle-hero"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <div className="ribbon">
              <CheckCircleOutlinedIcon />
              <a href="#get-listed">
                <span className="ribbon__text">Get started today</span>
              </a>
            </div>
            <h1 className="hero-title">Join the Marketplace</h1>
            <p className="hero-body">
              Connect with the most influential community of global data and
              analytics leaders
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={5}>
              <img
                className="hero-image"
                src={HeroImage}
                alt="Profiles"
                style={{ maxWidth: "100%" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="home-content-section why-choose claim-presence"
        spacing={3}
      >
        <Grid item xs={12} md={12}>
          <h2>Benefits</h2>
          <p className="centered-body">
            Accelerate success by joining our dedicated platform for data and
            analytics leaders and their teams
          </p>
        </Grid>
        <Grid item xs={12} md={6} className="benefits-column">
          <div className="highlight">
            <Grid item xs={12} md={12} className="inline-grid">
              <h3 className="highlight-teal">
                Build your brand and market awareness
              </h3>
              <p>
                Make it easy for your customers to find you by having a presence
                on the Data Leaders Marketplace.
              </p>
            </Grid>
          </div>
          <div className="highlight">
            <Grid item xs={12} md={12} className="inline-grid">
              <h3 className="highlight-teal">Demonstrate thought leadership</h3>
              <p>
                Publish your content and make it accessible to the community.
              </p>
            </Grid>
          </div>
          <div className="highlight">
            <Grid item xs={12} md={12} className="inline-grid">
              <h3 className="highlight-teal">Connect with the community</h3>
              <p>Allow your customers to discover and connect with you.</p>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="benefits-column">
          <div className="highlight">
            <Grid item xs={12} md={12} className="inline-grid">
              <h3 className="highlight-teal">Marketed to relevant needs</h3>
              <p>Showcase your expertise.</p>
            </Grid>
          </div>
          <div className="highlight">
            <Grid item xs={12} md={12} className="inline-grid">
              <h3 className="highlight-teal">Drive more qualified leads</h3>
              <p>Ensure your value is easy to identify.</p>
            </Grid>
          </div>
          <div className="highlight">
            <Grid item xs={12} md={12} className="inline-grid">
              <h3 className="highlight-teal">Designed for data teams</h3>
              <p>Increase the sphere of influence you reach.</p>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="home-content-section"
        id="get-listed"
      >
        <Grid item xs={12} md={6}>
          <img className="main-image" src={MobileImage} alt="Profiles" />
        </Grid>
        <Grid item xs={12} md={6}>
          <h1 className="ultimate-title">Marketplace</h1>
          <h2 className="orange-title center">Get listed</h2>
          <p className="hero-body">
            If your business helps data and analytics leaders deliver more value
            to their organisation, then you should be in The Data Leaders
            Marketplace.
          </p>
          <Button
            className="lower-case-button theme-button "
            variant="outlined"
            color="primary"
            // href={ROUTES.DATA_LEADERS_CONTACT}
            onClick={() => {
              setShowContactForm(true);
            }}
          >
            Get listed today
          </Button>
        </Grid>
      </Grid>

      {showContactForm && (
        <div className="contact-us__form">
          <div className="contact-us__form-container">
            <Paper elevation={2}>
              <Box p={5}>
                <div className="contact-form-container">
                  {loading ? (
                    <LoadingAnimation message="Sending message" />
                  ) : success ? (
                    <Alert severity="success">
                      We have received your request and will get back as quickly
                      as we can.
                    </Alert>
                  ) : (
                    <FormControl>
                      {" "}
                      {/* <h2 className="orange-title">Get listed</h2> */}
                      <p className="contact-form-header hero-body">
                        Thank you for your interest in joining our marketplace.
                        Please fill in the following form and we will get back
                        to you as quickly as we can.
                      </p>{" "}
                      <div className="fieldWrapper">
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          variant="standard"
                          InputProps={{
                            style: {
                              padding: 12,
                            },
                          }}
                          type="text"
                          name="firstName"
                          className="search-field"
                          value={contactForm.firstName}
                          placeholder="First name*"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          variant="standard"
                          InputProps={{
                            style: {
                              padding: 12,
                            },
                          }}
                          type="text"
                          name="lastName"
                          className="search-field"
                          value={contactForm.lastName}
                          placeholder="Last name*"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          variant="standard"
                          InputProps={{
                            style: {
                              padding: 12,
                            },
                          }}
                          type="text"
                          name="jobTitle"
                          className="search-field"
                          value={contactForm.jobTitle}
                          placeholder="Job title*"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          variant="standard"
                          InputProps={{
                            style: {
                              padding: 12,
                            },
                          }}
                          type="text"
                          name="companyName"
                          className="search-field"
                          value={contactForm.companyName}
                          placeholder="Company name*"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          variant="standard"
                          InputProps={{
                            style: {
                              padding: 12,
                            },
                          }}
                          type="email"
                          name="email"
                          className="search-field"
                          value={contactForm.email}
                          placeholder="Business email*"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          variant="standard"
                          InputProps={{
                            style: {
                              padding: 12,
                            },
                          }}
                          type="tel"
                          name="tel"
                          className="search-field"
                          value={contactForm.tel}
                          placeholder="Business phone*"
                          onChange={handleChange}
                        />
                      </div>
                      <p>Reason for contacting us*</p>
                      <div className="selectWrapper">
                        <Select
                          className="search-field"
                          variant="standard"
                          name="reason"
                          value={contactForm.reason}
                          onChange={handleReasonChange}
                          style={{
                            width: "100%",
                            padding: "0px 12px",
                            marginBottom: 20,
                          }}
                        >
                          <MenuItem value={"Get listed in the Marketplace"}>
                            Get listed in the Marketplace
                          </MenuItem>
                          <MenuItem value={"Update Marketplace listing"}>
                            Update Marketplace listing
                          </MenuItem>
                          <MenuItem value={"Request an upgrade to a paid plan"}>
                            Request an upgrade to a paid plan
                          </MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                        </Select>
                      </div>
                      <p>Where did you hear about us?*</p>
                      <div className="selectWrapper">
                        <Select
                          className="search-field"
                          variant="standard"
                          name="source"
                          value={contactForm.source}
                          onChange={handleSourceChange}
                          style={{ width: "100%" }}
                        >
                          <MenuItem value={"Email"}>Email</MenuItem>
                          <MenuItem value={"LinkedIn"}>LinkedIn</MenuItem>
                          <MenuItem value={"Google"}>Google</MenuItem>
                          <MenuItem value={"Referral"}>Referral</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                        </Select>
                      </div>
                      <div className="fieldWrapper">
                        <TextField
                          style={{
                            width: "100%",
                          }}
                          variant="standard"
                          InputProps={{
                            style: {
                              padding: 12,
                              paddingTop: 40,
                            },
                          }}
                          type="text"
                          name="message"
                          className="search-field"
                          value={contactForm.message}
                          placeholder="Your message*"
                          multiline
                          onChange={handleChange}
                        />
                      </div>
                      <br />
                      {missingData && (
                        <Alert severity="warning">
                          Please fill in all required fields.
                        </Alert>
                      )}
                      <Button
                        className="lower-case-button theme-button "
                        variant="outlined"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </FormControl>
                  )}
                </div>
              </Box>
            </Paper>
          </div>
        </div>
      )}
    </div>
  );
}
