import {
  useInstantSearch,
  useMenu,
  useNumericMenu,
} from "react-instantsearch-hooks-web";
import { Box, Tab, Tabs } from "@material-ui/core";
import { useEffect, useState } from "react";

export default function ResultsMenu(props: any) {
  const {
    selectedTab,
    setSelectedTab,
    menuOptions,
    loading,
    setLoading,
    shouldAutoSelectTab,
  } = props;
  const { uiState, setUiState, scopedResults } = useInstantSearch();

  useEffect(() => {
    if (loading) {
      if (Object.keys(uiState).length > 1) {
        const initialUiState: any = { ...uiState };

        menuOptions.forEach((option: any) => {
          const stateKey = option.label.replaceAll(" ", "-");
          const timestampNow = Math.floor(Date.now().valueOf() / 1000);

          if (option.indexName === "company_name") {
            initialUiState[stateKey] = {
              ...initialUiState[stateKey],
              menu: { company_type: option.configFilter },
            };
          }

          if (option.indexName === "event_name") {
            initialUiState[stateKey] = {
              ...initialUiState[stateKey],
              numericMenu: { timestamp: `${timestampNow}:` },
            };
          }
        });

        setUiState(initialUiState);
        setLoading(false);
      }
    }
  }, [uiState]);

  useEffect(() => {
    if (!loading && shouldAutoSelectTab) {
      const resultsCount = menuOptions.map((option: any, i: number) => {
        const resultsIndex = scopedResults.findIndex(
          (o) => o.indexId === option.label.replaceAll(" ", "-")
        );

        return resultsIndex >= 0
          ? scopedResults[resultsIndex].results.nbHits
          : 0;
      });

      const maxResults = Math.max(...resultsCount);
      const preferredTab = resultsCount.indexOf(maxResults);

      setSelectedTab(preferredTab !== -1 ? preferredTab : 0);
    }
  }, [scopedResults, loading]);

  function handleChange(e: any, newValue: number) {
    setSelectedTab(newValue);
  }

  if (loading) {
    return null;
  }

  return (
    <Box className="search-results__wrapper">
      <Tabs value={selectedTab} onChange={handleChange} variant="scrollable">
        {menuOptions.map((option: any, i: number) => {
          const resultsIndex = scopedResults.findIndex(
            (o) => o.indexId === option.label.replaceAll(" ", "-")
          );
          return (
            <Tab
              key={`${option.label}-tab`}
              label={
                <span>
                  {option.label.replaceAll("events", "peer discussions")}
                  <span className="search-results__number">
                    {resultsIndex >= 0
                      ? scopedResults[resultsIndex].results.nbHits
                      : "-"}
                  </span>
                </span>
              }
            />
          );
        })}
      </Tabs>
    </Box>
  );
}

export function VirtualMenu(props: any) {
  const { items } = useMenu(props);
  return null;
}

export function VirtualNumericMenu(props: any) {
  const { items } = useNumericMenu(props);
  return null;
}
