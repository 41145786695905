import { Box } from "@mui/material";
import AlgoliaSelectFilter from "./algolia_filter";
import ActiveRefinements from "./activeRefinements";

const prettyLabels: any = {
  specialisations_domains: "Domain",
  focus_areas: "Department",
  industry_focus: "Industry Focus",
  maturity: "Maturity",
  countries: "Location",
};

export default function Refinements({
  filters,
  indexId,
}: {
  filters: string[];
  indexId: string;
}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          my: 1,
          mx: -1,
        }}
      >
        {filters.map((filter: string) => {
          return (
            <AlgoliaSelectFilter
              key={filter}
              attribute={filter}
              limit={100}
              sortBy={["name:asc"]}
              label={prettyLabels[filter]}
            />
          );
        })}
      </Box>
      <ActiveRefinements indexId={indexId} prettyLabels={prettyLabels} />
    </>
  );
}
