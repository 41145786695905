import React from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import HeroImage from "../images/computer.png";
import SpeedIcon from "../images/speed.png";
import NetworkIcon from "../images/network.png";
import ProfileIcon from "../images/profile.png";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { Button } from "@material-ui/core";
import { generateAlgoliaSearchUrl } from "../utils/url_builder";
import ReactGA from "react-ga";
import {
  DomainList,
  DomainListDataProviders,
  DomainListServiceProviders,
  DomainListSoftwareProviders,
} from "../types/classification";
import { fetchFeaturedCompanies } from "../utils/database";
import { CompanyData } from "../types/companies";
import { capitalise } from "../utils/utils";
import * as ROUTES from "../types/routes";
import SignUpToNewsletter from "../components/sign_up_to_newsletter";

import { AlgoliaSearchField } from "../components/search_field/algolia_search";
import { HorizontalCarousel } from "../components/horizontalCarousel";
import JoinDataleadersHub from "../components/join_dataleaders_hub";

export default function Homepage() {
  const viewAllVendors = () => {
    // For the sake of time loading the search page anew
    window.location.replace(generateAlgoliaSearchUrl(""));
  };
  const [featuredCompanies, setFeaturedCompanies] = React.useState<
    CompanyData[]
  >([]);

  React.useEffect(() => {
    const getFeaturedCompanies = async () => {
      const data = await fetchFeaturedCompanies();
      if (data !== undefined) {
        setFeaturedCompanies(data);
      }
    };
    getFeaturedCompanies();
  }, []);

  const viewDomain = (category: string, companyType = "") => {
    // For the sake of time loading the search page anew
    ReactGA.event({
      category: "User Searches",
      action: "Searched a Domain and or Company Type",
      label: `category: ${category}, company type: ${companyType}`,
      nonInteraction: true,
    });

    window.location.replace(generateAlgoliaSearchUrl(category));
  };
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="hero"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box px={5}>
              <div className="ribbon">
                <CheckCircleOutlinedIcon />
                <span className="ribbon__text">
                  The Marketplace for data and analytics leaders
                </span>
              </div>
              <div className="heroTitleContainer">
                <h1 className="hero__title">
                  Solutions and intelligence for data and analytics leaders.
                </h1>
              </div>

              <div className="search">
                <AlgoliaSearchField />
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box px={5}>
              <div className="HeroImageContainer">
                <img
                  style={{ height: "100%", width: "100%" }}
                  src={HeroImage}
                  alt="Computer"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <div className="content">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="home-content-section home-content-section__benefits"
          spacing={0}
        >
          <h2>Helping Data Leaders find the providers they need.</h2>
          <p className=" home-content-section__benefits__subHeader">
            We provide a space for data leaders to understand more about the
            providers they need.
          </p>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            item
            xs={12}
          >
            <Grid item xs={12} md={4}>
              <img className="highlight-icon" src={SpeedIcon} alt="Speed" />
              <h3 className="home-content-section__benefits__title highlight-orange">
                Make change straightforward
              </h3>
              <p className="home-content-section__benefits__text">
                Connect with the people and solutions that meet your needs and
                gain insight from industry leading businesses.
              </p>
            </Grid>
            <Grid item xs={12} md={4}>
              <img className="highlight-icon" src={NetworkIcon} alt="Network" />
              <h3 className="highlight-blue">Connect with the community</h3>
              <p>
                Use the Data Leaders Marketplace to understand relevant
                technology. Quickly evaluate what organisations do and how they
                can help you.
              </p>
            </Grid>
            <Grid item xs={12} md={4}>
              <img className="highlight-icon" src={ProfileIcon} alt="Network" />

              <h3 className="highlight-teal">Unlock the potential</h3>
              <p>Find the right solutions to accelerate your performance.</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="home-content-section featured-companies"
          spacing={3}
        >
          <Grid item xs={12}>
            <div className="featured-companies__header">
              <div>
                <h2>Featured companies</h2>
                <p>
                  Connect with the people and solutions that meet your needs and
                  gain insight from industry leading businesses.
                </p>
              </div>
              <Button
                className="lower-case-button theme-button outline"
                variant="outlined"
                color="primary"
                component={Link}
                to="#"
                onClick={viewAllVendors}
              >
                View all companies
              </Button>
            </div>
          </Grid>

          {featuredCompanies && featuredCompanies.length > 0 && (
            <HorizontalCarousel items={featuredCompanies} />
          )}
          {/* <Grid item xs={12}>
            <div className="featured-companies__header">
              <div>
                <p>
                  To learn what your peers think about the providers, click{" "}
                  <a href="https://hub.dataleaders.net/users/sign_in">here</a>.
                </p>
              </div>
            </div>
          </Grid> */}
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="home-content-section"
          spacing={3}
        >
          <Grid item xs={12}>
            <div style={{ margin: "60px 0 0" }}>
              <JoinDataleadersHub />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="home-content-section featured-companies"
          spacing={3}
        >
          <Grid item xs={12}>
            <h2 className="secondary-title">Explore companies</h2>
            <h3 className="third-title">By domain</h3>
          </Grid>

          {DomainList.map((domain: string) => (
            <Grid item xs={12} md={4} key={domain}>
              <Button
                className="lower-case-button theme-button outline fullwidth-button"
                variant="outlined"
                color="primary"
                component={Link}
                to="#"
                onClick={() => viewDomain(domain.toLowerCase())}
              >
                {domain}
              </Button>
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="home-content-section"
        >
          <Grid item xs={12} md={12}>
            <h2>Find companies, services and products</h2>
            <Grid container spacing={3} direction="row" justify="center">
              <Grid item xs={12} md={4}>
                <h3 className="category-heading">Software Providers</h3>

                <ul className="subcategory-list-item">
                  {DomainListSoftwareProviders.map((domain) => (
                    <li key={domain}>
                      <Link
                        to="#"
                        onClick={() => viewDomain(domain, "Software Provider")}
                      >
                        {capitalise(domain)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="category-heading">Service Providers</h3>
                <ul className="subcategory-list-item">
                  {DomainListServiceProviders.map((domain) => (
                    <li key={domain}>
                      <Link
                        to="#"
                        onClick={() => viewDomain(domain, "Service Provider")}
                      >
                        {capitalise(domain)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="category-heading">Data Providers</h3>
                <ul className="subcategory-list-item">
                  {DomainListDataProviders.map((domain) => (
                    <li key={domain}>
                      <Link
                        to="#"
                        onClick={() => viewDomain(domain, "Data Provider")}
                      >
                        {capitalise(domain)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ margin: "60px 0" }}>
          <SignUpToNewsletter />
        </div>
      </div>
    </div>
  );
}
