import ReactGA from "react-ga";
import * as ROUTES from "../types/routes";

import TextImageSection, {
  ImageContainer,
  TextContainer,
} from "../components/about_page/TextImageSection";

import { Button } from "@material-ui/core";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";

import first_image from "../images/about_page/first_image.png";
import your_challenge from "../images/about_page/your_challenge.png";
import solutions from "../images/about_page/solutions.png";
import start_today from "../images/about_page/start_today.png";

import people from "../images/about_page/people.png";
import insight from "../images/about_page/insight.png";
import technology from "../images/about_page/technology.png";
import search from "../images/about_page/search.png";
import bar_chart from "../images/about_page/bar_chart.png";
import { useEffect } from "react";
import JoinDataleadersHub from "../components/join_dataleaders_hub";

const exploreCTA = (route: string, text: string) => (
  <Button
    className="lower-case-button theme-button "
    variant="outlined"
    color="primary"
    // target="_blank"
    href={route}
  >
    {text}
  </Button>
);

export default function About() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="aboutContainer">
      <div className="spacer--small" />
      <TextImageSection
        id="benefits"
        leftPanel={
          <TextContainer
            getListed={
              <div className="ribbonWrapper">
                <div className="ribbon">
                  <CheckCircleOutlinedIcon />
                  <span className="ribbon__text">
                    About The Data Leaders Marketplace
                  </span>
                </div>
              </div>
            }
            title="Connecting data professionals to the solutions they need"
            text=""
            id="text-connecting"
            cta={exploreCTA(ROUTES.SEARCH_RESULTS, "Explore")}
          />
        }
        rightPanel={<ImageContainer id="image-benefits" mdImg={first_image} />}
      />
      <h2 className="center">
        What are the benefits of using Data Leaders Marketplace?
      </h2>
      <br />
      <h3 className="highlight-teal center">
        Addressing the 5 main issues while searching for data solution providers
      </h3>
      <div className="benefitsContainer">
        <div className="benefitItem">
          <div className="benefitImageContainer">
            <img width="100%" height="100%" src={people} />
          </div>
          <p>Connect with the people and solutions that meet your needs.</p>
        </div>
        <div className="benefitItem">
          <div className="benefitImageContainer">
            <img width="100%" height="100%" src={insight} />
          </div>
          <p>Gain insights from industry leading businesses.</p>
        </div>
        <div className="benefitItem">
          <div className="benefitImageContainer">
            <img width="100%" height="100%" src={technology} />
          </div>
          <p>Understand relevant technology.</p>
        </div>
        <div className="benefitItem">
          <div className="benefitImageContainer">
            <img width="100%" height="100%" src={search} />
          </div>
          <p>
            Quickly evaluate what organisations do and how they can help you.
          </p>
        </div>
        <div className="benefitItem">
          <div className="benefitImageContainer">
            <img width="100%" height="100%" src={bar_chart} />
          </div>
          <p>Find the right solutions to accelerate your performance.</p>
        </div>
      </div>
      <TextImageSection
        id="benefits"
        leftPanel={
          <ImageContainer id="image-benefits" mdImg={your_challenge} />
        }
        rightPanel={
          <TextContainer
            title="Your Challenge"
            text="The data and analytics market is crowded and complex. What companies do and how they do it is difficult to unearth and understand. Data people need solutions, but don’t necessarily have the time to understand the nuances of what’s available. People often rely on hearsay and sales reps for their information, or they need to purchase expensive information licences to dig deeper."
            id="your-challenge"
          />
        }
      />
      <TextImageSection
        id="data-leaders-solution"
        rightPanel={<ImageContainer id="image-benefits" mdImg={solutions} />}
        leftPanel={
          <TextContainer
            title="Data Leaders Solution"
            text="Data Leaders offers a marketplace dedicated to data and analytics solutions and services, with trust at its core. The Marketplace allows people to easily understand an organisation’s value whilst being able to connect with users of a particular solution. Its aim is to reduce market complexity and make it easy for people to connect to real users who can offer benchmarking information."
            id="text-data-leaders-solution"
          />
        }
      />
      <TextImageSection
        id="start-searching"
        rightPanel={
          <TextContainer
            title="Start searching!"
            text="Connect with the people and solutions that meet your needs and gain insight from industry-leading businesses. A marketplace of solutions and intelligence for data and analytics leaders."
            id="text-start-searching"
            cta={exploreCTA(ROUTES.SEARCH_RESULTS, "Explore")}
          />
        }
        leftPanel={<ImageContainer id="text-benefits" mdImg={start_today} />}
      />
      <div style={{ padding: "80px 0 ", borderTop: "1px solid #eaeaea" }}>
        <JoinDataleadersHub />
      </div>
    </div>
  );
}
