import React from "react";
import { userRoleType } from "../../types/users";
import { NavLink, Link } from "react-router-dom";
import * as ROUTES from "../../types/routes";
import { getAuth, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import VerifiedUserOutlined from "@material-ui/icons/AccountCircleOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import dataLeadersLogo from "../../images/dataleaders.png";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

type Props = {
  userCompany: string | undefined;
};

export const PartnerSidebar: React.FC<Props> = ({
  userCompany = undefined,
}) => {
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);
  const [pathName, setPathName] = React.useState("");
  const [page, setPage] = React.useState("accounts");

  const signOutUser = async () => {
    await signOut(auth);
    localStorage.removeItem("uid");
  };

  React.useEffect(() => {
    setPathName(window.location.pathname);
  }, [page]);

  return (
    <div className="sidebar">
      <div className="sidebar__padding">
        <div className="sidebar__content">
          <div>
            <div style={{ padding: "0 16px" }}>
              <AccountCircleOutlinedIcon />

              <span className="username">
                {user && user.email ? user.email : "user name"}
              </span>
              <span className="role">{userCompany}</span>
            </div>
            <div className="separator" />
            <ul>
              {/* <li>
            <Link to={ROUTES.LANDING}>Dashboard</Link>
          </li> */}
              <li>
                <NavLink className="nav-link" to={ROUTES.ACCOUNT_HOME}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <HomeOutlinedIcon />
                    <span style={{ paddingLeft: "12px" }}>Home</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  to={`${ROUTES.PARTNER_MANAGE_PROFILE}/${userCompany}`}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <ManageAccountsOutlinedIcon />
                    <span style={{ paddingLeft: "12px" }}>Manage profile</span>
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav-link"
                  to={`${ROUTES.PARTNER_PREVIEW_PROFILE}/${userCompany}`}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <ContactPageOutlinedIcon />
                    <span style={{ paddingLeft: "12px" }}>
                      Preview profile
                    </span>{" "}
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
          <div>
            <div className="separator" />

            <ul>
              <li>
                <a className="nav-link" onClick={() => signOutUser()}>
                  Sign out
                </a>
              </li>
              <li>
                <Link to={ROUTES.HOME}>Back to Marketplace</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
