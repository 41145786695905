import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function Login() {
  const auth = getAuth();
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const handleLogIn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email === null || !validateEmail(email)) {
      setInvalidEmail(true);
      return;
    }
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccess(true);
      })
      .catch((e) => {
        setError(true);
      });
  };
  function validateEmail(email: string) {
    const cleanEmail = email.trim();
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(cleanEmail).toLowerCase());
  }

  return (
    <form className="login__form" onSubmit={handleLogIn}>
      {success ? (
        <div className="success">
          <Alert severity="success">
            {" "}
            We have sent you a link to reset your password.
          </Alert>
        </div>
      ) : (
        <>
          <input
            className="emailField"
            type="text"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          {invalidEmail && (
            <Alert severity="error">Please enter a valid email</Alert>
          )}
          {error && (
            <Alert severity="error">
              Sorry there was an error sending the password reset link.
            </Alert>
          )}
          <input
            className="btn btn--login"
            type="submit"
            value="RESET PASSWORD"
          />
        </>
      )}
    </form>
  );
}
