import React from "react";
import Login from "../components/login";
import { Link } from "react-router-dom";
import * as ROUTES from "../types/routes";
import dataLeadersLogo from "../images/dataleaders.png";
import Alert from "@material-ui/lab/Alert";

export default function LoginPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="login">
        <img src={dataLeadersLogo} alt="Data Leaders logo" />
        <h2>Sign in to Data Leaders</h2>
        <Login />
        <Alert severity="info">
          Want to become a Data Leaders partner?{" "}
          <Link to={ROUTES.CLAIM_PRESENCE}>Get in touch</Link>.
        </Alert>
      </div>
    </>
  );
}
