// To upload data
//  Add `write` for "companies" in firestore rules
//  Place the JSON file next to this file
//  Import somewhere and run (in Chrome as the website)
//  REMOVE write from firestore rules!!!
import { db, timeStamp, collection, getDocs, query, doc } from "../firebase";
import { getDoc, setDoc } from "firebase/firestore";
import { readFileSync } from "fs";
import admin from "firebase-admin";
import { Bucket } from "@google-cloud/storage";
import { firebaseApp } from "../firebase/index";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { CompanyData } from "../../types/companies";
// import mime from "mime-types";

import rawCompanyDataFromScrape from "./companies/scraped_data.json";

async function uploadImageAndGetReference(
  filePath: string | undefined,
  type: string
): Promise<string | undefined> {
  const storage = getStorage();
  if (!filePath || filePath == undefined) {
    console.error("No filePath");
    return "undefined";
  }
  const imageName = filePath.split("/").slice(-1)[0];
  // const contentType = mime.contentType(filePath.split("/").slice(-1)[0]);
  // if (!contentType) {
  //   console.error(`Error uploading image: ${filePath}`);
  //   return "undefined"; // ... why does that function return false, anyway, if falsy don't...
  // }
  const imagePathRef = ref(storage, `images/${type}/${imageName}`);
  try {
    const response = await fetch(
      `http://localhost:3000/new-companies-images/${type}/${imageName}`
    );
    // const metadata = {
    //   contentType: contentType,
    // };
    // const blob: Blob = await response.blob();
    // const snapshot = await uploadBytes(imagePathRef, blob, metadata);
    // console.log(`Uploaded ${imageName} to ${snapshot.ref} as ${contentType}`);
    // return snapshot.ref.toString();
  } catch (e) {
    console.error("Error");
    console.error(
      "Had to remove mime-types package as it was causing build errors. Please resinstall mime-types for uploadImageAndGetReference to work."
    );
    return "undefined";
  }
}

async function processCompanyDataMapUrlsBack(companyDatum: any): Promise<any> {
  let mappedExecutiveDatum = companyDatum.executive_team?.map((x: any) => {
    return { ...x };
  });

  if (companyDatum.executive_team) {
    const mappedExecutiveTeamUrl = await Promise.all(
      companyDatum.executive_team.map((executiveTeamDatum: any) => {
        if (!executiveTeamDatum.image_url) {
          return Promise.resolve(executiveTeamDatum.image_url);
        }
        return uploadImageAndGetReference(
          executiveTeamDatum.image_url,
          "executive_team"
        );
      })
    );

    mappedExecutiveDatum = companyDatum.executive_team.map(
      (executiveTeamDatum: any, i: number) => {
        return { ...executiveTeamDatum, image_url: mappedExecutiveTeamUrl[i] };
      }
    );
  }
  const imageRef = await uploadImageAndGetReference(
    companyDatum.company_logo_file_location,
    "logos"
  );
  return {
    ...companyDatum,
    company_logo_file_location: imageRef,
    executive_team: mappedExecutiveDatum,
  };
}

export const readCompaniesAndUploadImages = async () => {
  let reprocessedAllCompanies = [] as CompanyData[];
  let companyDatum: {};

  for (companyDatum of rawCompanyDataFromScrape) {
    const mappedCompany = await processCompanyDataMapUrlsBack(companyDatum);
    reprocessedAllCompanies.push(mappedCompany);
  }
  return reprocessedAllCompanies;
};

export const updateDatabaseWithReprocessedAllCompanies = async () => {
  await Promise.all(
    rawCompanyDataFromScrape.map(async (data: any) => {
      const docRef = doc(db, "companies", data["company_name"]);
      let docSnap: any;
      try {
        docSnap = await getDoc(docRef);
      } catch (e: any) {
        console.error(e);
      }

      if (!docSnap.exists()) {
        setDoc(doc(db, "companies", data["company_name"]), {
          ...data,
          id: data["company_name"],
          industry_focus: [""],
          focus_areas: [""],
          specialisations_domains: [""],
          approved: false,
          date_added: timeStamp.now(),
          date_last_edited: timeStamp.now(),
          videos: [],
        }).catch((e) => {
          console.error("Failed to write " + data["company_name"]);
        });
        console.log(data["company_name"] + " added");
      } else {
        console.error(data["company_name"] + " is already in the database!");
      }
    })
  );
  console.log(`Uploaded ${rawCompanyDataFromScrape.length} companies`);
  // return scrapedData;
};

// Handle general_contact_email vs email_provided_by_the_vendor_for_a_form
// REMOVE "(" from image urls!
// Add company_type
// remove white_paper_title
// email_provided_by_the_vendor_for_a_form

// Function used once to rename the companies collection from "companies3" to "companies"
// export const updateCollectionName = async () => {
//   const oldCollectionName = "companies3";
//   const newCollectionName = "companies";

//   let querySnapshot = null;
//   const q = query(collection(db, oldCollectionName));
//   querySnapshot = await getDocs(q);
//   const rawCompanyData = querySnapshot.docs.map((doc: any) => {
//     // console.log(JSON.stringify(doc));
//     return doc.data();
//   });
//   await Promise.all(
//     rawCompanyData.map(async (data: any) => {
//       const docRef = doc(db, newCollectionName, data["company_name"]);
//       setDoc(doc(db, newCollectionName, data["company_name"]), data)
//         .then(() => {
//           console.log("Company added");
//         })
//         .catch((e) => {
//           console.error(
//             "Failed to write " + data["company_name"] + JSON.stringify(e)
//           );
//         });
//     })
//   );
// };
