// Firebase setup and services
// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
// import "firebase/compat/auth";
// import { getAuth } from "firebase/compat/auth";

// Firebase 9:
import { initializeApp } from "firebase/app";
// import {
//   getFirestore,
//   collection,
//   getDocs,
//   Timestamp,
// } from "firebase/firestore/lite";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  Timestamp,
  doc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, getDownloadURL, ref } from "firebase/storage";

// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const timeStamp = Timestamp;
const firebaseAuth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

export {
  firebaseApp,
  db,
  timeStamp,
  firebaseAuth,
  onAuthStateChanged,
  collection,
  query,
  where,
  getDocs,
  doc,
  storage,
  getDownloadURL,
  ref,
};
