import * as React from "react";
import { Button } from "@material-ui/core";
import * as ROUTES from "../types/routes";
import ReactGA from "react-ga";

export default function JoinDataleadersHub() {
  const exploreCTA = (route: string, text: string) => {
    return (
      <Button
        className="lower-case-button theme-button "
        variant="outlined"
        target="_blank"
        color="primary"
        href={route}
        onClick={() => {
          console.log("Widget clicked");

          ReactGA.event({
            category: "CTA: Join DataLeaders Hub",
            action: "User click",
            label: "",
            nonInteraction: true,
          });
        }}
      >
        {text}
      </Button>
    );
  };
  React.useEffect(() => {
    console.log("Widget loaded");
    ReactGA.event({
      category: "CTA: Join DataLeaders Hub",
      action: "CTA displayed on page",
      label: "",
      nonInteraction: true,
    });
  }, []);

  return (
    <div>
      <h2 className="center">Data Leaders Members Get More</h2>
      <br />
      <div className="boxContainer">
        <div className="box">
          <div className="boxTitleWrapper">
            <h4 className="highlight-teal center">
              Direct access to research & insights
            </h4>
          </div>

          <p>
            Top data executives face a constant challenge in keeping up with new
            technology and determining how they might benefit from them.
          </p>
        </div>
        <div className="box">
          <div className="boxTitleWrapper">
            <h4 className="highlight-teal center">Discuss with your peers</h4>
          </div>

          <p>
            A dedicated platform for data professionals. A place where you can
            only find data solutions. There will be no more perplexing lists of
            providers that cater to everyone. This one is for all data
            specialists.
          </p>
        </div>
      </div>
      <br />
      <div className="center">
        {exploreCTA(ROUTES.DATA_LEADERS_HUB, "Join the community")}
      </div>
      <br />
      <h6
        className="center"
        style={{ fontWeight: "normal", maxWidth: "70ch", marginTop: "2rem" }}
      >
        *The Hub is a vendor-free space for data and analytics leaders to share
        their questions, ideas and suggestions in confidence. No data will be
        shared with any solution provider.
      </h6>
      <br />
    </div>
  );
}
