import React from "react";
import ResetPassword from "../components/resetPassword";
import { Link } from "react-router-dom";
import * as ROUTES from "../types/routes";

export default function ResetPasswordPage() {
  return (
    <div className="login">
      <h2>Forgotten your password?</h2>
      <p>
        Please enter your email address and we will send you a link to renew
        your password.
      </p>
      <ResetPassword />
      <div className="back-btn">
        <Link to={ROUTES.LOGIN}>&lt; back to login</Link>
      </div>
    </div>
  );
}
