import React from "react";
import {
  readCompaniesAndUploadImages,
  updateDatabaseWithReprocessedAllCompanies,
} from "../../utils/local_upload/local_upload_data";
import { LoadingAnimation } from "../../components/loadingAnimation";

export default function UploadScrapedCompanies() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [uploadSuccessful, setUploadSuccessful] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [confirmationMessage, setConfirmationMessage] = React.useState<
    String | undefined
  >(undefined);

  // setLoading(true);
  const uploadImagesToStorageAndReprocessLinks = async () => {
    try {
      const reprocessedData = await readCompaniesAndUploadImages();
      setConfirmationMessage(JSON.stringify(reprocessedData));
      setUploadSuccessful(true);
    } catch (e: any) {
      console.log(e);
      setError(true);
      setErrorMessage(JSON.stringify(e));
    } finally {
      setLoading(false);
    }
  };

  const addNewCompaniesToDatabase = () => {
    var confirmation = window.confirm("Run the script to add companies?");
    if (confirmation) {
      try {
        updateDatabaseWithReprocessedAllCompanies();
      } catch (e: any) {
        console.log(e);
        setError(true);
        setErrorMessage(JSON.stringify(e));
      }
    }
  };

  return (
    <>
      <div className="section-header">
        <h2>Add scraped companies</h2>
      </div>
      {loading && <LoadingAnimation message="Fetching companies..." />}
      <div className="instructions">
        <h4>Setup</h4>

        <ul>
          <li>
            Make db backup (see:{" "}
            <a href="https://firebase.google.com/docs/firestore/manage-data/export-import">
              https://firebase.google.com/docs/firestore/manage-data/export-import
            </a>
            )
          </li>
          <li>
            Remove all broken images (html files or with <code>.false</code>)
            <br />
            run: <code>del *.false</code>
          </li>
          {/* <li>
              Check image names and remove unsupported characters (eg. %C3%A9)
              via batch command.
              <code>
                <pre>
                  dir | Rename-Item -newname &#x2774; $_.Name -replace
                  "%C3%A9","e" &#x2775;
                </pre>
              </code>
            </li>
            <li>Update image names in the json file</li> */}
        </ul>
        <h4>Upload images to Google Storage</h4>
        <ul>
          <li>
            Upload all the images to Google Storage via the web interface.
          </li>
          {/* <li>
            Add the images to the <em>public/new-companies-images</em> folder
            (keep both folders: logos and executive_team)
          </li>
          <li>Click the button to upload the images:</li> */}
        </ul>
        {/* <button onClick={uploadImagesToStorageAndReprocessLinks}>
          Upload images and generate script
        </button> */}

        {uploadSuccessful && <code>{confirmationMessage}</code>}

        <h4>Upload the new companies to Firestore</h4>
        <ul>
          <li>
            Paste the scraped data (json) into the{" "}
            <code>scraped-data.json</code> file.
          </li>
          <li>
            Add the company type
            <br />
            eg: <code>"company_type": "services provider"</code>
          </li>
          <li>
            Update image urls
            <br /> replace <code>/images</code> with{" "}
            <code>gs://dataleaders-15a29.appspot.com/images</code>
          </li>
          <li>
            Remove unecessary fields (eg: deployment_and_support_blue_tags,
            deployment_and_support_green_tags, white_paper_title,
            white_paper_date, email_provided_by_the_vendor_for_a_form)
          </li>
          {/* <li>
            Copy the script generated above (with new Cloud Storage path for
            images) and paste it into the <code>reprocessed_data.json</code>{" "}
            file
          </li> */}

          <li>
            Click the button below to add companies to the database:
            <br />
          </li>
          <em>
            Please run the solution locally to execute this task (remove diabled
            from the buton).
          </em>
          <br />
          <button disabled onClick={addNewCompaniesToDatabase}>
            Add to database
          </button>
        </ul>
        <h4>Archive</h4>
        <li>
          Add the json file to a new folder with the date of the update in the
          archive folder, for a history of updates.
        </li>
        <div>
          {error && (
            <div style={{ color: "red" }}>
              Error: <pre>{errorMessage}</pre>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
