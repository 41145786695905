import React from "react";
import "instantsearch.css/themes/satellite.css";
import "@algolia/autocomplete-theme-classic/dist/theme.css";
import "./styles/styles.scss";
import { CompanyDetails } from "./pages/company_details";
import Homepage from "./pages/homepage";
import ClaimYourPresencePage from "./pages/claim_your_presence";
import Header from "./components/responsiveAppBar";
import Footer from "./components/footer";
import ReactGA from "react-ga";
import { CompanyForm } from "./pages/admin/company_form";
import AllCompanies from "./pages/admin/companies";
import AllAccounts from "./pages/admin/accounts";
import LoginPage from "./pages/login";
import ContactUs from "./pages/contact_us";
import NewAccount from "./pages/admin/new_account";
import ResetPasswordPage from "./pages/resetPassword";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { DebugContext } from "./contexts/debugContext";
import { LoadingAnimation } from "./components/loadingAnimation";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import * as ROUTES from "./types/routes";
import UploadCompanies from "./pages/admin/upload_scraped_companies";
import { userRoleType } from "./types/users";
import { AdminSidebar } from "./components/admin/adminSidebar";
import { PartnerSidebar } from "./components/partner/partnerSidebar";
import PartnerHome from "./pages/partner/partner_home";
import AdminHome from "./pages/admin/admin_home";
import AlgoliaResults from "./pages/algolia_results";
import About from "./pages/about";
import SubscriptionConfirmation from "./pages/subscription_confirmation";

function AdminScaffolding({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className="admin-app">
      {/* {isDevEnv && <DevConsole />} */}
      <AdminSidebar userRole={userRoleType.ADMIN} />
      <div className="admin-content">{children}</div>
    </div>
  );
}

function PartnerScaffolding({ children }: React.PropsWithChildren<{}>) {
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);
  const [userCompany, setUserCompany] = React.useState<string | undefined>(
    undefined
  );
  React.useEffect(() => {
    const getUserCompany = async () => {
      auth.currentUser
        ?.getIdTokenResult()
        .then((idTokenResult) => {
          setUserCompany(idTokenResult.claims.company?.toString());
        })
        .catch((e) => {
          console.error(
            "There was an error fetching the user's company: ",
            error
          );
        });
    };
    if (userCompany === undefined) {
      getUserCompany();
    }
  }, []);

  return (
    <div className="admin-app">
      {/* {isDevEnv && <DevConsole />} */}
      <PartnerSidebar userCompany={userCompany} />
      <div className="admin-content">{children}</div>
    </div>
  );
}
function MainScaffolding({ children }: React.PropsWithChildren<{}>) {
  return (
    <>
      <div className="App">
        {/* {isDevEnv && <DevConsole />} */}
        <Header />

        {children}
      </div>
      <Footer />
    </>
  );
}

function AdminApp() {
  const { isDevEnv } = React.useContext(DebugContext);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id/overview`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.ADMIN} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id/people-and-culture`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.ADMIN} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id/data-capability`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.ADMIN} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id/content`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.ADMIN} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id/live-users`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.ADMIN} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.ADMIN} />
            </MainScaffolding>
          )}
        />

        <Route
          exact
          path={`${ROUTES.PREVIEW_COMPANY}/:id`}
          render={(props: any) => (
            <AdminScaffolding>
              <CompanyDetails
                {...props.match}
                userRole={userRoleType.ADMIN}
                previewOnly={true}
              />
            </AdminScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.NEW_COMPANY}
          render={(props: any) => (
            <AdminScaffolding>
              <CompanyForm {...props.match} userRole={userRoleType.ADMIN} />{" "}
            </AdminScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.NEW_ACCOUNT}
          render={(props: any) => (
            <AdminScaffolding>
              <NewAccount {...props.match} userRole={userRoleType.ADMIN} />{" "}
            </AdminScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.ALL_ACCOUNTS}
          render={(props: any) => (
            <AdminScaffolding>
              <AllAccounts {...props.match} userRole={userRoleType.ADMIN} />{" "}
            </AdminScaffolding>
          )}
        />
        <Route
          path={`${ROUTES.EDIT_COMPANY}/:id`}
          render={(props: any) => (
            <AdminScaffolding>
              <CompanyForm {...props.match} userRole={userRoleType.ADMIN} />{" "}
            </AdminScaffolding>
          )}
        />
        <Route
          path={ROUTES.ALL_COMPANIES}
          render={(props: any) => (
            <AdminScaffolding>
              <AllCompanies {...props.match} userRole={userRoleType.ADMIN} />{" "}
            </AdminScaffolding>
          )}
        />
        <Route
          path={ROUTES.UPLOAD_COMPANIES}
          render={(props: any) => (
            <AdminScaffolding>
              <UploadCompanies {...props.match} userRole={userRoleType.ADMIN} />{" "}
            </AdminScaffolding>
          )}
        />

        <Route
          exact
          path={ROUTES.SEARCH_RESULTS}
          render={(props: any) => (
            <MainScaffolding>
              <AlgoliaResults {...props.match} userRole={userRoleType.ADMIN} />
            </MainScaffolding>
          )}
        />
        <Route
          path={`${ROUTES.REGISTRATION}`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyForm {...props.match} userRole={userRoleType.ADMIN} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.CLAIM_PRESENCE}
          render={(props: any) => (
            <MainScaffolding>
              <ClaimYourPresencePage
                {...props.match}
                userRole={userRoleType.ADMIN}
              />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.LOGIN}
          render={(props: any) => (
            <MainScaffolding>
              <LoginPage {...props.match} userRole={userRoleType.ADMIN} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.CONTACT_US}
          render={(props: any) => (
            <MainScaffolding>
              <ContactUs {...props.match} userRole={userRoleType.ADMIN} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.SUBSCSCRIPTION_CONFIRMATION}
          render={(props: any) => (
            <MainScaffolding>
              <SubscriptionConfirmation />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.ABOUT_MARKETPLACE}
          render={(props: any) => (
            <MainScaffolding>
              <About />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={`${ROUTES.ACCOUNT_HOME}`}
          render={(props: any) => (
            <AdminScaffolding {...props}>
              <AdminHome />
            </AdminScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.RESET_PASSWORD}
          render={(props: any) => (
            <MainScaffolding>
              <ResetPasswordPage
                {...props.match}
                userRole={userRoleType.ADMIN}
              />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.ACCOUNT_HOME}
          render={(props: any) => (
            <MainScaffolding>
              <Homepage {...props.match} userRole={userRoleType.ADMIN} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.HOME}
          render={(props: any) => (
            <MainScaffolding>
              <Homepage {...props.match} />
            </MainScaffolding>
          )}
        />
        <Redirect to={ROUTES.ACCOUNT_HOME} />
      </Switch>
    </Router>
  );
}
const PartnerApp: React.FC<{ userCompany: string | undefined }> = ({
  userCompany,
}) => {
  const { isDevEnv } = React.useContext(DebugContext);

  return (
    <Router>
      <div className="App">
        {/* {isDevEnv && <DevConsole />} */}
        <Switch>
          <Route
            exact
            path={`${ROUTES.COMPANY_DETAILS}/:id/overview`}
            render={(props: any) => (
              <MainScaffolding>
                <CompanyDetails
                  {...props.match}
                  userRole={userRoleType.PARTNER}
                />
              </MainScaffolding>
            )}
          />
          <Route
            exact
            path={`${ROUTES.COMPANY_DETAILS}/:id/people-and-culture`}
            render={(props: any) => (
              <MainScaffolding>
                <CompanyDetails
                  {...props.match}
                  userRole={userRoleType.PARTNER}
                />
              </MainScaffolding>
            )}
          />
          <Route
            exact
            path={`${ROUTES.COMPANY_DETAILS}/:id/data-capability`}
            render={(props: any) => (
              <MainScaffolding>
                <CompanyDetails
                  {...props.match}
                  userRole={userRoleType.PARTNER}
                />
              </MainScaffolding>
            )}
          />
          <Route
            exact
            path={`${ROUTES.COMPANY_DETAILS}/:id/content`}
            render={(props: any) => (
              <MainScaffolding>
                <CompanyDetails
                  {...props.match}
                  userRole={userRoleType.PARTNER}
                />
              </MainScaffolding>
            )}
          />
          <Route
            exact
            path={`${ROUTES.COMPANY_DETAILS}/:id/live-users`}
            render={(props: any) => (
              <MainScaffolding>
                <CompanyDetails
                  {...props.match}
                  userRole={userRoleType.PARTNER}
                />
              </MainScaffolding>
            )}
          />
          <Route
            exact
            path={`${ROUTES.COMPANY_DETAILS}/:id`}
            render={(props: any) => (
              <MainScaffolding>
                <CompanyDetails
                  {...props.match}
                  userRole={userRoleType.PARTNER}
                />
              </MainScaffolding>
            )}
          />

          <Route
            exact
            path={ROUTES.SEARCH_RESULTS}
            render={(props: any) => (
              <MainScaffolding>
                <AlgoliaResults
                  {...props.match}
                  userRole={userRoleType.PARTNER}
                />
              </MainScaffolding>
            )}
          />

          <Route
            exact
            path={`${ROUTES.PARTNER_PREVIEW_PROFILE}/:id`}
            render={(props: any) => (
              <PartnerScaffolding>
                <CompanyDetails
                  {...props.match}
                  userRole={userRoleType.PARTNER}
                  userCompany={userCompany}
                  previewOnly={true}
                />
              </PartnerScaffolding>
            )}
          />
          <Route
            exact
            path={`${ROUTES.ACCOUNT_HOME}`}
            render={(props: any) => (
              <PartnerScaffolding {...props}>
                <PartnerHome />
              </PartnerScaffolding>
            )}
          />
          <Route
            path={`${ROUTES.REGISTRATION}`}
            render={(props: any) => (
              <MainScaffolding>
                <CompanyForm {...props.match} />
              </MainScaffolding>
            )}
          />
          <Route
            path={`${ROUTES.PARTNER_MANAGE_PROFILE}/:id`}
            render={(props: any) => (
              <PartnerScaffolding>
                <CompanyForm {...props.match} userRole={userRoleType.PARTNER} />
              </PartnerScaffolding>
            )}
          />
          <Route
            exact
            path={ROUTES.CLAIM_PRESENCE}
            render={(props: any) => (
              <MainScaffolding>
                <ClaimYourPresencePage {...props.match} />
              </MainScaffolding>
            )}
          />
          <Route
            exact
            path={ROUTES.ABOUT_MARKETPLACE}
            render={(props: any) => (
              <MainScaffolding>
                <About />
              </MainScaffolding>
            )}
          />
          <Route
            exact
            path={ROUTES.HOME}
            render={(props: any) => (
              <MainScaffolding>
                <Homepage {...props.match} />
              </MainScaffolding>
            )}
          />
          <Route
            exact
            path={ROUTES.CONTACT_US}
            render={(props: any) => (
              <MainScaffolding>
                <ContactUs {...props.match} />
              </MainScaffolding>
            )}
          />
          <Route
            exact
            path={ROUTES.SUBSCSCRIPTION_CONFIRMATION}
            render={(props: any) => (
              <MainScaffolding>
                <SubscriptionConfirmation />
              </MainScaffolding>
            )}
          />
          <Redirect to={ROUTES.ACCOUNT_HOME} />
        </Switch>
      </div>
    </Router>
  );
};

function UnauthenticatedApp() {
  const { isDevEnv } = React.useContext(DebugContext);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id/overview`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.USER} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id/people-and-culture`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.USER} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id/data-capability`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.USER} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id/content`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.USER} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id/live-users`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.USER} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={`${ROUTES.COMPANY_DETAILS}/:id`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyDetails {...props.match} userRole={userRoleType.USER} />
            </MainScaffolding>
          )}
        />

        <Route
          exact
          path={ROUTES.SEARCH_RESULTS}
          render={(props: any) => (
            <MainScaffolding>
              <AlgoliaResults {...props.match} userRole={userRoleType.USER} />
            </MainScaffolding>
          )}
        />
        <Route
          path={`${ROUTES.REGISTRATION}`}
          render={(props: any) => (
            <MainScaffolding>
              <CompanyForm {...props.match} userRole={userRoleType.USER} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.CLAIM_PRESENCE}
          render={(props: any) => (
            <MainScaffolding>
              <ClaimYourPresencePage
                {...props.match}
                userRole={userRoleType.USER}
              />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.ABOUT_MARKETPLACE}
          render={(props: any) => (
            <MainScaffolding>
              <About />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.LOGIN}
          render={(props: any) => (
            <MainScaffolding>
              <LoginPage {...props.match} userRole={userRoleType.USER} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.CONTACT_US}
          render={(props: any) => (
            <MainScaffolding>
              <ContactUs {...props.match} userRole={userRoleType.USER} />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.SUBSCSCRIPTION_CONFIRMATION}
          render={(props: any) => (
            <MainScaffolding>
              <SubscriptionConfirmation />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.RESET_PASSWORD}
          render={(props: any) => (
            <MainScaffolding>
              <ResetPasswordPage
                {...props.match}
                userRole={userRoleType.USER}
              />
            </MainScaffolding>
          )}
        />
        <Route
          exact
          path={ROUTES.HOME}
          render={(props: any) => (
            <MainScaffolding>
              <Homepage {...props.match} userRole={userRoleType.USER} />
            </MainScaffolding>
          )}
        />
        <Redirect to={ROUTES.HOME} />
      </Switch>
    </Router>
  );
}

function App() {
  const auth = getAuth();
  const [user, loading, error] = useAuthState(auth);
  const [admin, setAdmin] = React.useState(true);
  const [userCompany, setUserCompany] = React.useState<string | undefined>(
    undefined
  );
  const [fetchingStatus, setFetchingStatus] = React.useState(false);
  const { updating, isDevEnv } = React.useContext(DebugContext);

  React.useEffect(() => {
    setFetchingStatus(true);
    const isAdminUser = async () => {
      auth.currentUser
        ?.getIdTokenResult()
        .then((idTokenResult) => {
          if (!idTokenResult.claims.admin) {
            setAdmin(false);
            setUserCompany(idTokenResult.claims.company?.toString());
          }
          setFetchingStatus(false);
        })
        .catch((e) => {
          console.error(
            "Oops, there was an error fetching the user status: ",
            error
          );
        });
    };

    isAdminUser();
  }, [user, loading]);

  if (!isDevEnv) {
    console.log(
      `Running Google Analytics with ID ${process.env.REACT_APP_GOOGLE_ANALYTICS}`
    );
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS as string);
  } else {
    console.log(`Google Analytics disabled`);
  }

  if (loading || updating) {
    return <LoadingAnimation message="" />;
  }

  if (!user) {
    return <UnauthenticatedApp />;
  }

  if (fetchingStatus) {
    return <LoadingAnimation message="" />;
  }

  if (admin) {
    return <AdminApp />;
  }

  return <PartnerApp userCompany={userCompany} />;
}

export default App;
