import React from "react";
import { CompanyData } from "../types/companies";
import { Link } from "react-router-dom";
import * as ROUTES from "../types/routes";
import { storage, ref, getDownloadURL } from "../utils/firebase";

// TODO: Type (TypeScript) things properly
interface CompanyItemProps {
  companyDatum: CompanyData;
}

export function FeaturedCompany({ companyDatum }: CompanyItemProps) {
  const [imageUrl, setImageUrl] = React.useState("");

  React.useEffect(() => {
    if (
      companyDatum["company_logo_file_location"] !== undefined &&
      companyDatum["company_logo_file_location"].includes("gs://")
    ) {
      getDownloadURL(ref(storage, companyDatum["company_logo_file_location"]))
        .then((url: string) => {
          setImageUrl(url);
        })
        .catch((error: any) => {
          console.error("Error retrieving logo from Google Storage: " + error);
        });
    } else {
      setImageUrl(
        process.env.PUBLIC_URL + companyDatum["company_logo_file_location"]
      );
    }
  }, [, companyDatum]);

  return (
    <Link
      className="caruselImageWrapper"
      to={{
        pathname: `${ROUTES.COMPANY_DETAILS}/${companyDatum["company_name"]}`,
      }}
    >
      {imageUrl !== "" && (
        <img
          src={`${imageUrl}`}
          alt={`${companyDatum["company_name"]} logo`}
          className="caruselImage"
        ></img>
      )}

      <h5
        style={{
          textAlign: "center",
          marginTop: "10px 0 0 0",
          fontWeight: "normal",
        }}
        className="name"
      >
        {companyDatum["company_name"]}
      </h5>
    </Link>
  );
}
