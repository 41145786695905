import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  useHistory,
  Link,
  useParams,
} from "react-router-dom";
import {
  updateCompany,
  createNewCompany,
  deleteCompany,
} from "../../utils/database";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Delete from "@material-ui/icons/DeleteOutlineOutlined";
import {
  DataSourceTypeList,
  DomainList,
  FunctionFocusList,
  IndustryFocusList,
} from "../../types/classification";
import {
  LocationData,
  CompanyTypeList,
  AccountType,
  CompanyData,
  TagData,
} from "../../types/companies";
import { fetchCompany } from "../../utils/database";
import clsx from "clsx";
import { stat } from "fs";
import { constants } from "os";
import Loader from "react-loader-spinner";
import { capitalise, indexOfCaseInsensitive } from "../../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";
import { LoadingAnimation } from "../../components/loadingAnimation";
import * as ROUTES from "../../types/routes";
import Alert from "@material-ui/lab/Alert";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { userRoleType } from "../../types/users";
import emailjs from "emailjs-com";
import { getTags } from "../../utils/database";
import { init } from "@emailjs/browser";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#f3f3f3",
    margin: "30px 0 0 0",
  },
  textInput: {
    background: "white",
  },
  formControl: {
    width: "100%",
    // margin: theme.spacing(3),
  },
  icon: {
    borderRadius: 0,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#365a63",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#365a63",
    },
  },
  dataProtectionLabel: {
    fontSize: "0.875rem",
    lineHeight: "1",
  },
}));

type TParams = { id: string };

type Props = {
  userRole: userRoleType;
};

// const defaultProps: Props = {
//   userRole: userRoleType.USER,
// };

export const CompanyForm: React.FC<Props> = ({
  userRole = userRoleType.USER,
}) => {
  const classes = useStyles();
  const [companyToEdit, setCompanyToEdit] = React.useState({} as CompanyData);
  const [newCompany, setNewCompany] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [tags, setTags] = React.useState<TagData[]>();

  const [showLocationsAmount, setShowLocationsAmount] =
    React.useState<number>(3);
  const params = useParams<TParams>();
  init(process.env.REACT_APP_EMAILJS_USER_ID as string);
  React.useEffect(() => {
    const fetchAllTags = async () => {
      const allTags: TagData[] = await getTags();
      setTags(allTags);
    };
    fetchAllTags();
  }, []);
  React.useEffect(() => {
    const fetchCompanyToEdit = async () => {
      let data = await fetchCompany(params.id);
      data["company_name"] = params.id;
      if (data["videos"] === undefined || data["videos"] === null) {
        data["videos"] = [""];
      }
      if (data["approved"] === undefined || data["approved"] === null) {
        data["approved"] = true;
      }
      if (
        data["featured_company"] === undefined ||
        data["featured_company"] === null
      ) {
        data["featured_company"] = false;
      }
      if (data["specialisations_domains"] === [""]) {
        data["specialisations_domains"] = [];
      }

      if (
        data["geographic_segment"] === undefined ||
        data["geographic_segment"] === null ||
        Object.keys(data["geographic_segment"]).length === 0
      ) {
        data["geographic_segment"] = {
          "North America": 0,
          EMEA: 0,
          Asia: 0,
          Other: 0,
        };
      }
      setCompanyToEdit({ ...data });
    };
    if (params.id === undefined || params.id === "undefined") {
      setLoading(false);
    } else {
      fetchCompanyToEdit().then(() => {
        setLoading(false);
        setNewCompany(false);
      });
    }
  }, []);

  // React.useEffect(() => {
  //   if (match.params.companyName && match.params.companyName !== "") {
  //     setNewCompany(false);
  //   }
  // }, []);

  // React.useEffect(() => {
  //   console.log(companyToEdit);
  // }, [companyToEdit]);

  const deleteCompanyHandler = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete ${companyToEdit.company_name}? This action is not reversible.`
      )
    ) {
      setLoading(true);
      try {
        await deleteCompany(params.id);
        window.history.back();
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }
  };

  const [state, setState] = React.useState({
    company_name: companyToEdit ? companyToEdit.company_name : "",
    about_the_company: "",
    headquarters_location: "",
    website: "",
    company_type: "",
    founded_year: "",
    total_funding_amount: "",
    number_of_clients: 0,
    number_of_employees: 0,
    all_locations: [{}, {}, {}, {}] as LocationData[],
    videos: ["", "", ""] as string[],
    industry_focus: [] as string[],
    specialisations_domains: [] as string[],
    "7_axis_of_analysis": {
      culture: "",
      organisation: "",
      operations: "",
      governance: "",
      business: "",
      security: "",
      innovation: "",
    } as { [axis: string]: string },
    geographic_segment: {
      "North America": 0,
      EMEA: 0,
      Asia: 0,
      Other: 0,
    } as { [region: string]: number },
    focus_areas: [] as string[],
    admin_notes: "",
    approved: false,
    featured_company: false,
  } as CompanyData);

  React.useEffect(() => {
    setState({ ...state, ...companyToEdit });
    setShowStockSymbol(
      companyToEdit.stock_symbol !== undefined &&
        companyToEdit.stock_symbol !== null &&
        companyToEdit.stock_symbol !== ""
    );
  }, [companyToEdit]);

  const [showStockSymbol, setShowStockSymbol] = React.useState(false);
  const [industryFocusOther, setIndustryFocusOther] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [missingFields, setMissingFields] = React.useState<string[]>([]);
  const [showErrors, setShowErrors] = React.useState(true);
  const [newGeographicSegmentRegion, setNewGeographicSegmentRegion] =
    React.useState("");
  const [newGeographicSegmentRevenue, setNewGeographicSegmentRevenue] =
    React.useState(0);
  const [formComplete, setFormComplete] = React.useState(false);
  let history = useHistory();
  const auth = getAuth();
  const [user] = useAuthState(auth);

  // const checkMissingFields = () => {
  //   if (state.company_name === "") {
  //     setMissingFields([...missingFields, "company_name"]);
  //   } else if (state.company_type === "") {
  //     setMissingFields([...missingFields, "company_type"]);
  //   } else if (state.specialisations_domains[0] === "") {
  //     setMissingFields([...missingFields, "specialisation_domains"]);
  //   } else {
  //     setMissingFields([]);
  //   }
  // };

  const isValid =
    userRole === userRoleType.ADMIN || userRole === userRoleType.PARTNER
      ? state.company_name &&
        state.company_name.length > 1 &&
        state.company_type !== "" &&
        state.specialisations_domains.length > 0
      : state.company_name &&
        state.company_name.length > 1 &&
        state.company_type !== "";
  const saveCompanyData = (event: React.FormEvent) => {
    event.preventDefault();
    if (newCompany) {
      createNewCompany(state, userRole)
        .then(() => {
          // send notification email: new registration
          setSuccess(true);
        })
        .catch((e) => setError(true));
    } else {
      updateCompany(state, userRole)
        .then(() => {
          if (userRole === userRoleType.PARTNER) {
            emailjs.send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
              process.env.REACT_APP_EMAILJS_PROFILE_UPDATE_TEMPLATE as string,
              { companyName: state.company_name },
              process.env.REACT_APP_EMAILJS_USER_ID as string
            );
          }
          setSuccess(true);
        })
        .catch((e) => {
          setError(true);
          console.error(e);
        });
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (state.all_locations == null || state.all_locations === []) {
      setState({
        ...state,
        all_locations: [{}, {}, {}, {}] as LocationData[],
      });
    }
  }, [state]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.currentTarget.name]: event.currentTarget.value as string,
    });
  };
  const handleIndustryFocusOtherChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIndustryFocusOther(event.currentTarget.value as string);
  };
  const addOtherIndustry = () => {
    setState({
      ...state,
      industry_focus: [...state.industry_focus, industryFocusOther],
    });
  };
  const toggleShowStockSymbol = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowStockSymbol(event.target.checked);
  };

  const handle7AxisChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      ["7_axis_of_analysis"]: {
        ...state["7_axis_of_analysis"],
        [event.target.name]: event.target.value,
      },
    });
  };
  const handleGeographicSegmentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({
      ...state,
      ["geographic_segment"]: {
        ...state["geographic_segment"],
        [event.target.name]: parseFloat(event.target.value),
      },
    });
  };
  const handleRemoveGeographicSegment = (region: string) => {
    try {
      if (state["geographic_segment"] !== null) {
        if (window.confirm(`Delete ${region} revenue?`)) {
          delete state["geographic_segment"][region];
        }
      }
    } catch (e) {
      console.error("Could not delete the region");
    }
    setState({
      ...state,
      ["geographic_segment"]: {
        ...state["geographic_segment"],
      },
    });
  };
  const handleNewRegion = () => {
    setState({
      ...state,
      ["geographic_segment"]: {
        ...state["geographic_segment"],
        [newGeographicSegmentRegion]: newGeographicSegmentRevenue,
      },
    });
    setNewGeographicSegmentRegion("");
    setNewGeographicSegmentRevenue(0);
  };
  const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (state.videos == null) {
      state.videos = [];
    }
    let videolist = [...state.videos];
    videolist[parseInt(event.target.name)] = event.target.value;
    setState({
      ...state,
      videos: videolist,
    });
  };

  const handleLocationsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    if (state.all_locations == null) {
      state.all_locations = [];
    }
    let locationList = [...state.all_locations];
    switch (field) {
      case "address":
        locationList[parseInt(event.target.name)].address = event.target.value;
        setState({
          ...state,
          all_locations: locationList,
        });
        break;
      case "city":
        locationList[parseInt(event.target.name)].city = event.target.value;
        setState({
          ...state,
          all_locations: locationList,
        });
        break;
      case "country":
        locationList[parseInt(event.target.name)].country = event.target.value;
        setState({
          ...state,
          all_locations: locationList,
        });
        break;
      default:
        return;
    }
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    let newList: string[] = [];
    let newFeaturesList: {};

    switch (field) {
      case "industry_focus":
        if (state.industry_focus == null) {
          state.industry_focus = [];
        }
        let industryList = [...state.industry_focus];
        if (industryList.includes(event.target.name)) {
          newList = industryList.filter((item) => item !== event.target.name);
        } else {
          newList = [...industryList, event.target.name];
        }
        setState({
          ...state,
          [field]: newList,
        });
        break;
      case "specialisations_domains":
        if (state.specialisations_domains == null) {
          state.specialisations_domains = [];
        }
        let domainsList = [...state.specialisations_domains];
        if (domainsList.includes(event.target.name)) {
          newList = domainsList.filter((item) => item !== event.target.name);
        } else {
          newList = [...domainsList, event.target.name];
        }
        setState({
          ...state,
          [field]: newList,
        });
        break;
      case "focus_areas":
        if (state.focus_areas == null) {
          state.focus_areas = [];
        }
        let focusAreasList = [...state.focus_areas];
        if (focusAreasList.includes(event.target.name)) {
          newList = focusAreasList.filter((item) => item !== event.target.name);
        } else {
          newList = [...focusAreasList, event.target.name];
        }
        setState({
          ...state,
          [field]: newList,
        });
        break;
      case "data_source_type":
        if (state.data_source_type == null) {
          state.data_source_type = [];
        }
        let dataSourceList = [...state.data_source_type];
        if (dataSourceList.includes(event.target.name)) {
          newList = dataSourceList.filter((item) => item !== event.target.name);
        } else {
          newList = [...dataSourceList, event.target.name];
        }
        setState({
          ...state,
          [field]: newList,
        });
        break;
      case "features":
        let featureName = event.target.name.split(".")[0];
        let value = event.target.name.split(".")[1];
        if (state.features == null || state.features == undefined) {
          state.features = {};
        }
        let featuresList = state.features;
        if (Object.keys(featuresList).includes(featureName)) {
          delete featuresList[featureName];

          // TODO: also delete from tags, not just features
          newFeaturesList = featuresList;
        } else {
          featuresList[featureName] = value;
          newFeaturesList = featuresList;
        }
        setState({
          ...state,
          [field]: newFeaturesList,
        });
        break;
      default:
        return;
    }
  };

  const handleTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    field: string
  ) => {
    setState({
      ...state,
      [field]: event.target.value as string,
    });
  };

  const SaveButtons = () => {
    return (
      <>
        <div className="form__field form__buttons">
          <Button
            className="form__button--submit"
            variant="contained"
            type="submit"
            disableElevation
            color="primary"
            size="large"
            disabled={!isValid}
          >
            Save
          </Button>
          <span style={{ margin: "0 5px" }}>&nbsp;</span>
          <Button
            variant="outlined"
            disableElevation
            size="large"
            onClick={() => {
              history.goBack();
            }}
            className="form__button--cancel"
          >
            Cancel
          </Button>
        </div>
        {!isValid && (
          <Alert severity="warning">
            Please fill in all required fields (marked with a *):
            <ul>
              <li>Company name</li>
              <li>Company type</li>
              {userRole !== userRoleType.USER && (
                <li>Specialisation domains</li>
              )}
            </ul>
          </Alert>
        )}
      </>
    );
  };
  return (
    <div className="new-registration">
      {loading && <LoadingAnimation message="Fetching data..." />}
      {!success && !loading && (
        <form onSubmit={saveCompanyData} className="form">
          {userRole === userRoleType.ADMIN && (
            <>
              <div className="admin-section">
                <h1>Admin only</h1>
                <div className="form__field">
                  {/* <h2>Accout type*</h2> */}

                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Account type
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="account_type"
                      value={state.account_type}
                      onChange={(e) => handleTypeChange(e, "account_type")}
                      label="Account type"
                    >
                      {Object.keys(AccountType).map((type) => {
                        return (
                          <MenuItem
                            key={type as string}
                            value={
                              AccountType[type as keyof typeof AccountType]
                            }
                          >
                            {AccountType[type as keyof typeof AccountType]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="form__field">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <span>Show as featured company on the homepage?</span>
                    {/* <h2>Approve*</h2> */}
                    <Checkbox
                      checked={
                        state.featured_company ? state.featured_company : false
                      }
                      onChange={() =>
                        setState({
                          ...state,
                          featured_company: !state.featured_company,
                        })
                      }
                      color="primary"
                    />{" "}
                  </div>
                </div>

                <div className="form__field">
                  <TextField
                    multiline={true}
                    rows={3}
                    name="admin_notes"
                    label="Comments"
                    placeholder="Add any comments / notes here"
                    variant="outlined"
                    value={state.admin_notes}
                    onChange={handleChange}
                    fullWidth
                  />
                </div>

                <div className="form__field">
                  {state.approved ? (
                    <Alert severity="success">
                      The company is visible on the marketplace
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        <span>Uncheck to remove </span>
                        <Checkbox
                          checked={state.approved}
                          onChange={() =>
                            setState({ ...state, approved: !state.approved })
                          }
                          color="primary"
                        />{" "}
                      </div>
                    </Alert>
                  ) : (
                    <>
                      <Alert severity="warning">
                        The company is not visible on the marketplace
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",

                            marginBottom: "10px",
                            width: "100%",
                          }}
                        >
                          <span>Check to activate </span>
                          <Checkbox
                            checked={state.approved}
                            onChange={() =>
                              setState({ ...state, approved: !state.approved })
                            }
                            color="primary"
                          />{" "}
                        </div>
                      </Alert>
                    </>
                  )}
                  <br />
                  <hr />
                  <br />
                  <div
                    onClick={() => {
                      deleteCompanyHandler();
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <Button className="btn" variant="outlined">
                      <Delete
                        style={{ color: "rgba(0,0,0,0.5)" }}
                        className="clickable"
                      >
                        Delete company
                      </Delete>
                      <span
                        style={{
                          paddingLeft: "1rem",
                          color: "rgba(0,0,0,0.48)",
                        }}
                      >
                        Delete company
                      </span>
                    </Button>
                  </div>
                </div>

                {/* <SaveButtons /> */}
              </div>
            </>
          )}
          <div className="section">
            {" "}
            <p className="section__header">Company Overview</p>
            <span>
              With reference to your profile in the Data Leaders Marketplace
              please modify and/or complete the information that already exists,
              making the appropriate edits in this form. All information will be
              reviewed by the Data Leaders Marketplace team. We are happy to
              announce that more updates to the Marketplace will be released
              shortly. If you have any suggestions please contact us; we love
              your feedback!
            </span>
            <h1>Overview</h1>
            <div className="form__field">
              <h2>
                Company name <span>*</span>
              </h2>
              <TextField
                required
                disabled={!newCompany}
                name="company_name"
                label="Company name"
                variant="outlined"
                value={state.company_name || ""}
                onChange={handleChange}
                fullWidth
              />

              {missingFields.includes("company_name") && (
                <Alert style={{ marginTop: 10 }} severity="warning">
                  Field required
                </Alert>
              )}
            </div>
            <div className="form__field">
              <h2>Company type*</h2>

              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  Company type
                </InputLabel>

                <Select
                  required
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="topic"
                  value={state.company_type}
                  onChange={(e) => handleTypeChange(e, "company_type")}
                  label="Company type"
                >
                  {/* <MenuItem value="">
                    <em>Please select</em>
                  </MenuItem> */}
                  {Object.keys(CompanyTypeList).map((_, id) => {
                    return (
                      <MenuItem key={id} value={CompanyTypeList[id as number]}>
                        {CompanyTypeList[id as number]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            {/* TODO Why is logo being overriden with 
            older? <div className="form__field">
              <h2 className="disabled">Logo (WIP)</h2>
              <TextField
                disabled
                name="company_logo_file_location"
                label="Placeholder logo"
                placeholder="Placeholder logo"
                variant="outlined"
                value={state.company_logo_file_location || ""}
                onChange={handleChange}
                fullWidth
              />
            </div> */}
            <div className="form__field">
              <h2>About</h2>

              <p>
                Please give a brief description about the company (Max. 500
                characters)
              </p>
              <TextField
                multiline={true}
                rows={3}
                name="about_the_company"
                label="About"
                variant="outlined"
                value={state.about_the_company}
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="form__field">
              <h2>Headquarters address</h2>

              <TextField
                multiline={true}
                rows={3}
                name="headquarters_location"
                label="Headquarters address"
                variant="outlined"
                value={state.headquarters_location}
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="form__field">
              <h2>Contact email address</h2>
              <p>
                If you are a Basic or Premium member, users can contact you via
                this address.{" "}
              </p>
              <TextField
                name="general_contact_email"
                type="email"
                label="Contact email"
                variant="outlined"
                value={state.general_contact_email}
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="form__field">
              <h2>Company website</h2>

              <TextField
                name="website"
                type="url"
                label="Company website"
                variant="outlined"
                value={state.website}
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="form__field">
              <h2>Year founded</h2>

              <TextField
                name="founded_year"
                type="number"
                label="Year founded"
                variant="outlined"
                value={state.founded_year}
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="form__field">
              <h2>
                Add stock symbol?&nbsp;
                <Checkbox
                  checked={showStockSymbol}
                  onChange={toggleShowStockSymbol}
                  color="default"
                />
              </h2>
              {showStockSymbol && (
                <TextField
                  name="stock_symbol"
                  label="Stock symbol"
                  variant="outlined"
                  value={state.stock_symbol || ""}
                  onChange={handleChange}
                  fullWidth
                />
              )}
            </div>
            <div className="form__field">
              <h2>Total funding amount</h2>

              <TextField
                name="total_funding_amount"
                label="Total funding amount"
                variant="outlined"
                value={state.total_funding_amount}
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="form__field">
              <h2>Number of clients</h2>

              <TextField
                name="number_of_clients"
                type="number"
                label="Number of clients"
                variant="outlined"
                value={state.number_of_clients}
                onChange={handleChange}
                fullWidth
              />
            </div>
            <div className="form__field form__field__geographic-revenue-segmentation">
              <h2>Geographic segmentation of revenue</h2>
              <p>
                Please enter numeric values only (either as percentage values,
                or revenue).
              </p>
              {state.geographic_segment &&
                Object.keys(state.geographic_segment).map((regionName) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      key={regionName}
                      className="geographic-segment"
                      name={regionName}
                      label={regionName}
                      // fullWidth
                      variant="outlined"
                      type="number"
                      value={
                        state.geographic_segment !== null
                          ? state.geographic_segment[regionName]
                          : 0
                      }
                      onChange={handleGeographicSegmentChange}
                    />
                    <a
                      className="form__button--remove-item"
                      onClick={() => {
                        handleRemoveGeographicSegment(regionName);
                      }}
                    >
                      x delete region
                    </a>

                    {/* <Delete
                      onClick={() => {
                        handleRemoveGeographicSegment(regionName);
                      }}
                    /> */}
                  </div>
                ))}
              <div style={{ margin: "10px 0" }}>Add new region</div>

              <div className="new">
                <TextField
                  className="geographic-segment--new"
                  name="new-geographic-segment-region"
                  label="Region name"
                  // fullWidth
                  variant="outlined"
                  value={newGeographicSegmentRegion}
                  onChange={(e) => {
                    setNewGeographicSegmentRegion(e.target.value);
                  }}
                />
                <TextField
                  className="geographic-segment--new"
                  name="new-geographic-segment-revenue"
                  label="Revenue"
                  // fullWidth
                  type="number"
                  variant="outlined"
                  value={newGeographicSegmentRevenue}
                  onChange={(e) => {
                    setNewGeographicSegmentRevenue(parseFloat(e.target.value));
                  }}
                />
                <Button onClick={handleNewRegion}>Add</Button>
              </div>
            </div>
            <div className="form__field">
              <h2>Number of employees</h2>

              <TextField
                name="number_of_employees"
                type="number"
                label="Number of employees"
                variant="outlined"
                value={state.number_of_employees}
                onChange={handleChange}
                fullWidth
              />
            </div>
            {(userRole === userRoleType.ADMIN ||
              userRole === userRoleType.PARTNER) && (
              <>
                <div className="form__field">
                  <h2>
                    Main company locations{" "}
                    <span className="header-note">
                      (Basic and Premium accounts only)
                    </span>
                  </h2>

                  {state.all_locations &&
                    state.all_locations
                      .filter((_, i) => {
                        if (i >= showLocationsAmount) {
                          return false;
                        }
                        return true;
                      })
                      .map((location, id) => (
                        <div key={id}>
                          <p>Location {id + 1}</p>
                          <TextField
                            className="form__input--location"
                            name={id.toString()}
                            label="Address"
                            variant="outlined"
                            value={
                              state.all_locations
                                ? state.all_locations[id]["address"]
                                : "" || ""
                            }
                            onChange={(e) =>
                              handleLocationsChange(e, "address")
                            }
                            fullWidth
                          />
                          <TextField
                            className="form__input--location form__input--location--city"
                            name={id.toString()}
                            label="City"
                            variant="outlined"
                            value={
                              state.all_locations
                                ? state.all_locations[id]["city"]
                                : "" || ""
                            }
                            onChange={(e) => handleLocationsChange(e, "city")}
                          />
                          <TextField
                            className="form__input--location form__input--location--country"
                            name={id.toString()}
                            label="Country"
                            variant="outlined"
                            value={
                              state.all_locations
                                ? state.all_locations[id]["country"]
                                : "" || ""
                            }
                            onChange={(e) =>
                              handleLocationsChange(e, "country")
                            }
                          />
                        </div>
                      ))}
                  {state.all_locations && state.all_locations.length < 100 && (
                    <a
                      className="form__button--add-item"
                      onClick={() => {
                        if (state.all_locations != null) {
                          setState({
                            ...state,
                            all_locations: [
                              ...state.all_locations,
                              {} as LocationData,
                            ],
                          });
                        } else {
                          setState({
                            ...state,
                            all_locations: [] as LocationData[],
                          });
                        }
                      }}
                    >
                      + add location
                    </a>
                  )}
                  {state.all_locations && state.all_locations.length > 1 && (
                    <a
                      className="form__button--remove-item"
                      onClick={() => {
                        if (
                          state.all_locations != null &&
                          state.all_locations.length > 1
                        ) {
                          let oldLocationsList = state.all_locations;
                          oldLocationsList.pop();
                          setState({
                            ...state,
                            all_locations: oldLocationsList,
                          });
                        } else {
                          setState({
                            ...state,
                            all_locations: [] as LocationData[],
                          });
                        }
                      }}
                    >
                      x delete location
                    </a>
                  )}
                </div>
                {state["all_locations"] &&
                  state["all_locations"]!.length - showLocationsAmount > 0 && (
                    <Button
                      className="lower-case-button theme-button outline fullwidth-button"
                      variant="outlined"
                      onClick={() =>
                        setShowLocationsAmount(showLocationsAmount + 8)
                      }
                    >
                      Show more locations (
                      {state["all_locations"]!.length - showLocationsAmount}{" "}
                      more)
                    </Button>
                  )}
              </>
            )}
            {(userRole === userRoleType.ADMIN ||
              userRole === userRoleType.PARTNER) && (
              <div className="form__field">
                <h2>
                  Introduction videos{" "}
                  <span className="header-note">
                    (Basic and Premium accounts only)
                  </span>
                </h2>
                <p>
                  Please use the <em>embedded</em> Youtube url, not the standard
                  url, otherwise the video will appear with an error: <br />
                  e.g.:<code>https://www.youtube.com/embed/_ro0bqUQ1J0</code>
                </p>
                {state.videos !== null &&
                  state.videos.map((video, id) => {
                    return (
                      <TextField
                        key={`video${id}`}
                        type="url"
                        className="form__input--video"
                        name={id.toString()}
                        label={`Video link ${id + 1}`}
                        variant="outlined"
                        value={state.videos ? state.videos[id] : null}
                        onChange={handleVideoChange}
                        fullWidth
                      />
                    );
                  })}
                {state.videos && state.videos.length < 15 && (
                  <a
                    className="form__button--add-item"
                    onClick={() => {
                      if (state.videos != null) {
                        setState({ ...state, videos: [...state.videos, ""] });
                      } else {
                        setState({ ...state, videos: [""] });
                      }
                    }}
                  >
                    + add link
                  </a>
                )}
                {state.videos && state.videos.length > 1 && (
                  <a
                    className="form__button--remove-item"
                    onClick={() => {
                      if (state.videos != null && state.videos.length > 1) {
                        let oldVideoList = state.videos;
                        oldVideoList.pop();
                        setState({ ...state, videos: oldVideoList });
                      } else {
                        setState({ ...state, videos: [""] });
                      }
                    }}
                  >
                    x delete link
                  </a>
                )}
              </div>
            )}
          </div>
          {(userRole === userRoleType.ADMIN ||
            userRole === userRoleType.PARTNER) && (
            <div className="section">
              <p className="section__header">Product overview</p>

              <h1>Data Capability </h1>
              <div className="form__field">
                <h2>Domains*</h2>

                <FormGroup>
                  <div className="form__field__checkbox-columns">
                    <div>
                      {DomainList.filter(
                        (_, id) => id < DomainList.length / 2
                      ).map((item) => {
                        return (
                          <div key={item}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className={classes.icon} />}
                                  checkedIcon={
                                    <span
                                      className={clsx(
                                        classes.icon,
                                        classes.checkedIcon
                                      )}
                                    />
                                  }
                                  checked={
                                    state.specialisations_domains !==
                                      undefined &&
                                    indexOfCaseInsensitive(
                                      state.specialisations_domains,
                                      item
                                    ) > -1
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      "specialisations_domains"
                                    )
                                  }
                                  name={item}
                                  color="primary"
                                />
                              }
                              label={capitalise(item)}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      {DomainList.filter(
                        (_, id) => id >= DomainList.length / 2
                      ).map((item) => {
                        return (
                          <div key={item}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className={classes.icon} />}
                                  checkedIcon={
                                    <span
                                      className={clsx(
                                        classes.icon,
                                        classes.checkedIcon
                                      )}
                                    />
                                  }
                                  checked={
                                    state.specialisations_domains !==
                                      undefined &&
                                    state.specialisations_domains.includes(item)
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      "specialisations_domains"
                                    )
                                  }
                                  name={item}
                                  color="primary"
                                />
                              }
                              label={capitalise(item)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </FormGroup>
                {missingFields.includes("specialisation_domains") && (
                  <Alert style={{ marginTop: 10 }} severity="warning">
                    Field required
                  </Alert>
                )}
              </div>
              <div className="form__field">
                <h2>Features and tags</h2>
                <p>
                  All features will also be saved as tags. Available tags depend
                  on the domains selected in the previous section.
                </p>

                <FormGroup>
                  {/* <h3>Analytics</h3> */}
                  <div className="form__field__features">
                    <div className="form__field__features__column">
                      <div></div>
                      {tags &&
                        tags.map((item: TagData) => {
                          return (
                            <div
                              key={item.id}
                              className="form__field__features__row form__field__features__row-label"
                            >
                              {item.value}
                            </div>
                          );
                        })}
                    </div>
                    <div className="form__field__features__column">
                      <div>Integrated</div>

                      {tags &&
                        tags.map((item: TagData) => {
                          return (
                            <div
                              key={item.id}
                              className="form__field__features__row"
                            >
                              <Checkbox
                                icon={<span className={classes.icon} />}
                                checkedIcon={
                                  <span
                                    className={clsx(
                                      classes.icon,
                                      classes.checkedIcon
                                    )}
                                  />
                                }
                                checked={
                                  state.features !== null &&
                                  state.features !== undefined &&
                                  state.features[item.value] === "integrated"
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(e, "features")
                                }
                                name={item.value + ".integrated"}
                                color="primary"
                              />
                            </div>
                          );
                        })}
                    </div>
                    <div className="form__field__features__column">
                      <div>Standalone</div>

                      {tags &&
                        tags.map((item: TagData) => {
                          return (
                            <div
                              key={item.id}
                              className="form__field__features__row"
                            >
                              <Checkbox
                                icon={<span className={classes.icon} />}
                                checkedIcon={
                                  <span
                                    className={clsx(
                                      classes.icon,
                                      classes.checkedIcon
                                    )}
                                  />
                                }
                                checked={
                                  state.features !== null &&
                                  state.features !== undefined &&
                                  state.features[item.value] === "standalone"
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(e, "features")
                                }
                                name={item.value + ".standalone"}
                                color="primary"
                              />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div className="form__field">
                <h2>Industry focus</h2>

                <FormGroup>
                  <div className="form__field__checkbox-columns">
                    <div>
                      {IndustryFocusList.filter(
                        (_, id) => id < IndustryFocusList.length / 2
                      ).map((item) => {
                        return (
                          <div key={item}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className={classes.icon} />}
                                  checkedIcon={
                                    <span
                                      className={clsx(
                                        classes.icon,
                                        classes.checkedIcon
                                      )}
                                    />
                                  }
                                  checked={
                                    state.industry_focus !== undefined &&
                                    state.industry_focus.includes(item)
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "industry_focus")
                                  }
                                  name={item}
                                  color="primary"
                                />
                              }
                              label={item}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      {IndustryFocusList.filter(
                        (_, id) => id >= IndustryFocusList.length / 2
                      ).map((item) => {
                        return (
                          <div key={item}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className={classes.icon} />}
                                  checkedIcon={
                                    <span
                                      className={clsx(
                                        classes.icon,
                                        classes.checkedIcon
                                      )}
                                    />
                                  }
                                  checked={
                                    state.industry_focus !== undefined &&
                                    state.industry_focus.includes(item)
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "industry_focus")
                                  }
                                  name={item}
                                  color="primary"
                                />
                              }
                              label={item}
                            />
                          </div>
                        );
                      })}
                      {state.industry_focus &&
                        state.industry_focus.includes("Other") && (
                          <TextField
                            className="form__input--video"
                            name={industryFocusOther}
                            variant="outlined"
                            value={industryFocusOther ? industryFocusOther : ""}
                            onChange={handleIndustryFocusOtherChange}
                            fullWidth
                            size="small"
                            onBlur={addOtherIndustry}
                          />
                        )}
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div className="form__field">
                <h2>Function focus / Department</h2>

                <FormGroup>
                  <div className="form__field__checkbox-columns">
                    <div>
                      {FunctionFocusList.filter(
                        (_, id) => id < FunctionFocusList.length / 2
                      ).map((item) => {
                        return (
                          <div key={item}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className={classes.icon} />}
                                  checkedIcon={
                                    <span
                                      className={clsx(
                                        classes.icon,
                                        classes.checkedIcon
                                      )}
                                    />
                                  }
                                  checked={
                                    state.focus_areas !== undefined &&
                                    state.focus_areas.includes(item)
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "focus_areas")
                                  }
                                  name={item}
                                  color="primary"
                                />
                              }
                              label={item}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      {FunctionFocusList.filter(
                        (_, id) => id >= FunctionFocusList.length / 2
                      ).map((item) => {
                        return (
                          <div key={item}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className={classes.icon} />}
                                  checkedIcon={
                                    <span
                                      className={clsx(
                                        classes.icon,
                                        classes.checkedIcon
                                      )}
                                    />
                                  }
                                  checked={
                                    state.focus_areas !== undefined &&
                                    state.focus_areas.includes(item)
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "focus_areas")
                                  }
                                  name={item}
                                  color="primary"
                                />
                              }
                              label={item}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </FormGroup>
              </div>

              <div className="form__field">
                <h2>Data source type</h2>

                <FormGroup>
                  <div className="form__field__checkbox-columns">
                    <div>
                      {DataSourceTypeList.map((item) => {
                        return (
                          <div key={item}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<span className={classes.icon} />}
                                  checkedIcon={
                                    <span
                                      className={clsx(
                                        classes.icon,
                                        classes.checkedIcon
                                      )}
                                    />
                                  }
                                  checked={
                                    state.data_source_type !== null &&
                                    state.data_source_type !== undefined &&
                                    state.data_source_type.includes(item)
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "data_source_type")
                                  }
                                  name={item}
                                  color="primary"
                                />
                              }
                              label={item}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div className="form__field">
                <h2>Feature information</h2>
                <TextField
                  name="feature_information"
                  multiline={true}
                  rows={3}
                  label=""
                  variant="outlined"
                  value={state.feature_information}
                  onChange={handleChange}
                  fullWidth
                />
              </div>

              <div className="form__field">
                <h2>Portfolio overview / product description</h2>
                <TextField
                  name="product_description"
                  multiline={true}
                  rows={3}
                  label=""
                  variant="outlined"
                  value={state.product_description}
                  onChange={handleChange}
                  fullWidth
                />
              </div>
              <>
                <div className="form__field">
                  <h2>Open positions</h2>
                  <p>Add a link to your current open positions.</p>
                  <TextField
                    name="open_positions_link"
                    type="string"
                    label="Link to open positions"
                    variant="outlined"
                    value={state.open_positions_link}
                    onChange={handleChange}
                    fullWidth
                  />
                </div>
                {/* <div className="form__field">
                  <h2 className="disabled">Customer references (WIP)</h2>
                  <TextField
                    disabled
                    name=""
                    placeholder="Enter your answer"
                    label=""
                    variant="outlined"
                    value=""
                    onChange={handle7AxisChange}
                    fullWidth
                  />
                </div>
                <div className="form__field">
                  <h2 className="disabled">Research and white papers (WIP)</h2>
                  <TextField
                    disabled
                    name=""
                    placeholder="Enter your answer"
                    label=""
                    variant="outlined"
                    value=""
                    onChange={handle7AxisChange}
                    fullWidth
                  />
                </div> */}
              </>
            </div>
          )}
          <SaveButtons />
        </form>
      )}
      {error && <div>Error</div>}
      {success && (
        <div className="confirmation-message">
          <Alert severity="success">
            Company profile successfully {newCompany ? "saved" : "updated"}.
          </Alert>
          <Link to={ROUTES.ALL_COMPANIES}>&lt; back</Link>
          <br />
          {newCompany && (
            <p>
              Your profile will be reviewed and we will get back to you as soon
              as we can.
            </p>
          )}
        </div>
      )}
    </div>
  );
};
