import React from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckIcon from "@material-ui/icons/Check";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import GroupIcon from "@material-ui/icons/Group";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";
import { LoadingAnimation } from "../../components/loadingAnimation";
import { StockChart } from "../stockChart";
import { PieChart } from "react-minimal-pie-chart";
import { PaddedPaper } from "../padded_paper";
import { CompanyData } from "../../types/companies";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LinkIcon from "@material-ui/icons/Link";
import { colorsForGraph, getRandomHslColor } from "../../utils/utils";
import emailjs from "emailjs-com";
import { init } from "@emailjs/browser";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

type GeographicSegmentsData = {
  title: string;
  value: number;
  color: string;
  totalValue: number;
}[];

interface CompanyDetailsDisplayProps {
  companyDatum: CompanyData;
  suggestedCompanies: CompanyData[] | undefined;
}

export default function OverviewTab({
  companyDatum,
  suggestedCompanies,
}: CompanyDetailsDisplayProps) {
  const [showLocationAmount, setShowLocationAmount] = React.useState<number>(8);
  const [value, setValue] = React.useState(0);
  const [showContactForm, setShowContactForm] = React.useState(false);

  init(process.env.REACT_APP_EMAILJS_USER_ID as string);

  const [contactForm, setContactForm] = React.useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    tel: "",
    message: "",
    recipientCompanyEmail: companyDatum.general_contact_email
      ? companyDatum.general_contact_email
      : "",
    recipientCompanyName: companyDatum.company_name,
  });
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [missingData, setMissingData] = React.useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setContactForm({
      ...contactForm,
      [name]: value,
    });
  };
  const isPaidAccount = () => {
    return (
      companyDatum["account_type"] === "basic" ||
      companyDatum["account_type"] === "premium"
    );
  };

  const showIntroductionVideo =
    companyDatum!["videos"] !== null &&
    companyDatum!["videos"] !== undefined &&
    companyDatum!["videos"].length > 0 &&
    companyDatum["videos"][0] !== "";

  const showGeographicRevenueSeg =
    companyDatum["geographic_segment"] !== null &&
    companyDatum["geographic_segment"] !== undefined &&
    Object.keys(companyDatum["geographic_segment"]).length > 0 &&
    Object.values(companyDatum["geographic_segment"]).reduce(
      (previous, current) => previous + current,
      0
    ) > 0;

  const showStockPrice =
    companyDatum["stock_symbol"] !== null &&
    companyDatum["stock_symbol"] !== undefined;

  const showLocations =
    isPaidAccount() &&
    companyDatum["all_locations"] !== null &&
    companyDatum["all_locations"] !== undefined &&
    companyDatum["all_locations"].length !== 0;

  let geographicSegmentsData: GeographicSegmentsData = [];
  let validGeographicSegmentsData = true;
  if (companyDatum["geographic_segment"]) {
    try {
      Object.entries(companyDatum["geographic_segment"]).map(
        ([location, datapoint], i) => {
          const total = Object.values(
            companyDatum["geographic_segment"]!
          ).reduce((a, b) => {
            if (typeof b !== "number") {
              return a + parseFloat(b);
            }
            return a + b;
          }, 0);
          let value = 0;
          if (typeof datapoint !== "number") {
            value = (parseFloat(datapoint) / total) * 100;
          } else {
            value = (datapoint / total) * 100;
          }
          if (isNaN(value)) {
            throw "Not a number";
          }
          const color = geographicSegmentsData.push({
            title: location,
            value: Math.round(value * 100) / 100,
            color: i <= 4 ? colorsForGraph[i] : getRandomHslColor(),
            totalValue: total,
          });
        }
      );
    } catch (e) {
      console.error(e);
      validGeographicSegmentsData = false;
    }
  }
  const AboutItem = () => {
    return (
      <div className="company-details-information about item">
        <h3>About</h3>
        <p>{companyDatum["about_the_company"]}</p>
      </div>
    );
  };

  const LocationItem = () => {
    return (
      <div className="company-details-information details item">
        {companyDatum["headquarters_location"] && (
          <>
            <h3>
              <LocationOnIcon className="overview-icon blue" />
              Headquarters location:
            </h3>
            <p>{companyDatum["headquarters_location"]}</p>
          </>
        )}
        {companyDatum["website"] && (
          <>
            <h3>
              <LinkIcon className="overview-icon blue" />
              Website:
            </h3>
            <p>
              <a href={companyDatum["website"]} target="_blank">
                {companyDatum["website"]
                  // messy way to remove 'https://' and 'http://', and any trailing '/'
                  .replace("https", "http")
                  .substring(7)
                  .replace(/\/$/, "")}
              </a>
            </p>
          </>
        )}
      </div>
    );
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (
      contactForm.firstName === "" ||
      contactForm.lastName === "" ||
      contactForm.companyName === "" ||
      contactForm.message === "" ||
      contactForm.email === "" ||
      contactForm.tel === ""
    ) {
      setMissingData(true);
    } else {
      setLoading(true);
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
          process.env
            .REACT_APP_EMAILJS_USER_MESSAGE_TO_COMPANY_TEMPLATE as string,
          contactForm,
          process.env.REACT_APP_EMAILJS_USER_ID as string
        )
        .then((resp) => {
          setSuccess(true);
        })
        .catch((error: any) => {
          console.error("Failed to send email.");
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="container-box">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <AboutItem />
        </Grid>
        <Grid item xs={12} md={4}>
          <LocationItem />
        </Grid>
        {isPaidAccount() &&
          companyDatum["general_contact_email"] != null &&
          companyDatum["general_contact_email"] != undefined &&
          companyDatum["general_contact_email"] !== "" && (
            <Grid item xs={12}>
              <div className="company-details-information details item contact-company">
                <h3>Would you like to know more about this company?</h3>

                {showContactForm ? (
                  <Grid id="contact-form" item xs={12}>
                    <div className="contact-us__form">
                      <div className="contact-us__form-container">
                        <Paper elevation={2}>
                          <Box p={5}>
                            <p className="contact-form-header hero-body">
                              Thank you for your interest in{" "}
                              {companyDatum.company_name}. Please fill in the
                              following form and we will pass on your message.
                            </p>
                            {loading ? (
                              <LoadingAnimation message="Sending message" />
                            ) : success ? (
                              <Alert severity="success">
                                Message successfully sent.
                              </Alert>
                            ) : (
                              <FormControl style={{ minWidth: "100%" }}>
                                <div className="fieldWrapper">
                                  <TextField
                                    style={{
                                      width: "100%",
                                    }}
                                    variant="standard"
                                    required
                                    InputProps={{
                                      style: {
                                        padding: 12,
                                      },
                                    }}
                                    type="text"
                                    name="firstName"
                                    className="search-field"
                                    value={contactForm.firstName}
                                    placeholder="First name*"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="fieldWrapper">
                                  <TextField
                                    style={{
                                      width: "100%",
                                    }}
                                    variant="standard"
                                    InputProps={{
                                      style: {
                                        padding: 12,
                                      },
                                    }}
                                    type="text"
                                    name="lastName"
                                    className="search-field"
                                    value={contactForm.lastName}
                                    placeholder="Last name*"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="fieldWrapper">
                                  <TextField
                                    style={{
                                      width: "100%",
                                    }}
                                    variant="standard"
                                    InputProps={{
                                      style: {
                                        padding: 12,
                                      },
                                    }}
                                    type="text"
                                    name="companyName"
                                    className="search-field"
                                    value={contactForm.companyName}
                                    placeholder="Company name*"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="fieldWrapper">
                                  <TextField
                                    style={{
                                      width: "100%",
                                    }}
                                    variant="standard"
                                    InputProps={{
                                      style: {
                                        padding: 12,
                                      },
                                    }}
                                    type="email"
                                    name="email"
                                    className="search-field"
                                    value={contactForm.email}
                                    placeholder="Business email*"
                                    onChange={handleChange}
                                    fullWidth
                                  />
                                </div>
                                <div className="fieldWrapper">
                                  <TextField
                                    style={{
                                      width: "100%",
                                    }}
                                    variant="standard"
                                    InputProps={{
                                      style: {
                                        padding: 12,
                                      },
                                    }}
                                    type="text"
                                    name="tel"
                                    className="search-field"
                                    value={contactForm.tel}
                                    placeholder="Business phone*"
                                    onChange={handleChange}
                                    fullWidth
                                  />
                                </div>
                                <div className="fieldWrapper">
                                  <TextField
                                    style={{
                                      width: "100% !important",
                                    }}
                                    variant="standard"
                                    InputProps={{
                                      style: {
                                        padding: 12,
                                      },
                                    }}
                                    type="text"
                                    name="message"
                                    className="search-field"
                                    value={contactForm.message}
                                    placeholder="Your message*"
                                    multiline
                                    rows={4}
                                    onChange={handleChange}
                                    fullWidth
                                  />
                                  <br />
                                  {missingData && (
                                    <Alert severity="warning">
                                      Please fill in all required fields.
                                    </Alert>
                                  )}
                                </div>
                                <Button
                                  className="lower-case-button theme-button "
                                  variant="outlined"
                                  color="primary"
                                  onClick={handleSubmit}
                                >
                                  Submit
                                </Button>
                                <br />
                                <Button
                                  variant="text"
                                  color="default"
                                  onClick={() => {
                                    setShowContactForm(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </FormControl>
                            )}
                          </Box>
                        </Paper>
                      </div>
                    </div>
                  </Grid>
                ) : (
                  <Button
                    className="lower-case-button theme-button outline"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setShowContactForm(true);
                    }}
                  >
                    Get in touch with them
                  </Button>
                )}
              </div>
            </Grid>
          )}
        <Grid item xs={12}>
          <div className="item">
            <Grid container direction="row">
              {companyDatum["company_type"] && (
                <Grid item xs={12} md={4}>
                  <div className="highlight-data">
                    <h3>
                      <SettingsIcon className="overview-icon light-blue" />
                      Company type:
                    </h3>
                    <p>{companyDatum["company_type"]}</p>
                  </div>
                </Grid>
              )}
              {companyDatum["founded_year"] && (
                <Grid item xs={12} md={4}>
                  <div className="highlight-data">
                    <h3>
                      <CheckIcon className="overview-icon purple" />
                      Founded year:
                    </h3>
                    <p>{companyDatum["founded_year"]}</p>
                  </div>
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <div className="highlight-data">
                  <h3>
                    <AccountBalanceWalletIcon className="overview-icon orange" />
                    Total Funding Amount:
                  </h3>
                  <p>
                    {companyDatum["total_funding_amount"]
                      ? companyDatum["total_funding_amount"]
                      : "N/A"}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="highlight-data">
                  <h3>
                    <GroupIcon className="overview-icon yellow" />
                    Number of employees:
                  </h3>
                  <p>
                    {companyDatum["number_of_employees"] !== null &&
                    companyDatum["number_of_employees"] !== undefined
                      ? companyDatum["number_of_employees"]
                      : "N/A"}
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <div className="highlight-data">
                  <h3>
                    <BusinessCenterIcon className="overview-icon teal" />
                    Industries:
                  </h3>
                  <p>
                    {companyDatum["industry_focus"]
                      ? companyDatum["industry_focus"].join(", ")
                      : "N/A"}
                  </p>
                </div>
              </Grid>
              {companyDatum["number_of_clients"] !== null &&
                companyDatum["number_of_clients"] !== undefined &&
                companyDatum["number_of_clients"] > 0 && (
                  <Grid item xs={12} md={4}>
                    <div className="highlight-data">
                      <h3>
                        <GroupIcon className="overview-icon grey" />
                        Number of clients:
                      </h3>
                      <p>{companyDatum["number_of_clients"]}</p>
                    </div>
                  </Grid>
                )}
            </Grid>
          </div>
        </Grid>

        {showLocations && (
          <Grid item xs={12}>
            <div className="item">
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className="details-row locations"
              >
                {isPaidAccount() &&
                  companyDatum["all_locations"] &&
                  companyDatum["all_locations"].length !== 0 && (
                    <>
                      <Grid
                        item
                        xs={12}
                        className="company-details-information"
                      >
                        <h3>All {companyDatum["company_name"]} Locations</h3>
                        <Grid container spacing={2}>
                          {companyDatum["all_locations"]
                            .filter((_, i) => {
                              if (i >= showLocationAmount) {
                                return false;
                              }
                              return true;
                            })
                            .map((location) => {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  sm={3}
                                  key={location["address"]}
                                  className="location-container"
                                >
                                  <div className="location-container__card">
                                    <ul>
                                      <li>
                                        <span className="city">
                                          {location["city"]}
                                        </span>
                                      </li>
                                      <li>
                                        <span className="country">
                                          {location["country"]
                                            ? location["country"]
                                            : ""}
                                        </span>
                                      </li>
                                      <li className="location-address">
                                        {location["address"]}
                                      </li>
                                      <li></li>
                                    </ul>
                                  </div>
                                </Grid>
                              );
                            })}
                        </Grid>
                        {companyDatum["all_locations"].length -
                          showLocationAmount >
                          0 && (
                          <Button
                            className="lower-case-button theme-button outline fullwidth-button"
                            variant="outlined"
                            onClick={() =>
                              setShowLocationAmount(showLocationAmount + 8)
                            }
                          >
                            Show more locations (
                            {companyDatum["all_locations"].length -
                              showLocationAmount}{" "}
                            more)
                          </Button>
                        )}
                      </Grid>
                    </>
                  )}
              </Grid>
            </div>
          </Grid>
        )}
        {showIntroductionVideo && (
          <Grid item xs={12}>
            <div className="item">
              <Grid item xs={12} className=" videos">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h3>Introduction Video</h3>
                  </Grid>

                  <>
                    <Grid item xs={12}>
                      <div className="video-container">
                        {companyDatum["videos"] &&
                          companyDatum["videos"] !== [] && (
                            <iframe
                              width="100%"
                              height="500"
                              src={companyDatum["videos"][0]}
                              title="YouTube video player"
                            ></iframe>
                          )}
                      </div>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}

        {showStockPrice && (
          <Grid item xs={12}>
            <div className="item">
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="stretch"
                className="details-row"
              >
                <Grid
                  item
                  xs={12}
                  className="company-details-information stock-chart"
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <h3>Stock price</h3>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <PaddedPaper>
                        <StockChart
                          stockSymbol={companyDatum["stock_symbol"]}
                        />
                      </PaddedPaper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}

        {showGeographicRevenueSeg && (
          <Grid item xs={12}>
            <div className="item">
              {" "}
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="stretch"
                className="details-row"
              >
                <Grid
                  item
                  xs={12}
                  className="company-details-information geographic"
                >
                  <Grid container>
                    <Grid item xs={12} sm={4}>
                      <div className="geographic-container">
                        <h3 style={{ marginBottom: "2rem" }}>
                          Geographic Revenue Split
                        </h3>
                      </div>
                      {companyDatum["geographic_segment"] &&
                        geographicSegmentsData.map((item) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0 20px",
                                margin: "2rem 0",
                              }}
                            >
                              <div
                                style={{
                                  display: "inline-block",
                                  color: item.color,
                                  backgroundColor: item.color,
                                  width: 20,
                                  minWidth: 20,
                                  height: 20,
                                  borderRadius: "50%",
                                  marginRight: "1.2rem",
                                }}
                              ></div>
                              <span>{item.title}</span>
                            </div>
                          );
                        })}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      {companyDatum["geographic_segment"] &&
                        validGeographicSegmentsData && (
                          <div className="geographic">
                            <div
                              style={{
                                width: "100%",
                                background: "white",
                                borderRadius: 4,
                                padding: "2rem 0",
                              }}
                            >
                              <PieChart
                                style={{ height: "400px" }}
                                data={geographicSegmentsData}
                                lineWidth={55}
                                label={({ dataEntry }) =>
                                  Math.round(dataEntry.value * 10) / 10 + "%"
                                }
                                labelStyle={(index) => ({
                                  fill: geographicSegmentsData[index].color,
                                  fontSize: "5px",
                                  fontFamily: "sans-serif",
                                })}
                                // onMouseOver={}
                                paddingAngle={1}
                                radius={42}
                                labelPosition={112}
                              />
                            </div>
                          </div>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
