import { parse } from "querystring";
import React from "react";

interface DebugContextInterface {
  numberOfDbConnections: string | null;
  numberOfDocReads: string | null;
  numberOfCacheReads: string | null;
  isDevEnv: boolean;
  updating: boolean;
}

export const DebugContext = React.createContext({} as DebugContextInterface);

export function DebugContextProvider(props: any) {
  const isUserEnvDevEnv =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1";

  const [isDevEnv, setIsDevEnv] = React.useState<boolean>(isUserEnvDevEnv);
  const [numberOfDbConnections, setNumberOfDbConnections] = React.useState(
    localStorage.getItem("numberOfDbConnections") || "0"
  );
  const [numberOfDocReads, setNumberOfDocReads] = React.useState(
    localStorage.getItem("numberOfDocReads") || "0"
  );
  const [numberOfCacheReads, setNumberOfCacheReads] = React.useState(
    localStorage.getItem("numberOfCacheReads") || "0"
  );
  const [updating, setUpdating] = React.useState(true);

  const getDataFromLocalStorage = () => {
    const localDbConnectionsValue = localStorage.getItem(
      "numberOfDbConnections"
    );
    const localDocReadsValue = localStorage.getItem("numberOfDocReads");
    const localCacheReadsValue = localStorage.getItem("numberOfCacheReads");
    if (localDbConnectionsValue != null) {
      setNumberOfDbConnections(localDbConnectionsValue);
    }
    if (localDocReadsValue != null) {
      setNumberOfDocReads(localDocReadsValue);
    }
    if (localCacheReadsValue != null) {
      setNumberOfCacheReads(localCacheReadsValue);
    }
    setUpdating(false);
  };

  React.useEffect(() => {
    setTimeout(() => getDataFromLocalStorage(), 3000);
  }, []);

  return (
    <DebugContext.Provider
      value={{
        numberOfDbConnections,
        numberOfDocReads,
        numberOfCacheReads,
        isDevEnv,
        updating,
      }}
    >
      {props.children}
    </DebugContext.Provider>
  );
}
