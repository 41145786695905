import React, { createContext, useContext, useState } from "react";

export type StockChartContent = {
  isChartReady: boolean;
  hasLoaded: boolean;
};

// Context.
export const StockChartScriptContext = createContext({
  isChartReady: false,
  hasLoaded: false,
});

// Create a custom hook to use the context.
export const useStockChartScriptContext = () =>
  useContext(StockChartScriptContext);

interface StockChartScriptProviderProps {
  children: any;
}

// Provider of context.
const StockChartScriptProvider = ({
  children,
}: StockChartScriptProviderProps) => {
  const [isChartReady, setIsChartReady] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const scriptAlreadyExists = () => {
    if (document.querySelector("script#trading-view") !== null) {
      setIsChartReady(true);
      return true;
    }
  };

  // Append TradingView script
  const appendStockChartScript = () => {
    const script = document.createElement("script");
    script.id = "trading-view";
    script.src = "https://s3.tradingview.com/tv.js";
    script.async = true;
    script.defer = true;
    script.onload = () => setHasLoaded(true);
    document.body.append(script);
  };

  React.useEffect(() => {
    if (!scriptAlreadyExists()) {
      appendStockChartScript();
    }
  }, [, isChartReady, hasLoaded]);

  React.useEffect(() => {
    if (hasLoaded) {
      setIsChartReady(true);
    }
  }, [hasLoaded]);

  return (
    <StockChartScriptContext.Provider value={{ isChartReady, hasLoaded }}>
      {children}
    </StockChartScriptContext.Provider>
  );
};

export { StockChartScriptProvider };
