import React from "react";
import { AccountType, CompanyData } from "../../types/companies";
import ReactGA from "react-ga";
import { PaddedPaper } from "../../components/padded_paper";
import { Link } from "react-router-dom";
import { Grid, Hidden } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import * as ROUTES from "../../types/routes";
import { storage, ref, getDownloadURL } from "../../utils/firebase";
import { ImageOutlined } from "@material-ui/icons";
import { ExecutiveTeamData } from "../../types/companies";

// TODO: Type (TypeScript) things properly
interface ExecutiveTeamProfileProps {
  profile: ExecutiveTeamData;
}

export function ExecutiveTeamProfile({ profile }: ExecutiveTeamProfileProps) {
  const [imageUrl, setImageUrl] = React.useState("");

  React.useEffect(() => {
    if (
      profile["image_url"] !== null &&
      profile["image_url"] !== undefined &&
      profile["image_url"].includes("gs://")
    ) {
      getDownloadURL(ref(storage, profile["image_url"]))
        .then((url: string) => {
          setImageUrl(url);
        })
        .catch((error: any) => {
          console.error("Error retrieving logo from Google Storage: " + error);
        });
    } else {
      setImageUrl(process.env.PUBLIC_URL + profile["image_url"]);
    }
  }, [, profile]);

  return (
    <Grid item xs={12} sm={6} md={3} key={imageUrl}>
      <div className="staff-profiles__card">
        {imageUrl !== "" ? (
          <div
            className="staff-profile-image"
            style={{
              height: "98px",
              width: "98px",
              backgroundImage: `url("${imageUrl}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              margin: "auto",
            }}
          ></div>
        ) : (
          <div
            className="staff-profile-image"
            style={{
              height: "98px",
              width: "98px",
              backgroundImage: `url("${imageUrl}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              margin: "auto",
            }}
          >
            <ImageOutlined
              style={{ width: "80%", fontSize: "80px", color: "#dde0e6" }}
            />
          </div>
        )}
        <h3 className="staff-heading">{profile["full_name"]}</h3>
        <h3 className="staff-heading job-title">{profile["job_title"]}</h3>
      </div>
    </Grid>
  );
}
