import React from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";

export default function AutocompleteItem({
  item,
  type,
}: {
  item: any;
  type: string;
}) {
  // scroll element while mouse is hovering over it
  const [intervalId, setIntervalId] = React.useState<any>(null);

  const handleMouseEnter = () => {
    const element = document.getElementById(
      `tag-stack-${item.__autocomplete_id}`
    );
    if (element) {
      const intervalScroll = setInterval(() => {
        element.scrollLeft += 2;
      }, 50);
      setIntervalId(intervalScroll);
    }
  };

  const handleMouseLeave = () => {
    const element = document.getElementById(
      `tag-stack-${item.__autocomplete_id}`
    );
    if (element) {
      element.scrollLeft = 0;
      clearInterval(intervalId);
    }
  };

  if (type === "company") {
    return (
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          width: "100%",
          overflow: "hidden",
          p: 0.75,
          border: "1px solid transparent",
          borderColor: item.featured_company && "hsl(8, 100%, 68%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="button">{item.company_name}</Typography>
            {item.featured_company && (
              <Typography
                variant="overline"
                sx={{ color: "hsl(8, 100%, 68%)", fontSize: "0.625em" }}
              >
                {" (Featured)"}
              </Typography>
            )}
          </Box>
          <Typography
            variant="overline"
            sx={{ color: "hsl(8, 100%, 68%)", fontSize: "0.625em" }}
          >
            {item.company_type}
          </Typography>
        </Box>
        <Stack
          id={`tag-stack-${item.__autocomplete_id}`}
          spacing={1}
          direction="row"
          sx={{ overflow: "hidden" }}
        >
          {item.tags.map((tag: any, i: number) => {
            return (
              <Chip
                key={`suggestion-${i}`}
                label={tag}
                size="small"
                sx={{ m: 0, cursor: "pointer" }}
              />
            );
          })}
        </Stack>
      </Box>
    );
  } else if (type === "tag") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 0.75,
          border: "1px solid transparent",
        }}
      >
        <Typography variant="button">{item.label}</Typography>
        <Typography
          variant="overline"
          sx={{ color: "hsl(8, 100%, 68%)", fontSize: "0.625em" }}
        >{`(${item.count})`}</Typography>
      </Box>
    );
  } else {
    return null;
  }
}
