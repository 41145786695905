import React, { useContext } from "react";
// import { AuthContext } from "../contexts/authContext";
import { useHistory, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import Alert from "@material-ui/lab/Alert";
import * as ROUTES from "../types/routes";
import { verifyEmail } from "../utils/utils";

export default function Login() {
  const history = useHistory();
  // const { logIn } = useContext(AuthContext);
  const auth = getAuth();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [validPassword, setValidPassword] = React.useState(true);
  const [rejected, setRejected] = React.useState(false);
  const [user, loading, error] = useAuthState(auth);

  const handleLogIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email === null || !verifyEmail(email)) {
      setInvalidEmail(true);
      return;
    }
    try {
      await signInWithEmailAndPassword(auth, email, password);
      history.push(ROUTES.ACCOUNT_HOME);
    } catch (error) {
      setRejected(true);
    }
  };

  React.useEffect(() => {
    if (loading) return;
    if (error) {
      setRejected(true);
    }
    if (user) history.replace("/admin/companies");
  }, [user, loading]);

  return (
    <form className="login__form" onSubmit={handleLogIn}>
      <input
        className="emailField"
        type="text"
        value={email}
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      {invalidEmail && (
        <Alert severity="error">Please enter a valid email</Alert>
      )}

      <input
        className="emailField"
        type="password"
        value={password}
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}
      />

      {rejected && (
        <Alert severity="error">Incorrect email or password combination.</Alert>
      )}

      <input className="btn btn--login" type="submit" value="LOG IN" />

      <div className="reset-link">
        <Link to={ROUTES.RESET_PASSWORD}>Forgotten password?</Link>
      </div>
    </form>
  );
}
