import React from "react";
import { useHistory, Link } from "react-router-dom";
import * as ROUTES from "../../types/routes";

export default function AdminHome({ userCompany = "" }) {
  return (
    <div className="partner-account">
      <div className="partner-account__content">
        <h2>Welcome to your admin console</h2>
        <p>You can manage all accounts and companies from here.</p>
      </div>
    </div>
  );
}
