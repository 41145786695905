import {
  db,
  timeStamp,
  collection,
  getDocs,
  where,
  query,
  doc,
} from "./firebase";
import { getArraysIntersection, getRandom } from "./utils";
import { AccountType } from "../types/companies";
import { firestore } from "firebase-admin";
import { getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { CompanyData, enrichWithDynamicValues } from "../types/companies";
import { userRoleType } from "../types/users";
// import { writeDataFromJson } from './firebase/local_upload/local_upload_data';
// writeDataFromJson()
const DB_COLLECTION_COMPANIES = "companies";
const DB_COLLECTION_ACCOUNTS = "accounts";
const LOCAL_STORAGE_DATA_DATE_TIME_EXPIRY = "DataLeadersDataExpiry";
const LOCAL_STORAGE_DATA = "DataLeadersData";

export type AllDataFilterCompanyDataKeys =
  | "$all_specialisations_domains"
  | "$all_industry_focus"
  | "$all_focus_areas";
export type FilterDataCompanyDataKeys =
  | "company_type"
  | "specialisations_domains"
  | "industry_focus"
  | "focus_areas"
  | "founded_message";

// $ prefix variables are computed and not in the DB

export interface AccountData {
  id: string;
  email: string;
  company: string;
  date_added: firestore.Timestamp | null | undefined;
}

let companyData: CompanyData[] | null = null;

export const getTags = async () => {
  let querySnapshot = null;
  const q = query(collection(db, "Resources_tags"));
  querySnapshot = await getDocs(q);
  const allTags = querySnapshot.docs.map((doc: any) => {
    return {
      id: doc.id,
      value: doc.data().value,
    };
  });
  return allTags;
};

const fetchCompaniesRawData = async (
  canUseCache = true
): Promise<CompanyData[]> => {
  if (canUseCache) {
    if (companyData) {
      return companyData;
    }
    const cachedDataIfPresent = loadCacheRawDataIfNotExpired();
    if (cachedDataIfPresent !== undefined) {
      return cachedDataIfPresent;
    }
  }
  let querySnapshot = null;
  const q = query(collection(db, DB_COLLECTION_COMPANIES));
  querySnapshot = await getDocs(q);
  const rawCompanyData = querySnapshot.docs.map((doc: any) => {
    return doc.data();
  });
  companyData = enrichWithDynamicValues(rawCompanyData);
  try {
    updateCachedRawCompanyData(rawCompanyData);
  } catch (e) {
    console.error("Could not store results in browser cache");
  }
  return rawCompanyData;
};

// export const fetchSuggestedCompanies = async (): Promise<
//   CompanyData[] | undefined
// > => {
//   const rawCompanyData = await fetchCompaniesRawData(true);
//   // TODO make this relavent to user's search, not random
//   return getRandom(rawCompanyData, 4);
// };

export const fetchFeaturedCompanies = async (): Promise<
  CompanyData[] | undefined
> => {
  const rawCompanyData = await fetchCompaniesRawData(true);
  const featuredCompanies = rawCompanyData.filter(
    (company) =>
      company.featured_company &&
      (company.approved || company.approved == undefined)
  );
  return featuredCompanies;
};

export const fetchAllCompaniesAsAdmin = async (): Promise<CompanyData[]> => {
  const rawCompanyData = await fetchCompaniesRawData(false);
  if (rawCompanyData !== undefined) {
    return rawCompanyData;
  } else {
    return [];
  }
};

export const fetchAllApprovedCompanies = async (): Promise<CompanyData[]> => {
  const rawCompanyData = await fetchCompaniesRawData(true);
  const featuredCompanies = rawCompanyData.filter(
    (company) => company.approved || company.approved == undefined
  );
  if (featuredCompanies == undefined) {
    return [];
  }
  return featuredCompanies;
};

export const getCompanyById = async (
  id: String,
  userRole: userRoleType = userRoleType.USER,
  userCompany: String = ""
): Promise<CompanyData | undefined> => {
  let allCompanyData = [];
  if (userRole === userRoleType.ADMIN || userRole === userRoleType.PARTNER) {
    allCompanyData = await fetchAllCompaniesAsAdmin();
  } else {
    allCompanyData = await fetchAllApprovedCompanies();
  }
  if (userCompany !== "") {
    allCompanyData = allCompanyData.filter(
      (companyDatum: CompanyData) => companyDatum.company_name === userCompany
    );
  }
  try {
    return allCompanyData.find(
      (companyDatum: CompanyData) => companyDatum["id"] === id
    );
  } catch (e) {
    return undefined;
  }
};

export const getCompaniesMatchingTextSearch = async (
  searchValue: string
): Promise<CompanyData[]> => {
  const allCompanyData = await fetchAllApprovedCompanies();
  const matchingCompanyNames = new Set();
  const searchValueLowerCase = searchValue.toLowerCase().trim();
  let indexOf = (arr: string[], q: string) => {
    if (!arr || arr.length < 0) {
      return -1;
    }
    const index = arr.findIndex((item) => q === item.toLowerCase());
    if (index !== undefined) {
      return index;
    }
    return -1;
  };

  if (allCompanyData == undefined) {
    return [];
  }

  const matchingCompanyData = allCompanyData.filter((companyDatum) => {
    const hasNameMatch: Boolean = companyDatum["company_name"]
      .toLowerCase()
      .replace(new RegExp("[.!?\\-]"), "")
      .includes(searchValueLowerCase.replace(new RegExp("[.!?\\-]"), ""));
    if (hasNameMatch) {
      matchingCompanyNames.add(companyDatum["company_name"]);
    }
    return hasNameMatch;
  });

  matchingCompanyData.push(
    ...allCompanyData.filter((companyDatum) => {
      if (matchingCompanyNames.has(companyDatum["company_name"])) {
        return false;
      }
      // Search in description
      const hasDescriptionMatch =
        companyDatum["about_the_company"] !== undefined &&
        companyDatum["about_the_company"]
          .toLowerCase()
          .includes(searchValueLowerCase);

      // Search in tags
      const allCompanyTagsAndVariants = fetchAllTagVariants(
        companyDatum["tags"]
      );
      const hasTagsMatch =
        indexOf(allCompanyTagsAndVariants, searchValueLowerCase) > -1;

      // Search in domains
      const hasDomainMatch =
        indexOf(companyDatum["specialisations_domains"], searchValueLowerCase) >
        -1;

      const hasMatch = hasDescriptionMatch || hasTagsMatch || hasDomainMatch;
      if (hasMatch) {
        matchingCompanyNames.add(companyDatum["company_name"]);
      }
      return hasMatch;
    })
  );

  return matchingCompanyData;
};

// const uniqueStringArray = (array: string[]): string[] => {
//   return Array.from(new Set(array));
// };

const fetchAllTagVariants = (tags: string[] | undefined) => {
  if (tags === undefined) return [];
  let additionalTags: string[] = [];
  tags.map((tag) => {
    if (tag === "Data cataloguing") {
      additionalTags.push("Data catalogue", "Data catalog", "Data cataloging");
    }
  });
  return tags.concat(additionalTags);
};

const countOccurencesInArray = (options: string[]): Record<string, number> => {
  return options.reduce((prev: Record<string, number>, cur: string) => {
    let lowerCaseCur = "";
    if (cur) {
      lowerCaseCur = cur.toLowerCase();
    }
    if (!prev[lowerCaseCur]) {
      prev[lowerCaseCur] = 1;
    } else {
      prev[lowerCaseCur] += 1;
    }

    return prev;
  }, {});
};

export const addCountToEachValue = (
  entries: Record<any, number>,
  modifier: number
): void => {
  Object.entries(entries).forEach(([key, value]) => {
    entries[key] = value + modifier;
  });
};

export const getCompanyTypeOptions = (
  dataset: CompanyData[]
): Record<string, number> => {
  return countOccurencesInArray(
    dataset
      .map((companyDatum) => {
        return companyDatum["company_type"];
      })
      .sort()
  );
};

export const getOptionsToCounts = (
  dataset: CompanyData[],
  dataKey: FilterDataCompanyDataKeys,
  allDataKey?: AllDataFilterCompanyDataKeys
): Record<string, number> => {
  const optionsToCounts = countOccurencesInArray(
    dataset
      .filter((companyDatum) => !allDataKey || !companyDatum[allDataKey])
      .map((companyDatum) => {
        return companyDatum[dataKey];
      })
      .flat()
      .sort()
  );

  if (allDataKey) {
    const countOfAll = dataset.filter(
      (companyDatum) => companyDatum[allDataKey]
    ).length;

    addCountToEachValue(optionsToCounts, countOfAll);
  }
  return optionsToCounts;
};

export const getSpecialisationDomainsOptionsToCounts = (
  dataset: CompanyData[]
): Record<string, number> => {
  return getOptionsToCounts(
    dataset,
    "specialisations_domains",
    "$all_specialisations_domains"
  );
};

// export const getTagOptions = (dataset: CompanyData[]): string[] => {
//   return uniqueStringArray(
//     dataset.map((companyDatum) => {
//       return companyDatum["tags"];
//     }).flat().sort()
//   );
// }

export const getIndustryFocusOptionsToCounts = (
  dataset: CompanyData[]
): Record<string, number> => {
  return getOptionsToCounts(dataset, "industry_focus", "$all_industry_focus");
};

export const getFocusAreaOptionsToCounts = (
  dataset: CompanyData[]
): Record<string, number> => {
  return getOptionsToCounts(dataset, "focus_areas", "$all_focus_areas");
};

export const getMaturityOptionsToCounts = (
  dataset: CompanyData[]
): Record<string, number> => {
  const options = [
    "Start-Up (<5yrs)",
    "Growing (5-10yrs)",
    "Established (>10yrs)",
  ];
  const optionsToCounts = getOptionsToCounts(dataset, "founded_message");
  const filteredOptionsToCounts = Object.entries(optionsToCounts).reduce(
    (prev: Record<string, number>, [key, value]) => {
      if (options.indexOf(key) !== -1) {
        prev[key] = value;
      }
      return prev;
    },
    {}
  );

  return filteredOptionsToCounts;
};

export const adminFetchListOfAccounts = async () => {
  let querySnapshot = null;
  const q = query(collection(db, DB_COLLECTION_ACCOUNTS));
  querySnapshot = await getDocs(q);
  const rawAccountData = querySnapshot.docs.map((doc: any) => {
    return doc.data();
  });
  return rawAccountData;
};

export const fetchCompany = async (
  companyName: string
): Promise<CompanyData> => {
  // const q = query(collection(db, DB_COLLECTION));
  const docRef = doc(db, DB_COLLECTION_COMPANIES, companyName);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const response = { ...docSnap.data() } as CompanyData;
    return response;
  } else {
    console.error("Company not found");
    const response = {} as CompanyData;
    return response;
  }
};

export const createNewCompany = async (
  data: CompanyData,
  userRole: userRoleType = userRoleType.USER
) => {
  setDoc(doc(db, DB_COLLECTION_COMPANIES, data["company_name"]), {
    ...data,
    id: data["company_name"],
    tags:
      data["features"] !== null && data["features"] !== undefined
        ? Object.keys(data["features"])
        : [],
    approved: false,
    date_added: timeStamp.now(),
    date_last_edited: timeStamp.now(),
    new_user_update: userRole === userRoleType.ADMIN ? data.approved : false,
  })
    .then(() => {
      return true;
    })
    .catch((e) => {
      console.error("Error saving the data:", e);
    });
};

export const updateCompany = async (
  data: CompanyData,
  userRole: userRoleType = userRoleType.USER
) => {
  setDoc(
    doc(db, DB_COLLECTION_COMPANIES, data["company_name"]),
    {
      ...data,
      id: data["company_name"],
      tags:
        data["features"] !== null && data["features"] !== undefined
          ? Object.keys(data["features"])
          : [],
      date_last_edited: timeStamp.now(),
      new_user_update: userRole !== userRoleType.ADMIN,
    }
    // { merge: true }
  )
    .then(() => {
      return true;
    })
    .catch((e) => {
      console.error("Error saving the data:", e);
    });
};

export const deleteCompany = async (companyId: string) => {
  await deleteDoc(doc(db, DB_COLLECTION_COMPANIES, companyId));
};

export const addNewAccount = async (data: AccountData): Promise<Boolean> => {
  try {
    await setDoc(doc(db, DB_COLLECTION_ACCOUNTS, data.email), {
      ...data,
      id: data["id"],
      date_added: timeStamp.now(),
    });
    return true;
  } catch {
    return false;
  }
};
const hasCacheDataExpired = (): boolean => {
  const dataExpiry = localStorage.getItem(LOCAL_STORAGE_DATA_DATE_TIME_EXPIRY);
  if (dataExpiry) {
    const dataExpiryDate = new Date(dataExpiry);
    const currentDate = new Date();

    return currentDate > dataExpiryDate;
  }
  return false;
};

const loadCacheRawDataIfNotExpired = (): Array<CompanyData> | undefined => {
  if (hasCacheDataExpired()) {
    return undefined;
  }
  const dataField = localStorage.getItem(LOCAL_STORAGE_DATA);
  if (dataField === null) {
    return undefined;
  }
  return JSON.parse(dataField);
};

const updateCachedRawCompanyData = (companyData: Array<any>): void => {
  const date24hoursFromNow = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000
  );
  localStorage.setItem(
    LOCAL_STORAGE_DATA_DATE_TIME_EXPIRY,
    date24hoursFromNow.toISOString()
  );
  localStorage.setItem(LOCAL_STORAGE_DATA, JSON.stringify(companyData));
};
