import * as React from "react";
import { DebugContextProvider } from "./debugContext";
import { UserContextProvider } from "./userContext";
import { StylesProvider } from "@material-ui/core/styles";

type MyComponentProps = React.PropsWithChildren<{}>;

function AppProviders({ children }: MyComponentProps) {
  return (
    <StylesProvider injectFirst>
      <DebugContextProvider>
        {/* <UserContextProvider> */}
        {children}
        {/* </UserContextProvider> */}
      </DebugContextProvider>
    </StylesProvider>
  );
}

export default AppProviders;
