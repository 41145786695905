import { FilterOptionsSelected } from "../pages/search_results";

export interface SearchUrlOptions extends Partial<FilterOptionsSelected> {
  search?: string;
}

export const generateSearchUrl = (searchData: SearchUrlOptions) => {
  const searchParams = new URLSearchParams();

  type SearchUrlOptionsKeys = keyof FilterOptionsSelected; // not including "search" as that's not a string[]
  const ifSetSetKey = (key: SearchUrlOptionsKeys) => {
    if (searchData[key] !== undefined && searchData[key] !== null) {
      searchParams.set(key, searchData[key]!.join(","));
    }
  };

  if (searchData["search"]) {
    searchParams.set("search", searchData["search"]);
  }

  ifSetSetKey("selectedCompanyTypeOptions");
  ifSetSetKey("selectedSpecialisationDomainsOptions");
  // ifSetSetKey("selectedTagOptions");
  ifSetSetKey("selectedIndustryFocusOptions");
  ifSetSetKey("selectedFocusAreaOptions");
  ifSetSetKey("selectedMaturityOptions");

  return `/search-results?${searchParams.toString()}`;
};

export const generateAlgoliaSearchUrl = (query: string) => {
  return encodeURI(`/search-results?company_name[query]=${query}`);
};
