import React from "react";
import { PaddedPaper } from "../padded_paper";
import {
  CompanyData,
  AccountType,
  AdditionalResourceType,
} from "../../types/companies";
import emailjs from "emailjs-com";
import { init } from "@emailjs/browser";

import { Grid, Button } from "@material-ui/core";
import newsItemPlaceholder from "../../images/icons/news_item_placeholder.png";
import * as ROUTES from "../../types/routes";
import { LoadingAnimation } from "../../components/loadingAnimation";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";

interface CompanyDetailsDisplayProps {
  companyDatum: CompanyData;
}

export default function LiveUsersTab({
  companyDatum,
}: CompanyDetailsDisplayProps) {
  const [showContactForm, setShowContactForm] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [missingData, setMissingData] = React.useState(false);
  const [notAgreedToPrivacyPolicy, setNotAgreedToPrivacyPolicy] =
    React.useState(false);
  init(process.env.REACT_APP_EMAILJS_USER_ID as string);
  const [contactForm, setContactForm] = React.useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    tel: "",
    member: "",
    isMember: false,
    agreeToPrivacyPolicy: false,
    companyInterestedIn: companyDatum.company_name,
    message: `I would like a list of live users of ${companyDatum.company_name}.`,
  });
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!contactForm.agreeToPrivacyPolicy) {
      setNotAgreedToPrivacyPolicy(true);
      return;
    }
    if (
      contactForm.companyName === "" ||
      contactForm.email === "" ||
      ((contactForm.firstName === "" ||
        contactForm.lastName === "" ||
        contactForm.message === "" ||
        contactForm.tel === "") &&
        !contactForm.isMember)
    ) {
      setMissingData(true);
    } else {
      setLoading(true);

      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
          process.env.REACT_APP_EMAILJS_LIVE_USERS_REQUEST_TEMPLATE as string,
          contactForm,
          process.env.REACT_APP_EMAILJS_USER_ID as string
        )
        .then((resp) => {
          setSuccess(true);
        })
        .catch((error: any) => {
          console.error("Failed to send email.");
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;

    if (name === "agreeToPrivacyPolicy") {
      let value = e.target.checked;
      setContactForm({
        ...contactForm,
        [name]: value,
      });
    } else {
      let value = e.target.value;
      value = e.target.value;
      setContactForm({
        ...contactForm,
        [name]: value,
      });
    }
  };

  return (
    <div className="container-box">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="item">
            <Grid item xs={12} sm={6}>
              Tech Evaluate enables potential buyers to connect with real users
              of a solution.
              {showContactForm ? (
                <Grid id="contact-form" item xs={12}>
                  <div className="contact-form-container">
                    <p className="contact-form-header hero-body">
                      Please fill in the following form and we will get back to
                      you as quicly as we can.
                    </p>
                    {loading ? (
                      <p>Sending message...</p>
                    ) : success ? (
                      <Alert severity="success">
                        Message successfully sent. We will get back to you
                        shortly.
                      </Alert>
                    ) : (
                      <FormControl>
                        {/* <h2 className="orange-title">Get listed</h2> */}
                        <TextField
                          variant="standard"
                          InputProps={{
                            style: {
                              padding: 12,
                            },
                          }}
                          type="email"
                          name="email"
                          className="search-field"
                          value={contactForm.email}
                          placeholder="Business email*"
                          onChange={handleChange}
                          fullWidth
                        />

                        <TextField
                          variant="standard"
                          InputProps={{
                            style: {
                              padding: 12,
                            },
                          }}
                          type="text"
                          name="companyName"
                          className="search-field"
                          value={contactForm.companyName}
                          placeholder="Company name*"
                          onChange={handleChange}
                          fullWidth
                        />

                        {!contactForm.isMember && (
                          <>
                            <TextField
                              variant="standard"
                              InputProps={{
                                style: {
                                  padding: 12,
                                },
                              }}
                              type="text"
                              name="firstName"
                              className="search-field"
                              value={contactForm.firstName}
                              placeholder="First name*"
                              onChange={handleChange}
                              fullWidth
                            />
                            <TextField
                              variant="standard"
                              InputProps={{
                                style: {
                                  padding: 12,
                                },
                              }}
                              type="text"
                              name="lastName"
                              className="search-field"
                              value={contactForm.lastName}
                              placeholder="Last name*"
                              onChange={handleChange}
                              fullWidth
                            />
                            <TextField
                              variant="standard"
                              InputProps={{
                                style: {
                                  padding: 12,
                                },
                              }}
                              type="text"
                              name="tel"
                              className="search-field"
                              value={contactForm.tel}
                              placeholder="Business phone*"
                              onChange={handleChange}
                              fullWidth
                            />
                          </>
                        )}
                        <div>
                          <p style={{ fontSize: "0.75rem" }}>
                            Data Leaders may share my information with this
                            company and may use the information I provide to
                            contact me regarding relevant products and services
                            in line with their Privacy Policy.
                          </p>
                          <Checkbox
                            name="agreeToPrivacyPolicy"
                            checked={contactForm.agreeToPrivacyPolicy || false}
                            onChange={handleChange}
                            color="default"
                            disableFocusRipple
                          />
                          <span style={{ fontSize: "0.75rem" }}>I agree</span>
                        </div>
                        {/* <TextField
                          variant="standard"
                          InputProps={{
                            style: {
                              padding: 12,
                            },
                          }}
                          type="text"
                          name="message"
                          className="search-field"
                          value={contactForm.message}
                          placeholder="Your message*"
                          multiline
                          rows={4}
                          onChange={handleChange}
                          fullWidth
                        /> */}
                        <br />
                        {missingData && (
                          <Alert severity="warning">
                            Please fill in all required fields.
                          </Alert>
                        )}
                        {notAgreedToPrivacyPolicy && (
                          <Alert severity="warning">
                            Please agree to the Privacy Policy.
                          </Alert>
                        )}
                        <Button
                          className="lower-case-button theme-button "
                          variant="outlined"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                        <br />
                        <Button
                          variant="text"
                          color="default"
                          onClick={() => {
                            setShowContactForm(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </FormControl>
                    )}
                  </div>
                </Grid>
              ) : (
                <>
                  <div>
                    <p>
                      I'm a client, connect me with a qualified user of this
                      solution and/or service. <br />
                      <Button
                        className="lower-case-button theme-button outline"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setContactForm({ ...contactForm, isMember: true });
                          setShowContactForm(true);
                        }}
                      >
                        Click here
                      </Button>
                    </p>
                  </div>
                  <div>
                    <p>
                      I'm not a client, but I want to speak with a qualified
                      user.
                      <br />
                      <Button
                        className="lower-case-button theme-button outline"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setContactForm({ ...contactForm, isMember: false });
                          setShowContactForm(true);
                        }}
                      >
                        Click here
                      </Button>
                    </p>
                  </div>
                </>
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
