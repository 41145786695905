import { createElement, Fragment, useEffect, useRef } from "react";
import algoliasearch from "algoliasearch";
import {
  autocomplete,
  getAlgoliaFacets,
  getAlgoliaResults,
} from "@algolia/autocomplete-js";
import { render } from "react-dom";
import * as ROUTES from "../../types/routes";
import AutocompleteItem from "../search_results/autocompleteItem";
import { generateAlgoliaSearchUrl } from "../../utils/url_builder";
import { Box, Button } from "@mui/material";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID as string,
  process.env.REACT_APP_ALGOLIA_API_KEY as string
);

function AlgoliaAutocomplete(props: any) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment, render },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div ref={containerRef} />;
}

export function AlgoliaSearchField() {
  function handleSubmit(query: string) {
    window.location.href = generateAlgoliaSearchUrl(query);
  }

  return (
    <AlgoliaAutocomplete
      placeholder="Search for a company or domain"
      autofocus={true}
      onSubmit={({ state }: { state: any }) => {
        handleSubmit(state.query);
      }}
      getSources={({ query }: { query: string }) => [
        {
          sourceId: "tags",
          getItems() {
            return getAlgoliaFacets({
              searchClient,
              queries: [
                {
                  indexName: "company_name",
                  facet: "tags",
                  params: {
                    facetQuery: query,
                    maxFacetHits: 4,
                  },
                },
              ],
            });
          },
          templates: {
            header() {
              return (
                <>
                  <span
                    className="aa-SourceHeaderTitle"
                    style={{ color: "hsl(8, 100%, 68%)" }}
                  >
                    Terms
                  </span>
                  <div
                    className="aa-SourceHeaderLine"
                    style={{ borderColor: "hsl(8, 100%, 68%)" }}
                  />
                </>
              );
            },
            item({ item }: { item: any }) {
              return <AutocompleteItem item={item} type="tag" />;
            },
          },
          onSelect({ setIsOpen, item }: { setIsOpen: any; item: any }) {
            setIsOpen(false);
            window.location.href = generateAlgoliaSearchUrl(item.label);
          },
        },
        {
          sourceId: "companies",
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: "company_name",
                  query,
                  params: {
                    hitsPerPage: 4,
                  },
                },
              ],
            });
          },
          templates: {
            header() {
              return (
                <>
                  <span
                    className="aa-SourceHeaderTitle"
                    style={{ color: "hsl(8, 100%, 68%)" }}
                  >
                    Providers
                  </span>
                  <div
                    className="aa-SourceHeaderLine"
                    style={{ borderColor: "hsl(8, 100%, 68%)" }}
                  />
                </>
              );
            },
            item({ item }: { item: any }) {
              return <AutocompleteItem item={item} type="company" />;
            },
            footer({ state }: { state: any }) {
              return (
                <Box
                  sx={{
                    color: "hsl(8, 100%, 68%)",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    className="all-companies-button"
                    type="submit"
                    onClick={() => handleSubmit(state.query)}
                    color="inherit"
                    fullWidth
                  >
                    Search all companies
                  </Button>
                </Box>
              );
            },
          },
          transformResponse({ results }: { results: any }) {
            console.log(results);
          },
          onSelect({ setIsOpen, item }: { setIsOpen: any; item: any }) {
            setIsOpen(false);
            window.location.href = encodeURI(
              `${ROUTES.COMPANY_DETAILS}/${item.company_name}`
            );
          },
        },
      ]}
    />
  );
}
